<template>
    <ul class="">
        <single-user
            :user="user"
            v-for="user in users"
            :key="user.uuid"
        />
    </ul>
</template>

<script>
import SingleUser from "~base/components/Settings/Users/User.vue";

export default {
    components: {
        SingleUser
    },
    computed: {
        users() {
            return this.$store.state.users;
        }
    }
}
</script>
