import { mutationTypes } from "~base/store/mutations";
const types = {
    'SET_SCREEN': 'SCREENS_SET_SCREEN'
}

const actions = {
    [types.SET_SCREEN]({ commit, state: { screens } }, { screen }) {
        if (!screens.all.find(s => s.id === screen.id)) return;
        commit(mutationTypes.screens.SET_SCREEN, screen.id);
    }
}

export { types as screenActionTypes };
export default actions;

