<template>
    <li class="pb-6 md:pb-7" :class="{[draggable.handle.group]: screenSmallerThanMd}">
        <div class="flex items-center pb-2.5 group md-select">
            <div
                class="flex items-center relative"
            >
                <span class="flex items-center justify-center h-5 w-5 px-2 cursor-pointer">
                    <list-chevron v-model="openNestedTasks" />
                </span>
                <div class="px-2">
                    <span
                        class="font-medium text-gray-800 md-select hover:bg-white text-[17px] py-0.5 px-[3px] border border-transparent hover:border-dashed hover:border-gray-600"
                        v-if="!updater.fields.title.editing"
                        @click="() => updater.fields.title.start()"
                    >
                        {{ updater.fields.title.model }}
                    </span>
                    <input
                        type="text"
                        class="font-medium text-gray-800 bg-white leading-none text-[17px] py-0.5 px-[3px] border border-dashed border-gray-600 focus:outline-none"
                        v-else
                        v-focus
                        v-model="updater.fields.title.model"
                        @keydown.enter.prevent="() => updater.fields.title.save()"
                        @keydown.escape.prevent="() => updater.fields.title.cancel()"
                        @blur="() => updater.fields.title.save()"
                    />
                </div>
                <button class="absolute hidden md:block right-full p-2 opacity-0 group-hover:opacity-100 focus:outline-none" :class="draggable.handle.group" v-if="!isEditingSomething">
                    <i class="fas fa-arrows-alt text-gray-600"></i>
                </button>
            </div>
            <button @click="modals.delete.show({ item: group, callback: deleteGroup })" class="opacity-0 group-hover:opacity-100 focus:outline-none">
                <i class="far fa-trash-alt text-gray-600"></i>
            </button>
        </div>
        <draggable-list 
            class="flex flex-col"
            group="tasks"
            v-model="draggableTasks"
            ghost-class="draggable__moving_element"
            :animation="300"
            :handle="`.${draggable.handle.task}`"
            :disabled="isEditingSomething"
            :delay="300"
            :delayOnTouchOnly="true"
        > 
            <task-item 
                v-for="(task, i) in group.ordered_parent_tasks" 
                :key="task.uuid" 
                :task="task"
                :index="i"
                :opened="openNestedTasks"
                :disable-drag="disableDrag || isEditingSomething || havingNotStoredTask"
                @deleteTask="deleteTask"
                @addTask="addTask"
                @refreshTask="refreshTask"
            />
        </draggable-list> 
        <add-task-button @clicked="addTask" v-if="openNestedTasks" />
    </li>
</template>

<script>
import DraggableList from "vuedraggable"
import { mapActions, mapGetters, mapState } from "vuex";
import models from "~base/models"
import updater from "~base/classes/Models";
import TaskItem from "~base/components/TaskList/Show/Task";
import directives from "~base/directives";
import AddTaskButton from "~base/components/TaskList/Show/Partials/AddTask";
import listChevron from "~base/components/TaskList/Show/Partials/chevron.vue";
import { types } from '~base/store';
import modals from "~base/components/Modals/Config"
import { getterTypes } from '~base/store/getters';

export default {
    props: {
        group: {
            required: true,
            type: Object
        },
        index: {
            required: true,
            type: Number
        },
        disableDrag: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
        TaskItem,
        AddTaskButton,
        DraggableList,
        listChevron
    },
    directives: {
        focus: directives.focus
    },
    data()
    {
        return {
            updater: {
                handler: {},
                fields: {
                    title: {},
                    show: {}
                }
            },
            modals: modals.apply(this)
        }
    },
    methods: {
        ...mapActions(['task_counters_update_task_stored']),
        // forcing update for freshly created task
        refreshTask({ task }) {
            const index = this.group.ordered_parent_tasks.findIndex(({ uuid }) => uuid === task.uuid);
            if (index === -1) {
                const notStoredIndex = this.group.ordered_parent_tasks.findIndex(({ uuid }) => !uuid);
                if (notStoredIndex === -1) return console.log({ uuid: task.uuid }, 'couldn"t find a task to update')
                return this.group.ordered_parent_tasks.splice(notStoredIndex, 1, task);
            }
            this.group.ordered_parent_tasks.splice(index, 1, task);
        },
        async addTask()
        {
            const { id: task_group_id, task_list_id } = this.group;
            const task = models.task.new(
                { user: this.minimalUser },
                { 
                    task_group_id,
                    task_list_id,
                    order: this.group.ordered_parent_tasks.length 
                        ? this.group.ordered_parent_tasks.reduce((max, t) => Math.max(max, t.order), 0) + 1
                        : 0
                }
            );
            this.task_counters_update_task_stored({ model: task.due_date, isDone: task.is_done })
            this.group.ordered_parent_tasks.push(task);
        },
        deleteTask(uuid)
        {
            const index = this.group.ordered_parent_tasks.findIndex(task => task.uuid === uuid);
            if (index >= 0) this.group.ordered_parent_tasks.splice(index, 1);
        },
        stored() {
            return this.group.uuid ? true : false;
        },
        handleCancel()
        {
            if (!this.stored()) this.deleteGroup() 
        },
        deleteGroup()
        {
            this.$emit('delete', this.group.uuid);
        },
        async storeGroup()
        {
            const group = await models.taskGroup.store(this.group, { user: this.minimalUser });
            this.$emit("refreshGroup", { index: this.index, group });
        },
        async updateGroup({ field })
        {
            try {
                const { title } = this.updater.fields;
                // if trying to save empty  title
                if (field === 'title' && title.model === '') return title.cancel();
                // if list not persisted yet, persist it
                if (!this.stored()) return this.storeGroup();

                const group = await models.taskGroup.update(this.group, { user: this.minimalUser, field });
                console.log('updated');
                return group;
            } catch(err) {
                console.error('error while updating');
                throw err;
            }
        },
        setUpdater() {
            this.updater.handler = new updater(this.group);
            const { handler, fields } = this.updater;
            Object.keys(fields).forEach(key => {
                fields[key] = handler.field({
                    name: key,
                    callbacks: {
                        save: this.updateGroup.bind(this, { field: key }),
                        start: this.setGroupEditing.bind(this, true),
                        stop: this.setGroupEditing.bind(this, false),
                        cancel: this.handleCancel
                    }
                })
            });
        },
        setGroupEditing(value)
        {
            this.$store.dispatch(types.actions.doing.SET_GROUP_EDITING, value);
        }
    },
    computed: {
        ...mapGetters(['selectedOrder', 'minimalUser']),
        ...mapState(['user', 'draggable', 'screens']),
        screenSmallerThanMd() {
            return this.$store.getters[getterTypes.screens.IS_SMALLER_THAN_MD];
        },
        isEditingSomething()
        {
            return this.$store.getters[ types.getters.doing.IS_EDITING];
        },
        draggableTasks: {
            get() {
                return this.group.ordered_parent_tasks;
            },
            set(tasks) {
                const bulk = []
                // Making sure tasks attributes reflect a group parent task model.
                tasks.forEach((parent, index) => {
                    const { parent_id, task_group_id, order } = parent;
                    const updateNeeded = parent_id !== null || task_group_id !== this.group.id || order !== index;
                    if (updateNeeded) {
                        parent.parent_id = null;
                        parent.task_group_id = this.group.id;
                        parent.order = index;
                        bulk.push({ uuid: parent.uuid, parent_id: parent.parent_id, task_group_id: parent.task_group_id, order: parent.order })
                    }
                });
                if (bulk.length) models.task.bulkUpdate(bulk);
                // setting value to formated one
                this.$emit("refreshGroup", { index: this.index, group: { ...this.group, ordered_parent_tasks: tasks } });
            }
        },
        openNestedTasks: {
            get() {
                return this.group.show;
            },
            set(value) {
                // when we store to database this should make a call too
                const { show } = this.updater.fields;
                show.start().model = value;
                show.save();
            }
        },
        hasNestedTasks() {
            const { ordered_parent_tasks } = this.group;
            return ordered_parent_tasks && ordered_parent_tasks.length > 0; 
        },
        havingNotStoredTask()
        {
            return this.group.ordered_parent_tasks.find(t => !t.uuid) ? true : false;
        },
        isListPreventingFilters()
        {
            return this.$store.state.active_list_prevent_filters;
        }
    },
    watch: {
        selectedOrder: {
            immediate: true,
            handler(order)
            {
                this.group.ordered_parent_tasks.sort(order.sort);
            }
        },
        isListPreventingFilters(prevent) {
            if (prevent) return;
            // stop preventing => sorting
            this.group.ordered_parent_tasks.sort(this.selectedOrder.sort);
        }
    },
    created()
    {
        this.setUpdater();
        if (!this.stored()) this.updater.fields.title.start().model = ''
    }
}
</script>

<style>

</style>