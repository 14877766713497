<template>
    <div class="flex items-center">
        <i class="far fa-exclamation-circle text-late-task mr-1" v-if="parsedDueDate.late"></i>
        <span
            :class="parsedDueDate.class"
            @click="$emit('start')"
            :data-tooltip="tooltipText"
        >
            {{ parsedDueDate.value }}
        </span>
    </div>
</template>

<script>
import moment from "moment";
import { getterTypes } from '~base/store/getters';

export default {
    props: {
        format: {
            type: String,
            require: false,
            default: () => 'DD MMM YYYY'
        },
        isDone:{
            type: Boolean,
            required: true
        },
        field: {
            type: Object,
            required: true
        },
        hasTime: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        parsedDueDate()
        {
            if (!this.field.model) return { class: 'text-[15px] text-gray-700', value: this.screenSmallerThanMd ? "" : "Ajouter une date" }
            const date = moment(this.field.model)
            let formated = date.format(this.format)
            const now = moment();
            const isLate = date.isBefore(now, 'day');
            const isToday = date.isSame(now, 'day');
            const isYesterday = date.isSame(now.clone().subtract(1, "day"), 'day');
            const isTomorrow = date.isSame(now.clone().add(1, "day"), 'day');

            if (isToday) formated = this.$t("pages.list.task.due_date.today");
            if (isYesterday) formated = this.$t("pages.list.task.due_date.yesterday");;
            if (isTomorrow) formated = this.$t("pages.list.task.due_date.tomorrow");;
            
            if (this.isDone) return { class: 'text-[15px] text-gray-700 font-light', value: formated }
            
            if (isLate) return { class: 'text-[15px] text-late-task font-medium', value: formated, late: true }
            
            if (isToday) {
                const isTodayButLate = date.isBefore(now);
                return isTodayButLate
                    ? { class: 'text-[15px] text-late-task font-medium', value: formated, late: true }
                    : { class: 'text-[15px] text-today-task font-medium', value: formated }
            }
                
            return { class: 'text-[15px] text-gray-700', value: formated }
        },
        tooltipText() {
            const due_date = this.field.model;
            if (!due_date) return this.$t("pages.list.task.due_date.empty")
            if (!this.hasTime) return this.$t("pages.list.task.due_date.picker.time.empty");
            
            return moment(due_date).format("HH:mm");
        },
        screenSmallerThanMd() {
            return this.$store.getters[getterTypes.screens.IS_SMALLER_THAN_MD];
        },
    }
}
</script>

<style>

</style>