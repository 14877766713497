var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(! _vm.list || _vm.loaders.list.loading)?_c('div',{staticClass:"relative py-24"},[_c('v-spa-loader')],1):_c('task-list',{attrs:{"list":_vm.list,"disable-drag":!_vm.isDefaultOrder,"use-groups":true,"as-page":true,"task-key":"ordered_groupless_parent_tasks"},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var model = ref.model;
var start = ref.start;
var save = ref.save;
var cancel = ref.cancel;
var editing = ref.editing;
var setModel = ref.setModel;
return [(editing)?_c('input',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"py-0.5 px-[3px] !text-xl !md:text-3xl leading-none focus:outline-none bg-white border border-dashed border-gray-600",domProps:{"value":model},on:{"input":setModel,"blur":save,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return save.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }$event.preventDefault();return cancel.apply(null, arguments)}]}}):_c('span',{staticClass:"py-0.5 px-[3px] text-xl md:text-3xl hover:bg-white border border-dashed border-transparent hover:border-gray-600",on:{"click":start}},[_vm._v(_vm._s(model))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }