import helpers from "~base/helpers";
import i18n from "~base/i18n";

const sort = () => ({
    display: false,
    selected: 'custom',
    default: 'custom',
    all: [
        {
            id: 'custom',
            group_default: true,
            group_display: i18n.t("pages.list.sort.custom"),
            display: i18n.t("pages.list.sort.custom"),
            icon: 'fas fa-cog',
            sort: (a, b) => helpers.sorting.defaults.ascending(a.order, b.order)
        },
        {
            id: 'due-date-asc',
            group: "due_date",
            group_default: true,
            group_display: i18n.t("pages.list.sort.due_date.group"),
            asc: true,
            display: i18n.t("pages.list.sort.due_date.asc"),
            icon: 'fas fa-long-arrow-alt-up',
            sort: (a, b) => helpers.sorting.dates.ascending(a.due_date, b.due_date)
        },
        {
            id: 'due-date-desc',
            group: "due_date",
            display: i18n.t("pages.list.sort.due_date.desc"),
            icon: 'fas fa-long-arrow-alt-down',
            sort: (a, b) => helpers.sorting.dates.descending(a.due_date, b.due_date)
        },
        // {
        //     id: 'created-at-asc',
        //     group: "created_at",
        //     group_default: true,
        //     group_display: i18n.t("pages.list.sort.creation_date.group"),
        //     asc: true,
        //     display: i18n.t("pages.list.sort.creation_date.asc"),
        //     icon: 'fas fa-long-arrow-alt-up',
        //     sort: (a, b) => helpers.sorting.dates.ascending(a.created_at, b.created_at)
        // },
        // {
        //     id: 'created-at-desc',
        //     group: "created_at",
        //     display: i18n.t("pages.list.sort.creation_date.desc"),
        //     icon: 'fas fa-long-arrow-alt-down',
        //     sort: (a, b) => helpers.sorting.dates.descending(a.created_at, b.created_at)
        // },
        {
            id: 'alphabetical-asc',
            group: "title",
            group_default: true,
            group_display: i18n.t("pages.list.sort.title.group"),
            asc: true,
            display: i18n.t("pages.list.sort.title.asc"),
            icon: 'fas fa-long-arrow-alt-up',
            sort: (a, b) => helpers.sorting.defaults.ascending(a.title, b.title)
        },
        {
            id: 'alphabetical-desc',
            group: "title",
            display: i18n.t("pages.list.sort.title.desc"),
            icon: 'fas fa-long-arrow-alt-down',
            sort: (a, b) => helpers.sorting.defaults.descending(a.title, b.title)
        }
    ]
});

export { sort as refreshSortings }
export default sort();