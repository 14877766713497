import { mutationTypes } from "~base/store/mutations";
const types = {
    'SET_TASK_EDITING': 'DOING_SET_TASK_EDITING',
    'SET_GROUP_EDITING': 'DOING_SET_GROUP_EDITING',
    'SET_LIST_EDITING': 'DOING_SET_LIST_EDITING',
}

const actions = {
    [types.SET_TASK_EDITING]({ commit, state: { doing: { task } } }, value)
    {
        commit(mutationTypes.doing.SET_TASK_EDITING, { ...task, editing: value });
    },

    [types.SET_GROUP_EDITING]({ commit, state: { doing: { group } } }, value)
    {
        commit(mutationTypes.doing.SET_GROUP_EDITING, { ...group, editing: value });
    },

    [types.SET_LIST_EDITING]({ commit, state: { doing: { list } } }, value)
    {
        commit(mutationTypes.doing.SET_LIST_EDITING, { ...list, editing: value });
    }
}

export { types as doingActionTypes };
export default actions;

