<template>
    <router-link 
        v-slot="{ navigate }"
        :to="to"
        custom
    >
        <div :class="containerClasses" @click="navigate">
            <div class="flex items-center flex-1">
                <slot name="icon" _class="w-6 flex text-[15px] -mt-0.5" />
                <slot name="text" _class="block md:hidden lg:block text-[15px] text-gray-800" />
            </div>
            <slot name="numbers" _class="text-[15px] block md:hidden lg:block" />
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        to: {
            type: Object,
            required: true
        }
    },
    computed: {
        isActive()
        {
            const route = this.$router.resolve(this.to);
            
            return route && route.resolved.path === this.$route.path;
        },
        containerClasses() {
            return `${this.$spa.css.layout.sidebar_link} relative cursor-pointer ${this.isActive ? 'bg-sidebar-dark' : 'bg-transparent'}`;
        }
    }
}
</script>