<template>
    <div class="flex items-center mt-6 cursor-pointer" @click="action.callback">
        <i class="mr-4 text-sm" :class="icon"></i>
        <span class="text-gray-600 hover:text-primary focus:text-primary">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        action: {
            required: true,
            type: Object
        }
    },
    computed: {
        icon() {
            return this.action.icon[this.key];
        },
        text() {
            return this.action.text[this.key];
        },
        key() {
            return this.action.active ? "active" : "disabled";
        }
    }
}
</script>
