import Field from "./UpdateHandlerField";

class UpdateHandler
{
    constructor(model)
    {
        this.model = model;
        this.initEditing()
            .initCallbacks()
            .persisted = {};
        this.saving = {};
    }

    initEditing()
    {
        this.editing = Object.keys(this.model).reduce((editing, key) => {
            editing[key] = false;
            return editing;
        }, {});

        return this
    }

    initCallbacks()
    {
        this.callbacks = Object.keys(this.model).reduce((callbacks, key) => {
            callbacks[key] = {};
            return callbacks;
        }, {});

        return this
    }

    setSaveCallback(name, callback)
    {
        this.callbacks[name].save = callback;
    }

    setStartCallback(name, callback)
    {
        this.callbacks[name].start = callback;
    }

    setCancelCallback(name, callback)
    {
        this.callbacks[name].cancel = callback;
    }

    setStopCallback(name, callback)
    {
        this.callbacks[name].stop = callback;
    }

    setCallbacks(name, callbacks = {})
    {
        const { save = () => {}, stop = () => {}, cancel = () => {}, start = () => {} } = callbacks;
        this.callbacks[name] = { save, stop, cancel, start };
    }

    persist(name)
    {
        this.persisted[name] = this.model[name];

        return this;
    }

    async save(name, ...args)
    {
        if (this.saving[name]) return;
        if (this.persisted[name] === this.model[name]) return this.stop(name);
        
        try {
            this.startSaving(name)
                .stopEditing(name);
            const response  = await this.callbacks[name].save(this.model, ...args);
            this.stopSaving(name)
                .stop(name);
            return response;
        } catch (error) {
            this.stopSaving(name)
                .cancel(name);
            throw error;
        }
    }

    revert(name)
    {
        this.model[name] = this.persisted[name];

        return this;
    }

    start(name)
    {
        // if (Object.values(this.saving).includes(true)) return;
        this
            .persist(name)
            .startEditing(name);
        
        this.callbacks[name].start();
        
        return this;
    }

    startEditing(name)
    {
        this.editing[name] = true;
        
        return this;
    }

    startSaving(name)
    {
        this.saving[name] = true;
        
        return this;
    }

    stop(name)
    {
        this
            .persist(name)
            .stopEditing(name)
        
        this.callbacks[name].stop();

        return this;
    }

    stopSaving(name)
    {
        this.saving[name] = false;
        
        return this;
    }

    stopEditing(name)
    {
        this.editing[name] = false;
        
        return this;
    }

    cancel(name)
    {
        this.revert(name)
            .stop(name)
            .callbacks[name].cancel();
        
        return this;
    }

    field({ name, callbacks })
    {
        return new Field({ name, callbacks, handler: this });
    }
}

export default UpdateHandler;