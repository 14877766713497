<template>
    <div class="py-2.5 px-4 flex flex-col">
        <slot name="title" _class="flex justify-between items-center pl-1.5 pr-4 py-3">
            <div class="flex justify-between items-center pl-1.5 pr-4 py-3">
                <slot name="title_text" _class="font-medium" />
                <slot name="title_icon" />
            </div>
        </slot>
        <slot name="list"/>
    </div>
</template>

<script>
export default {

}
</script>