<template>
    <div class="flex flex-col items-center justify-center">
        <div class="px-12">
          <img class="max-h-96 w-full max-w-96" :src="illustration" alt="success_icon">
        </div>
        <span class="py-2 text-center text-2xl font-medium">{{ title }}</span>
        <span class="py-2 text-gray-400 text-sm font-light">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title: { required: true, type: String },
        text: { required: true, type: String },
        illustration: { required: true, type: String }
    }
}
</script>
