<template>
    <li 
        class="flex items-center hover:text-primary hover:border-primary rounded-full transition cursor-pointer" 
        :class="{
            'text-gray-600 border-gray-200': !isActive,
            'text-primary border-primary': isActive,
            [containerClasses]: true
        }"
        @click="toggleSelection"
    >
        <i class="fa fa-check" :class="{ 'mr-4': !smallVersion, 'mr-1 text-sm': smallVersion }" v-show="isActive"></i>
        <span class="whitespace-nowrap font-light text-sm">{{ filter.display }}</span>
    </li>
</template>

<script>
import { mapGetters } from "vuex";
import models from '~base/models';
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';
export default {
    props: {
        filter: {
            type: Object,
            required: true
        },
        smallVersion: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    methods: {
        select() {
            return this.$store.dispatch(actionTypes.filters.SELECT, { id: this.filter.id })
        },
        deselect() {
            return this.$store.dispatch(actionTypes.filters.DESELECT, { id: this.filter.id })
        },
        async toggleSelection()
        {
            const ids = await (
                this.isActive
                    ? this.deselect()
                    : this.select()
            )
            const payload = {
                user_id: this.minimalUser.id,
                filters: ids,
                task_list_id: this.list.id
            };
            const filter = await (
                this.isListHavingFilters
                    ? models.taskListUserFilter.update({ 
                        filter: {
                            ...payload,
                            uuid: this.list.current_user_filters.uuid
                        },
                        field: "filters",
                        user: this.minimalUser 
                    })
                    : models.taskListUserFilter.store({ filter: payload, user: this.minimalUser })
            );
            this.updateList({ ...this.list, current_user_filters: filter });
        },
        updateList(list) {
            this.$store.dispatch(actionTypes.lists.update, list)
        }
    },
    computed: {
        ...mapGetters(['minimalUser']),
        isActive()
        {
            return this.activeIds.includes(this.filter.id);
        },
        isListHavingFilters() {
            return !!this.list?.current_user_filters
        },
        list() {
            return this.$store.getters[getterTypes.lists.active];
        },
        activeIds()
        {
            return this.$store.state.filters.active;
        },
        containerClasses() {
            return this.smallVersion
                ? "py-1 px-2 m-1 border md:py-2 md:px-4 md:m-2 md:border-2"
                : "py-2 px-4 m-2 border-2"
        }
    }
}   
</script>

<style>

</style>