<template>
    <sidebar-element :to="{name: 'account.lists.show', params: {list: listParameter }}">
        <template v-slot:text="text">
            <div class="flex items-center">
                <span :class="text._class">{{ list.title }}</span>
                <i 
                    class="ml-2 text-sm fal fa-lock-alt" 
                    v-if="shared"
                >
                </i>
            </div>
        </template>
        <template v-slot:numbers="numbers">
            <span class="text-gray-400" :class="numbers._class">{{ taskCounter }}</span>
        </template>
    </sidebar-element>
</template>

<script>
    import SidebarElement from "~base/components/_Layout/Sidebar/Section/Element.vue";
    import models from '~base/models';

    export default {
        props: {
            list: {
                type: Object,
                required: true
            }
        },
        components: {
            SidebarElement
        },
        computed: {
            listParameter() {
                return this.list.uuid ?? "new";
            },
            shared() {
                return models.taskList.shared(this.list);
            },
            taskCounter() {
                const count = this.list?.not_done_tasks_count;
                return count || '';
            }
        }
    }
</script>