import { api, route } from "~base/helpers";

export default {
    async index({ start, end })
    {
        const { data } = await api.get({
            url: route('api.calendar.tasks.index'),
            params: { start, end }
        });

        return data;
    },

    // Transforming to Task model.
    toTask(calendarEvent)
    {
        const { id, title, start, extendedProps } = calendarEvent;
        return { 
            id, 
            title, 
            ...extendedProps, 
            due_date: start,
            // removing reference for user_ids
            user_ids: [...extendedProps.user_ids] 
        };
    },
    fromCalendar(calendarEvent) {
        const { id, title, start, extendedProps } = calendarEvent;
        return { 
            id, 
            title, 
            start,
            ...extendedProps, 
        };
    }
}