<template>
    <div>
        <span class="w-full py-0.5 px-[3px] text-[15px] text-gray-700 bg-white border border-dashed border-gray-600 focus:outline-none text-right">
            {{ formattedDate }}
        </span>
        <div class="fixed inset-0 md:absolute md:right-0 md:left-auto md:bottom-auto md:top-full md:mt-2 z-[60] p-8 md:p-4 md:rounded-lg bg-white shadow flex flex-col justify-center md:justify-start h-full md:h-auto">
            <modal-close-icon @close="$emit('cancel')" />
            <date-picker
                class="!border-0"
                @input="$emit('date', $event)"
                :value="date"
                :is-inline="true"
                :locale="$i18n.locale"
                :is-expanded="true"
            />
            <div class="flex items-center justify-between pt-4 border-t border-gray-250">
                <time-picker-options v-slot="{ elements }" v-if="hasTime">
                    <base-time-picker
                        class="flex-auto"
                        :options="elements"
                        @input="$emit('time', $event)"
                        :value="time ? time : $t('pages.list.task.due_date.picker.time.empty')"
                    />
                </time-picker-options>
                <div 
                    v-else 
                    @click="$emit('time', '08:00')"
                    class="flex items-center text-gray-600 cursor-pointer hover:text-primary"
                >
                    <i class="mr-2 text-sm fal fa-plus"></i>
                    <span>{{ $t("pages.list.task.due_date.picker.time.add") }}</span>
                </div>
                <button 
                    @click="$emit('save')"
                    class="ml-4 px-4 py-1.5 bg-primary text-white hover:bg-primary-dark transition rounded outline-none"
                >
                    {{ $t("pages.list.task.due_date.picker.time.save") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTimePicker from "~base/components/_Partials/TimePicker.vue";
import TimePickerOptions from "~base/components/_Partials/TimePickerOptions.vue";
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import ModalCloseIcon from "~base/components/_Partials/Modals/CloseIcon.vue";

export default {
    props: {
        date: {
            required: true
        },
        time: {
            required: true
        },
        formattedDate: {
            required: true
        },
        hasTime: {
            required: true,
            type: Boolean
        }
    },
    components: {
        DatePicker,
        BaseTimePicker,
        TimePickerOptions,
        ModalCloseIcon
    }
}
</script>
<style>
    .is-today .vc-day-content {
        background: #EFEEEE;
    }
    .is-today .vc-highlights + .vc-day-content {
        background: transparent;
    }
</style>