<template>
    <portal to="modals">
        <v-spa-right-panel-modal
            :name="name"
            @before-open="init"
        >
            <span class="mb-8 text-3xl text-gray-800">{{ list.title }}</span>
            <list-element v-for="action in actions" :key="action.id" :action="action" />
        </v-spa-right-panel-modal>
    </portal>
</template>

<script>
import modals from "~base/components/Modals/Config";
import ListElement from "~base/components/Modals/ListHeader/Element.vue";
import { getterTypes } from '~base/store/getters';

export default {
    components: {
        ListElement
    },
    data()
    {
        return {
            name: modals().listHeader.name,
            modals: modals.apply(this),
            shareable: false             
        }
    },
    methods: {
        addGroup() {
            this
                .hide()
                .$emit("addGroup");
        },
        toggleLocked() {
            this
                .hide()
                .$emit("toggleLocked");
        },
        toggleSorting() {
            this.hide()
                .$emit("toggleSorting")
        },
        toggleFilters() {
            this.hide()
                .$emit("toggleFilters")
        },
        toggleFavorite() {
            this
                .hide()
                .$emit("toggleFavorite");
        },
        toggleArchive() {
            this
                .hide()
                .$emit("toggleArchive");
        },
        toggleShare() {
            this
                .hide()
                .$emit("toggleShare");
        },
        hide() {
            this.modals[this.name].hide();
            return this;
        },
        init({ params }) {
            const { shareable } = params;
            this.shareable = shareable;
        }
    },
    computed: {
        minimalUser() {
            return this.$store.getters[getterTypes.user.MINIMAL_USER];
        },
        list() {
            return this.$store.getters[getterTypes.lists.active] || {};
        },
        isFavorite() {
            return !!this.list?.current_user_filters?.favorite_at;
        },
        isArchive() {
            return !!this.list.archived_at;
        },
        isLocked() {
            return !!this.list.locked_at;
        },
        allActions() {
            if (!this.list) return [];
            return [
                {
                    id: "group",
                    icon: { active: "far fa-stream text-gray-400" },
                    text: { active: this.$t("modals.list.menu.add_group.active") },
                    callback: this.addGroup,
                    active: true
                },
                {
                    id: "sorting",
                    icon: { active: "fas fa-sort-amount-down text-gray-400" },
                    text: { active: this.$t("modals.list.menu.sort.active") },
                    callback: this.toggleSorting,
                    active: true
                },
                {
                    id: "filters",
                    icon: { active: "fas fa-filter text-gray-400" },
                    text: { active: this.$t("modals.list.menu.filter.active") },
                    callback: this.toggleFilters,
                    active: true
                },
                {
                    id: "locked",
                    icon: { 
                        active: "far fa-lock-alt text-gray-400",
                        disabled: "far fa-lock-open-alt text-gray-400"
                    },
                    text: { 
                        active: this.$t("modals.list.menu.lock.active"),
                        disabled: this.$t("modals.list.menu.lock.disabled")
                    },
                    callback: this.toggleLocked,
                    active: this.isLocked
                },
                {
                    id: "favorite",
                    icon: { 
                        active: "fas fa-star text-[#ffc107]",
                        disabled: "fas fa-star text-gray-400"
                    },
                    text: { 
                        active: this.$t("modals.list.menu.favorite.active"),
                        disabled: this.$t("modals.list.menu.favorite.disabled")
                    },
                    callback: this.toggleFavorite,
                    active: this.isFavorite
                },
                {
                    id: "archive",
                    icon: {
                        active: "far fa-box-alt text-gray-400",
                        disabled: "far fa-box-open text-gray-400"
                    },
                    text: { 
                        active: this.$t("modals.list.menu.archive.active"),
                        disabled: this.$t("modals.list.menu.archive.disabled")
                    },
                    callback: this.toggleArchive,
                    active: this.isArchive
                },
                {
                    id: "share",
                    icon: { 
                        active: "fas fa-users text-gray-400",
                    },
                    text: { 
                        active: this.$t("modals.list.menu.share.active"),
                    },
                    callback: this.toggleShare,
                    active: this.shareable
                }
            ];
        },
        actions() {
            // Not showing share action if list is not shareable
            return this.allActions.filter(a => a.id !== "share" || a.active);
        }
    }
}
</script>
