<template>
    <figure class="h-full w-full">
        <img
            v-if="hasAvatar"
            class="h-full w-full rounded-full object-cover" 
            :src="user.avatar"
            :alt="user.first_name"
        >
        <div class="flex h-full w-full rounded-full items-center justify-center bg-gray-200" v-else>
            <i class="fal fa-user text-sm"></i>
        </div>
    </figure>
</template>

<script>
export default {
    props: {
        user: {
            required: true,
            type: Object
        }
    },
    computed: {
        hasAvatar() {
            return Object.keys(this.user).length > 0 && this.user.avatar;
        }
    }
}
</script>