<template>
    <div class="flex flex-col">
        <span class="text-gray-800 mb-2 text-[15px] font-medium"> {{ $t("pages.list.task.users.picker.title") }} </span>
        <input
            :placeholder="$t('pages.list.task.users.picker.search.placeholder')"
            type="text"
            class="border border-gray-300 outline-none focus:border-primary rounded px-3 py-2 text-gray-800 transition"
            v-bind="input.attributes"
            v-on="input.events"
            v-focus
        >
        <ul class="flex flex-col mt-2 max-h-40 overflow-y-auto">
            <picker-user 
                :delete="deleteElement" 
                :add="addElement" 
                :selected="selected" 
                :highlighted="isHighlighted"
                :element="user"
                :key="user.id" 
                v-for="user in elements.matchingInput"
            />
        </ul>
    </div>
</template>

<script>
import PickerUser from "~base/components/_Partials/UserPicker/User.vue";
import directives from "~base/directives";
import helpers from '~base/helpers';

export default {
    props: ['deleteElement', 'addElement', 'toggleElement', 'isHighlighted', 'highlight', 'hasHighlight', 'highlightNextElement', 'highlightPreviousElement', 'input', 'elements', 'selected'],
    components: {
        PickerUser
    },
    data() {
        return {
            keypress: {}
        };
    },
    methods: {
        next() {
            console.log("next")
            this.highlightNextElement(this.elements.matchingInput);
        },
        previous() {
            console.log("previous")
            this.highlightPreviousElement(this.elements.matchingInput);
        },
        toggle() {
            // if (this.hasHighlight) {
            //     this.selected(this.highlight) ? this.deleteElement(this.highlight) : this.addElement(this.highlight);
            // }
            this.toggleElement(this.highlight);
        },
        mountKeypress() {
            this.keypress = new helpers.keypress(window);
            window.addEventListener(this.keypress.getEventName(['ArrowUp']), this.previous);
            window.addEventListener(this.keypress.getEventName(['ArrowDown']), this.next);
            window.addEventListener(this.keypress.getEventName(['Enter']), this.toggle);
        },
        destroyKeypress() {
            window.removeEventListener(this.keypress.getEventName(['ArrowUp']), this.previous);
            window.removeEventListener(this.keypress.getEventName(['ArrowDown']), this.next);
            window.removeEventListener(this.keypress.getEventName(['Enter']), this.toggle);
            this.keypress.removeListeners();
            this.keypress = {};
        }
    },
    directives: {
        focus: directives.focus
    },
    created() {
        this.mountKeypress();
    },
    beforeDestroy() {
        this.destroyKeypress();
    }
}
</script>

<style>

</style>