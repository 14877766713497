<template>
    <portal to="modals">
        <calendar-task />
        <task-edit />
        <task-comments />
        <comment-image />
        <item-delete />
    </portal>
</template>

<script>
import TaskComments from "~base/components/Modals/Comments.vue"
import CommentImage from "~base/components/Modals/CommentImage.vue"
import CalendarTask from "~base/components/Modals/CalendarTask.vue"
import ItemDelete from "~base/components/Modals/Delete.vue"
import TaskEdit from "~base/components/Modals/TaskEdit.vue"

export default {
    components: {
        TaskComments,
        CommentImage,
        CalendarTask,
        ItemDelete,
        TaskEdit,
    }
}
</script>

<style>

</style>