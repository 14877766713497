const types = {
    'IS_SMALLER_THAN_MD': 'IS_SMALLER_THAN_MD'
}

const getters = {
    [types.IS_SMALLER_THAN_MD]({ screens }) {
        return screens.all.slice(0, 2).map(({ id }) => id).includes(screens.actual);
    }
}

export { types as screenGetterTypes };
export default getters;

