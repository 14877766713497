import { api } from "~base/helpers"

export default {

    getUserAccounts(context, user)
    {
        return new Promise((resolve, reject) => {
            context.dispatch('getProfessionals', user)
                .then(professionals => {
                    let promises = [];
                    for ( let i = 0; i < professionals.length; i++ ) {
                        promises.push(
                            context.dispatch('getProfessionalAccounts', professionals[i])
                        );
                    }
                    Promise.all(promises)
                        .then(() => {
                            resolve(professionals);
                        });
            });
        });
    },

    getUserActiveAccounts(context, user)
    {
        return new Promise((resolve, reject) => {
            context.dispatch('getProfessionals', user)
                .then(professionals => {
                    let promises = [];
                    for ( let i = 0; i < professionals.length; i++ ) {
                        promises.push(
                            context.dispatch('getProfessionalAccounts', professionals[i])
                        );
                    }
                    Promise.all(promises)
                        .then(() => {
                            resolve(professionals.filter(professional => professional.accounts.length > 0));
                        });
            });
        });
    },

    getProfessionalAccounts(context, professional)
    {
        return new Promise((resolve) => {
            context.dispatch('getAccounts', professional)
                .then(accounts => {
                    professional.accounts = accounts;
                    resolve(professional);
                });
        });
    },

    getAccounts(context, professional)
    {
        return context.dispatch('getAccountData', {
            url: `${window.url.api}/accounts/key/${professional.authorization_key}`
        }).then( ({ data: accounts }) => accounts );
    },

    storeAccount(context, account)
    {
        return context.dispatch('postAccountData', {
            url: `${window.url.api}/accounts`,
            data: account,
        });
    },

    getAccount(context, uuid)
    {
        return context.dispatch('getAccountData', {
            url: `${window.url.api}/accounts/${uuid}`,
        }).then( ({data: account}) => account );
    },

    setAccount(context, account)
    {
        context.commit('setAccount', account);
        const { name = '', uuid = '' } = account ?? {};
        api.setConfig({
            headers: {
                'X-Account-UUID': uuid,
                'X-TrustUp-Authorization': context.getters.apiHeaders.Authorization
            }
        });
        context.dispatch('setVariableInTitle', {variable: 'accountname', value: name});
        return account;
    }
}