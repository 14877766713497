<template>
    <v-spa-modal
        :name="name"
        height="100%"
        width="100%"
        :show-close-icon="true"
        @before-open="init"
    >
        <div class="">
            <v-spa-loader v-if="loader.task.loading" className="bg-white absolute inset-0 z-20 text-center" />
            <div class="flex items-center h-screen" v-else>
                <div class="relative flex flex-col max-w-lg mx-auto px-7">
                    <input
                        v-model="updater.fields.title.model"
                        type="text" 
                        class="pb-3 py-2 bg-gray-50 !text-lg text-gray-800 focus:outline-none border-b border-gray-400 focus:border-primary hover:border-primary"
                        placeholder="Titre de la tâche"
                        @focus="() => updater.fields.title.start()"
                        @blur="() => updater.fields.title.save()"
                    >
                    <task-edit-element :title="$t('modals.task.color.title')">
                        <color-picker :colors="colors" v-model="pickerActiveColors" />
                    </task-edit-element>

                    <task-edit-element :title="$t('modals.task.collaborators.title')">
                        <user-list-and-picker 
                            :field="updater.fields.user_ids" 
                            :tooltip="{ data: $t('pages.list.task.users.add'), location: 'top' }"
                        />
                    </task-edit-element>

                    <task-edit-element :title="$t('modals.task.due_date.title')">
                        <div 
                            class="py-2 pb-3 border-b border-gray-400 cursor-pointer focus:outline-none hover:border-primary focus:border-primary"
                            @click="() => updater.fields.due_date.start()"
                            v-if="!updater.fields.due_date.editing"
                        >
                            {{ formattedDueDate }}
                        </div>
                        <due-date-picker
                            :formattedDate="formattedDueDate"
                            :date="calendarDueDate"
                            :time="calendarDueTime"
                            :has-time="calendarHasTime"
                            @time="calendarDueTime = $event"
                            @date="calendarDueDate = $event"
                            @save="saveDatePicker"
                            @cancel="cancelDatePicker"
                            v-else
                        />
                    </task-edit-element>

                    <task-edit-element :title="$t('modals.task.comments.title')">
                        <button @click="showComments">
                            <i :class="{ 'text-primary fas fa-comments': task.comments_count, 'text-gray-400 far fa-comment': !task.comments_count }"></i>
                        </button>
                    </task-edit-element>

                    <task-edit-element>
                        <button 
                            class="relative flex items-center px-4 py-3 text-sm text-base tracking-tight text-white transition-all duration-300 bg-red-500 rounded-lg outline-none hover:bg-red-600"
                            @click="showDeleteModal"
                        >
                            <i class="mr-2 far fa-trash-alt"></i>
                            {{ $t("modals.task.buttons.delete") }}
                        </button>
                    </task-edit-element>
                </div>
            </div>
        </div>
    </v-spa-modal>
</template>

<script>
import Updater from "~base/classes/Models";
import ColorPicker from "~base/components/_Partials/ColorPicker.vue";
import UserListAndPicker from "~base/components/TaskList/Show/Partials/UserListAndPicker.vue";
import DueDatePicker from "~base/components/TaskList/Show/Task/DueDatePicker.vue";
import TaskEditElement from "~base/components/Modals/TaskEdit/Element.vue";
import modals from "~base/components/Modals/Config";
import models from "~base/models";
import helpers from '~base/helpers';
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';

export default {
    components: {
        ColorPicker,
        UserListAndPicker,
        DueDatePicker,
        TaskEditElement
    },
    data()
    {
        return {
            name: modals().taskEdit.name,
            modals: modals.apply(this),
            task: {},
            loader: {
                task: new helpers.loader(true),
            },
            colors: models.taskColor.all,
            updater: {
                fields: {
                    title: {},
                    due_date: {},
                    is_done: {},
                    user_ids: {},
                    color_id: {},
                    show: {},
                    due_date_has_time: {}
                }
            },
            due_date: {
                format: {
                    display: "DD MMM YYYY",
                    store: "YYYY-MM-DD HH:mm:ss"
                },
            },
            debounce: {
                color_id: null,
                watch: null
            }
        }
    },
    methods: {
        setTask(task)
        {
            this.loader.task.loadTill(() => this.task = task)
            return this;
        },
        setUpdater() {
            this.updater.handler = new Updater(this.task);
            const { handler, fields } = this.updater;
            Object.keys(fields).forEach(key => {
                const field = handler.field({
                    name: key,
                    callbacks: {
                        save: this.handleUpdate.bind(this, { field: key }),
                    }
                });
                fields[key] = field;
            });
            return this;
        },
        setDebounce() {
            this.debounce.color_id = helpers.debounce(this.saveColor, 500)
            return this;
        },
        init({ params: { task } }) {
            this.setTask(task)
                .setUpdater()
                .setDebounce();
        },
        saveColor(color_id) {
            if (color_id.saving) {
                this.debounce.watch = this.$watch('updater.fields.color_id.saving', () => {
                    this.debounce.watch();
                    color_id.save();
                });
            }
            color_id.save();
        },
        saveDatePicker() {
            const { due_date, due_date_has_time } = this.updater.fields;
            if (due_date_has_time.dirty && !due_date.dirty) due_date_has_time.save();
            else due_date_has_time.stop();
            due_date.save();
        },
        cancelDatePicker() {
            const { due_date, due_date_has_time } = this.updater.fields;
            due_date.cancel();
            due_date_has_time.cancel();
        },
        openDatePicker() {
            const { due_date, due_date_has_time } = this.updater.fields;
            due_date.start();
            due_date_has_time.start();
        },
        getNewCalendarDate(value) {
            const { due_date } = this.updater.fields;
            if (!value) return value;
            // Preventing save when setting initially
            if (moment(this.calendarDueDate).isSame(value)) return false;
            if (!due_date.model) {
                return moment(value)
                    .hour(0)
                    .minute(0)
                    .format(this.due_date.format.store);
            }
            const momentDueDate = moment(due_date.model);
            const newMomentDate = moment(value);
            return momentDueDate.year(newMomentDate.get('year'))
                .month(newMomentDate.get('month'))
                .date(newMomentDate.get('date'))
                .format(this.due_date.format.store);
        },
        showDeleteModal() {
            this.modals.delete.show({ item: this.task, callback: this.handleDelete })
        },
        showComments()
        {
            this.modals.comments.show({ task: this.task })
            this.$emit('showTaskComments');
        },
        async handleUpdate({ field })
        {
            try {
                const { due_date } = this.updater.fields;
                const { model } = due_date;
                const { persisted = model } = due_date;
                const isDone = this.updater.fields.is_done.model;
                const wasDone = this.updater.fields.is_done.persisted ?? isDone;
                const task = await this.updateTask({ field, user: this.minimalUser });
                this.counterTaskUpdated({ model, persisted, isDone, wasDone });
                console.log('updated');
                this.$bus.$emit('updateTask', this.task.uuid);
                // this.handlePostSave(field);
                return task;
            } catch (err) {
                console.error('something went wrong');
                throw err;
            }
        },
        updateTask({ field, user = this.minimalUser })
        {
            return models.task.update(this.task, { field, user });
        },
        counterTaskStored(values) {
            this.$store.dispatch(actionTypes.tasks.TASK_STORED, values);
            const { isDone } = values;
            if (!isDone) return this.incrementTaskCounter()
        },
        counterTaskUpdated(values) {
            this.$store.dispatch(actionTypes.tasks.TASK_UPDATED, values);
            const { wasDone, isDone } = values;
            if (isDone && !wasDone) return this.decrementTaskCounter();
            if (!isDone && wasDone) return this.incrementTaskCounter();
        },
        counterTaskDeleted(values) {
            this.$store.dispatch(actionTypes.tasks.TASK_DELETED, values);
            const { wasDone } = values;
            if (!wasDone) this.decrementTaskCounter();
        },
        incrementTaskCounter() {
            this.addToTaskCounter(1);
        },
        decrementTaskCounter() {
            this.addToTaskCounter(-1);
        },
        addToTaskCounter(number) {
            this.updateListCounter(this.list.not_done_tasks_count + number);
        },
        updateListCounter(counter) {
            this.updateList({ 
                ...this.list, 
                not_done_tasks_count: counter
            });
        },
        updateList(list) {
            this.$store.dispatch(actionTypes.lists.update, list)
        },
        async handleDelete()
        {
            try {
                const { model } = this.updater.fields.due_date;
                this.$bus.$emit('deleteTask', this.task.uuid);
                this.counterTaskDeleted({ model, wasDone: this.updater.fields.is_done.model });
                this.deleteTask({ user: this.minimalUser, options: { nested_tasks: true } })
                    .then(() => console.log('successfully deleted'));
                this.modals.taskEdit.hide();
                
            } catch(err) {
                console.error(err);
                console.error("couldn't delete");
            }
        },
        deleteTask({ user, options })
        {
            return models.task.delete(this.task, { user, options });
        },
    },
    computed: {
        minimalUser() {
            return this.$store.getters[getterTypes.user.MINIMAL_USER];
        },
        pickerActiveColors: {
            // formatting for selector
            get() {
                return [{ id: this.updater.fields.color_id.model }]
            },
            // Making sure default color is selected if user unchecks all
            set(colors) {
                const { color_id } = this.updater.fields;
                if (!color_id.editing) color_id.start();
                color_id.model = (colors.length ? colors[0].id : models.taskColor.default.id);
                this.debounce.color_id(color_id);
            }
        },
        calendarDueDate: {
            get() {
                const { due_date } = this.updater.fields;
                if (!due_date.model) return null;
                return moment(due_date.model)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .toDate();
            },
            set(value) {
                console.log({ value });
                const newValue = this.getNewCalendarDate(value);
                if (newValue === false) return;
                const { due_date } = this.updater.fields;
                if (!newValue) this.calendarHasTime = false;
                due_date.model = newValue;
                due_date.save();
            }
        },
        calendarDueTime: {
            get() {
                const { due_date } = this.updater.fields;
                if (!due_date.model || !this.task.due_date_has_time) return null;
                return moment(due_date.model).format('HH:mm');
            },
            set(value) {
                console.log({value}, 'timepicker');
                this.calendarHasTime = !!value;
                if(!value) return;
                const [ hour, minute ] = value.split(':');
                const { due_date } = this.updater.fields;
                if (!due_date.model) return;
                due_date.model = moment(due_date.model)
                    .hour(hour)
                    .minute(minute)
                    .format(this.due_date.format.store);
                // const { due_date } = this.updater.fields;
                // // Preventing save when setting initially
                // if (moment(due_date.model).isSame(value)) return;

                // console.log(due_date.model = value
                //     ? moment(value).format(this.due_date.format.store)
                //     : value)
                // due_date.save();
            }
        },
        calendarHasTime: {
            get() {
                return this.task.due_date_has_time || false;
            },
            set(value) {
                console.log({value}, 'hasTime');
                const { due_date_has_time, due_date } = this.updater.fields;
                // if (due_date.model && value && !this.calendarDueTime) this.calendarDueTime = '08:00';
                due_date_has_time.model = value;
            }
        },
        formattedDueDate()
        {
            const { model } = this.updater.fields.due_date;
            if (!model) return "Aucune date";
            const momentModel = moment(model);
            if (!this.calendarHasTime) return momentModel.format(this.due_date.format.display)

            return momentModel.format(`${this.due_date.format.display} HH:mm`)
        },
        stored() {
            return !!this.task.uuid;
        },
        list() {
            return this.$store.getters[getterTypes.lists.active];
        },
    }
}
</script>
