<template>
    <div class="cursor-pointer px-1" @click="handleClick">
        <div class="rounded-full border-2 relative" :class="[(active ? 'border-primary' : 'border-transparent')]">
            <div class="h-8 w-8 rounded-full border-2 border-transparent">
                <slot />
            </div>
            <div v-show="active" class="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-[.15rem] h-4 w-4 rounded-full flex bg-primary items-center justify-center">
                <i class="fas fa-check text-[.5rem] text-white"></i>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        element: {
            required: true,
            type: Object
        },
        delete: {
            required: true,
            type: Function
        },
        add: {
            required: true,
            type: Function
        },
        selected: {
            required: true,
            type: Function
        }
    },
    components: {},
    methods: {
        handleClick()
        {
            this.active
                ? this.delete(this.element)
                : this.add(this.element)
        }
    },
    computed: {
        active()
        {
            return this.selected(this.element);
        }
    }
}
</script>

<style>

</style>