<template>
    <v-spa-modal
        :name="modals.calendarTask.name"
        height="auto"
        :scrollable="true"
        :shift-x="0.5"
        :shift-y="0.5"
        :show-close-icon="false"
        classes="!overflow-visible"
        @before-open="setTask" 
    >
        <v-spa-loader v-if="loading.task" />
        <div v-else>
            <div class="flex items-center justify-between">
                <div class="flex items-center -mx-1">
                    <span class="mx-1 text-3xl text-gray-800">{{ $t("modals.task.title") }}</span>
                    <button class="p-2 mx-1" @click="showList">
                        <i class="text-lg far fa-external-link-alt text-primary"></i>
                    </button>
                </div>
                <button class="p-2 group" @click="modals.calendarTask.hide">
                    <img src="/spa/close.svg" class="w-4 h-4 opacity-50 group-hover:opacity-100" />
                </button>
            </div>
            <div class="py-4">
                <task-item
                    :task="task"
                    :useNested="false"
                    :disable-drag="true"
                    @deleteTask="handleDelete"
                    @updateTask="handleUpdate"
                    @showTaskComments="handleComments"
                />
            </div>
        </div>
    </v-spa-modal>
</template>

<script>
import modals from "~base/components/Modals/Config";
import TaskItem from "~base/components/TaskList/Show/Task.vue";
import models from '~base/models';
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        TaskItem
    },
    data()
    {
        return {
            modals: modals.bind(this)(),
            task: {},
            loading: {
                task: true,
            }
        }
    },
    methods: {
        ...mapActions(['updateEvent', 'destroyEvent']),
        setTask({ params: { task } })
        {
            this.loading.task = true;
            this.task = task;
            this.loading.task = false;
        },
        handleDelete()
        {
            this.destroyEvent({ event: { uuid: this.task.uuid } })
            console.log('should delete and close');
            this.modals.calendarTask.hide();
        },
        handleUpdate()
        {
            const event = models.task.toCalendarTask(this.task);
            // if event is done close modal and delete it from calendar
            if (event.is_done) {
                this.destroyEvent({ event });
                this.modals.calendarTask.hide();
            } else {
                this.updateEvent({ event })
            }
        },
        handleComments()
        {
            this.modals.calendarTask.hide();
        },
        showList()
        {
            if (this.list) {
                this.modals.calendarTask.hide();
                this.$router.push({ name:'account.lists.show', params: { list: this.list.uuid } });
            }
        }
    },
    computed: {
        ...mapState(['lists']),
        list()
        {
            return this.lists.find(l => l.id === this.task.task_list_id);
        }
    }
}
</script>