import Vue from "vue";

const bus = new Vue();

const plugin = {
    install(Vue) {
        Vue.prototype.$bus = bus;
    }
}

Vue.use(plugin);

export default plugin;