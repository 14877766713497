import { Api } from "./api";
import route from './ziggy';
import sorting from './sorting';
import debounce from './debounce';
import keypress from './keyPress';
import swipe from './swipe';
import zoom from './zoom';
import loader from './loader';
import formData from "./formData";
import tailwind from "./tailwind";
import string from "./string";
import try_to from "./try";

const api =  new Api();
const helpers = { api, route, sorting, debounce, keypress, swipe, zoom, loader, formData, tailwind, string, try: try_to };

export { api, route, sorting, debounce, keypress, swipe, zoom, loader, formData, tailwind, string, try_to };
export default helpers;