<template>
    <li class="md:px-6 py-2 md:py-4 flex items-center rounded-md">
        <img
            class="h-12 w-12 object-cover rounded-full mr-4"
            :src="user.avatar"
            :alt="user.full_name"
        />
        <div class="flex flex-col">
            <span class="text-gray-800 font-medium">{{ user.full_name }}</span>
            <div class="flex -mx-2 py-2">
                <apple-checkbox class="px-2" :label="$t('pages.settings.collaborators.options.emails')" v-model="emails" />
            </div>
        </div>
    </li>
</template>

<script>
import Updater from '~base/classes/Models'
import AppleCheckbox from "~base/components/_Partials/AppleCheckbox.vue"
import helpers from '~base/helpers'
import models from '~base/models'
import { actionTypes } from '~base/store/actions'

export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    components: {
        AppleCheckbox
    },
    methods: {
        saveSetting({ field, value }) {
            if (this.hasSetting) return this.updateSetting({ field, value })
            this.storeSetting({ field, value })
        },
        storeSetting({ field, value }) {
            const user = this.user;
            const setting = models.userSetting.new({ user }, { options: { [field]: value } });
            const save = async () => {
                const value = await models.userSetting.store({ setting, user })
                this.updateUserSetting(value)
            };
            this.handleSaveSetting({ setting, save });
        },
        updateSetting({ field, value }) {
            const user = this.user;
            const options = { ...this.user.setting.options, [field]: value };
            const setting = { ...this.user.setting, options };
            const save = async () => {
                await models.userSetting.update({
                    user,
                    setting,
                    field
                })
                this.updateUserSetting(setting)
            };
            this.handleSaveSetting({ setting, save });
        },
        handleSaveSetting({ setting, save }) {
            const updater = new Updater(this.user);
            const field = updater.field({
                name: "setting",
                callbacks: { save }
            })
            field.start().model = setting;
            field.save();
        },
        updateUserSetting(setting) {
            return this.updateUser({ ...this.user, setting });
        },
        updateUser(user) {
            this.$store.dispatch(actionTypes.users.UPDATE_USER, user);
        }
    },
    computed: {
        emails: {
            set(value) {
                this.saveSetting({ value, field: "emails" })
            },
            get() {
                return this.user?.setting?.options?.emails || false
            }
        },
        hasSetting() {
            return !!this.user?.setting;
        }
    }
}
</script>
