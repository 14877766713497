import { route, api } from "~base/helpers";
export default {
    async store({ user, setting })
    {
        const { data } = await api.post({
            url: route('api.userSettings.store'),
            data: {
                setting,
                user
            }
        });

        return data;
    },

    async update({ user, setting, field })
    {
        const { data } = await api.put({
            url: route('api.userSettings.update', { userSetting: setting.uuid }),
            params: { field },
            data: {
                setting,
                user
            }
        });

        return data;
    },

    async index({ users })
    {
        const { data } = await api.get({
            url: route('api.userSettings.index'),
            params: {
                users: users.map(u => u.id),
            }
        });

        return data;
    },


    new({ user }, overload) {
        return {
            options: null,
            user_id: user.id,
            ...overload
        }
    }
}