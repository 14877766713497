<template>
    <div class="flex flex-col">
        <sidebar-favorite-lists />
        <sidebar-shared-and-public-lists />
        <sidebar-private-lists />
    </div>
</template>



<script>

    import SidebarFavoriteLists from "~base/components/_Layout/Sidebar/Lists/Favorite.vue";
    import SidebarSharedAndPublicLists from "~base/components/_Layout/Sidebar/Lists/SharedAndPublic.vue";
    import SidebarPrivateLists from "~base/components/_Layout/Sidebar/Lists/Private.vue";

    export default {
        components: {
            SidebarFavoriteLists,
            SidebarSharedAndPublicLists,
            SidebarPrivateLists,
        }
    }
</script>
