import { mutationTypes } from "../mutations";

const types = {
    SELECT: "orders_select",
    SHOW : "orders_show",
    HIDE: "orders_hide",
    TOGGLE_DISPLAY: "orders_toggle",
    GET_TRANSLATED: "ORDERS_GET_TRANSLATED",
}

export default {
    [types.SELECT]({ commit }, order = {})
    {
        const { id = 'custom' } = order;
        commit('orders_select', id);
    },

    [types.SHOW]({ commit })
    {
        commit('orders_display', true);
    },

    [types.HIDE]({ commit })
    {
        commit('orders_display', false);
    },

    [types.TOGGLE_DISPLAY]({ commit, state })
    {
        commit('orders_display', !state.orders.display);
    },

    [types.GET_TRANSLATED]({ commit })
    {
        commit(mutationTypes.orders.GET_TRANSLATED);
    },
}

export { types as ordersActionTypes };