const types = {
    'SET_SCREEN': 'SCREENS_SET_SCREEN'
}

const mutations = {
    [types.SET_SCREEN]({ screens }, screen) {
        screens.actual = screen;
    }
}

export { types as screenMutationTypes };
export default mutations;

