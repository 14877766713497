const types = {
    "IS_EDITING": "DOING_IS_EDITING"
};

const getters = {
    [types.IS_EDITING]({ doing })
    {
        return Object.values(doing).find( ({ editing }) => editing) ? true : false;
    }
}

export { types as doingGetterTypes }
export default getters;