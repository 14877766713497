<template>
  <illustration
    :title="$t('pages.archives.empty.text')"
    :text="$t('pages.archives.empty.last_words')"
    illustration="/images/pages/archives/empty.svg"
  />
</template>

<script>
import Illustration from '~base/pages/_includes/Illustration.vue'
export default {
  components: { Illustration }
}
</script>
