<template>
    <li
        ref="el"
        class="px-2 py-1 rounded cursor-pointer focus:outline-none"
        :class="{
            'bg-primary text-white': active,
            'text-gray-700 hover:text-white hover:bg-primary': selected,
        }"
        tabindex="0"
        @click="select(element)"
        @keydown.enter.stop.prevent="selectIfHighlighted"
    >
        <span>
            {{ element.text }}
        </span>
    </li>
</template>

<script>
export default {
    props: {
        element: {
            required: true,
            type: Object
        },
        selected: {
            required: true,
            type: Boolean
        },
        select: {
            required: true,
            type: Function
        },
        highlighted: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        goToElement(is_active) {
            if(!is_active) return;
            this.$el.focus();
        },
        selectIfHighlighted() {
            this.highlighted && this.select(this.element);
        }
    },
    computed: {
        active() {
            return this.highlighted || this.selected;
        }
    },
    watch: {
        active(is_active) {
            this.goToElement(is_active);
        }
    },
    mounted() {
        this.goToElement(this.active);
    }
}
</script>

<style>

</style>