import moment from "moment";
export default {
    ascending(a, b) {
        if (a === null) return 1;
        if (b === null) return -1;

        const momentA = moment(a);
        if (momentA.isAfter(b)) return 1;
        if (momentA.isSame(b)) return 0;
        return -1;
    },
    descending(a, b) {
        if (a === null) return 1;
        if (b === null) return -1;

        const momentA = moment(a);
        if (momentA.isBefore(b)) return 1;
        if (momentA.isSame(b)) return 0;
        return -1;
    }
}