<template>
    <div class v-if="!isDefaultSorting">
        <div 
            class="flex items-center text-primary border-primary rounded-full transition cursor-pointer"
            :class="containerClasses"
            @click="disableSorting"
        >
            <div 
                class="flex items-center justify-center h-4 w-4 rounded-md"
                :class="{ 'mr-1': smallVersion, 'mr-2': !smallVersion }"
                :data-tooltip="$t('pages.list.sort.reverse')"
                @click.stop="selectLinkedSorting"
            >
                <i class="text-sm group-hover:text-primary transition" :class="selectedSorting.icon"></i>
            </div>
            <span class="whitespace-nowrap font-light text-sm">{{ selectedSorting.display }}</span>
        </div>
    </div>
</template>

<script>
import models from '~base/models';
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';
export default {
    props: {
        smallVersion: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            icon: ""
        }
    },
    methods: {
        selectLinkedSorting() {
            if (!this.havingLinkedSortings) return;
            this.setSelectedOrder(this.linkedSortings[0])
        },
        disableSorting() {
            this.setSelectedOrder({ id: "custom" })
        },
        setIcon(value) {
            this.icon = value;
        },
        async setSelectedOrder(selected) {
            this.$store.dispatch(actionTypes.orders.SELECT, selected);
            const payload = {
                user_id: this.minimalUser.id,
                sorting: selected.id,
                task_list_id: this.list.id
            };
            const filter = await (
                this.isListHavingFilters
                    ? models.taskListUserFilter.update({ 
                        filter: {
                            ...payload,
                            uuid: this.list.current_user_filters.uuid
                        },
                        field: "order",
                        user: this.minimalUser 
                    })
                    : models.taskListUserFilter.store({ filter: payload, user: this.minimalUser })
            );
            this.updateList({ ...this.list, current_user_filters: filter });
        },
        hideMenu() {
            this.$store.dispatch(actionTypes.orders.HIDE);
        },
        updateList(list) {
            this.$store.dispatch(actionTypes.lists.update, list)
        }
    },
    computed: {
        minimalUser() {
            return this.$store.getters[getterTypes.user.MINIMAL_USER];
        },
        selectedSorting() {
            return this.$store.getters[getterTypes.orders.SELECTED];
        },
        isDefaultSorting() {
            return this.$store.getters[getterTypes.orders.IS_DEFAULT_ORDER];
        },
        linkedSortings() {
            return this.$store.getters[getterTypes.orders.SELECTED_SAME_GROUP_ORDERS];
        },
        havingLinkedSortings() {
            return !!this.linkedSortings.length
        },
        list() {
            return this.$store.getters[getterTypes.lists.active];
        },
        isListHavingFilters() {
            return !!this.list?.current_user_filters
        },
        containerClasses() {
            return this.smallVersion
                ? "py-1 px-2 m-1 border md:py-2 md:px-4 md:m-2 md:border-2"
                : "py-2 px-4 m-2 border-2"
        }
    },
    created() {
        this.icon = this.selectedSorting.icon;
    }
}
</script>

<style>

</style>