<template>
    <div 
        class="flex justify-between w-full md:pb-0 md:w-task-text" 
    >
        <!-- style="width: calc(100% - 360px)" -->
        <span
            class="py-0.5 px-[3px] text-base md:text-[15px] md:truncate md:hover:bg-white border border-transparent md:hover:border-dashed md:hover:border-gray-600"
            :class="{ 
                'text-gray-800 md:text-gray-700': !fields.is_done.model,
                'text-gray-500 line-through': fields.is_done.model
            }"
            @click.prevent="startEdit"
            v-if="!fields.title.editing"
        >
            {{ fields.title.model }}
        </span>
        <div class="flex flex-auto items-center relative" v-else>
            <label class="relative w-full" v-click-outside="stopEditingTitle">
                <input
                    type="text"
                    class="w-full py-0.5 pr-[3px] pl-[3px] md:pl-7 bg-white text-gray-700 border border-dashed border-gray-600 focus:outline-none"
                    v-focus
                    v-model="fields.title.model"
                    @keydown.enter.prevent="() => fields.title.save({ enter_key: true })"
                    @keydown.escape.prevent="() => fields.title.cancel()"
                />
                <div
                    class="hidden md:block absolute z-10 top-1/2 transform -translate-y-1/2 ml-1 flex items-center cursor-pointer justify-center h-5 w-5 rounded-full"
                    :style="{backgroundColor: color.value}"
                    @click.prevent.stop="openColorPicker"
                >
                    <div 
                        class="absolute z-10 left-0 top-full max-w-[300px] overflow-x-visible mt-2 bg-white shadow rounded" 
                        v-if="fields.color_id.editing"
                        v-click-outside="closeColorPicker"
                    >
                        <color-picker :colors="colors" v-model="pickerActiveColors" />
                    </div>
                </div>
            </label>
        </div>
        <button class="hidden px-2 opacity-100 focus:outline-none" @click="$emit('delete')"><i class="far fa-trash-alt text-gray-600"></i></button>
    </div>
</template>

<script>
import ColorPicker from "~base/components/_Partials/ColorPicker.vue";
import models from "~base/models";
import directives from "~base/directives";
import { getterTypes } from '~base/store/getters';

export default {
    props: {
        fields: {
            required: true, 
            type: Object
        },
        color: {
            required: true,
            type: Object
        },
        stored: {
            required: true,
            type: Boolean
        }
    },
    components: {
        ColorPicker
    },
    directives: {
        focus: directives.focus
    },
    data() {
        return {
            colors: models.taskColor.all,
        };
    },
    methods: {
        stopEditingTitle() {
            const { color_id, title } = this.fields;
            // if not sorted yet, persist color and let title go through 
            if (!this.stored && color_id.editing) color_id.persisted = color_id.model;
            if (color_id.editing) color_id.save();
            title.save();
        },
        closeColorPicker()
        {
            if (this.stored) this.fields.color_id.save();
        },
        openColorPicker() {
            const { color_id, title } = this.fields;
            if (!color_id.editing) {
                // if title is dirty when opening => save title
                if (title.dirty && this.stored) {
                    this.$emit("update", { field: "title" });
                    title.persisted = title.model
                };
                color_id.start();
            }
        },
        startEdit() {
            console.log(this.stored, this.screenSmallerThanMd)
            if (this.stored && this.screenSmallerThanMd) return;

            this.fields.title.start();
        }
    },
    computed: {
        screenSmallerThanMd() {
            return this.$store.getters[getterTypes.screens.IS_SMALLER_THAN_MD];
        },
        pickerActiveColors: {
            // formatting for selector
            get() {
                return [{ id: this.fields.color_id.model }]
            },
            // Making sure default color is selected if user unchecks all
            set(colors) {
                this.fields.color_id.model = (colors.length ? colors[0].id : models.taskColor.default.id);
            }
        },
    }
}
</script>

<style>

</style>