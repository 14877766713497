<template>
    <div>
        <div class="flex flex-wrap justify-center">
            <v-spa-lottie-empty></v-spa-lottie-empty>
        </div>
        <div>
            <span class="block py-2 text-2xl font-medium text-center">{{ $t('pages.late.all_done.text') }}</span>
            <span class="block py-2 text-sm font-light text-center text-gray-400">{{ $t('pages.late.all_done.last_words') }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>
