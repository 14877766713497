import taskList from "./taskList";
import task from "./task";
import taskColor from "./taskColor";
import calendarTask from "./calendarTask";
import taskGroup from "./taskGroup";
import comment from "./comment";
import user from "./user";
import taskListUserFilter from "./TaskListUserFilter";
import userSetting from "./userSetting";
import activityFeed from "./activityFeed";

export {
    taskList,
    task,
    taskColor,
    calendarTask,
    taskGroup,
    comment,
    user,
    taskListUserFilter,
    userSetting,
    activityFeed
};
export default {
    taskList,
    task,
    taskColor,
    calendarTask,
    taskGroup,
    comment,
    user,
    taskListUserFilter,
    userSetting,
    activityFeed
};