import { api, route } from "~base/helpers";

export default {
    // async update({ comment, task })
    // {
    //     const { data } = await api.put({
    //         url: route('api.tasks.comments.update', { task: task.uuid, comment: comment.uuid }),
    //         data: comment
    //     });

    //     return data;
    // },

    async store({ comment, task })
    {
        const { data } = await api.post({
            url: route('api.tasks.comments.store', { task: task.uuid }),
            data: comment,
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        });

        return data;
    },

    async update({ comment: { formData, uuid }, task })
    {
        formData.append("_method", "PUT");
        const { data } = await api.post({
            url: route('api.tasks.comments.update', { task: task.uuid, comment: uuid }),
            data: formData,
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        });

        return data;
    },

    async delete({ comment, task })
    {
        const { data } = await api.delete({
            url: route('api.tasks.comments.destroy', { task: task.uuid, comment: comment.uuid }),
        });

        return data;
    },

    async index(task)
    {
        const { data } = await api.get({
            url: route('api.tasks.comments.index', { task: task.uuid })
        });

        return data;
    }
}