import { usersMutationTypes } from "../mutations/users";

const types = {
    "SET_USERS": "user_index",
    "UPDATE_USER": "USERS_USER_UPDATE",
    "FIND_USER": "user_find"
}

export default {
    
    [types.SET_USERS]({ commit }, users)
    {
        commit(usersMutationTypes.SET_USERS, users);
    },
    [types.UPDATE_USER]({ commit }, user)
    {
        commit(usersMutationTypes.UPDATE_USER, user);
    },
    [types.FIND_USER]: ({ state }, { id }) => state.users.find(user => user.id === id) ?? {}
}

export { types as usersActionTypes };