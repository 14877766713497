import models from "~base/models";
import { getterTypes } from ".";

const types = {
    sorted: "LISTS_SORTED",
    active: "LISTS_ACTIVE_LIST",
    IS_ACTIVE_LIST_HAVING_DEFAULT_FILTERS: "LISTS/IS_ACTIVE_LIST_HAVING_DEFAULT_FILTERS",
    ARCHIVES_COUNT: "LISTS/ARCHIVES_COUNT"
};

export { types as ListGetterTypes };
export default {
    [types.sorted]: ({ lists }) => lists.reduce((sorted, list) => {
        const { current_user_filters, archived_at } = list;
        if (archived_at) {
            sorted.archives.push(list)
            return sorted;
        }

        if (current_user_filters?.favorite_at) sorted.favorite.push(list);
        
        sorted[models.taskList.private(list) ? "private" : "sharedAndPublic"].push(list);
        
        return sorted;
    }, {
        private: [],
        sharedAndPublic: [],
        favorite: [],
        archives: []
    }),
    [types.active]: ({ lists, active_list }) => lists.find(l => l.uuid === active_list),
    [types.IS_ACTIVE_LIST_HAVING_DEFAULT_FILTERS](state, getters) {
        return getters[getterTypes.filters.ARE_DEFAULT_FILTERS]
        && getters[getterTypes.orders.IS_DEFAULT_ORDER]
    }
}