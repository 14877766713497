<template>
    <label class="flex cursor-pointer group">
        <input type="checkbox" class="hidden" @input="change" :value="value">
        <div
            class="flex relative transition rounded-full w-8 h-5"
            :class="backgroundClasses"
        >
            <div 
                class="absolute top-1/2 transform -translate-y-1/2 rounded-full h-4 w-4 bg-white"
                :class="checkboxClasses"
            ></div>
        </div>
        <span class="text-gray-600 text-sm ml-2">{{ label }}</span>
    </label>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        label: {
            required: true,
            type: String
        }
    },
    methods: {
        change() {
            this.$emit('input', !this.value)
        }
    },
    computed: {
        checkboxClasses() {
            return {
                "left-0.5": !this.value,
                "right-0.5": this.value
            }
        },
        backgroundClasses() {
            return {
                "bg-gray-300": !this.value,
                "bg-primary": this.value
            }
        }
    }
}
</script>

<style>

</style>