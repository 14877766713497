import helpers, { api, route } from "~base/helpers";

const model = {
    async index()
    {
        const { data } = await api.get({
            url: route('api.lists.index')
        });

        return data;
    },

    async today()
    {
        const { data } = await api.get({
            url: route('api.lists.today')
        });

        return data;
    },

    async archives()
    {
        const [resp, err] = await helpers.try(api.get({
            url: route('api.lists.archives')
        }));

        if(err) return [resp, err];
        return [resp.data, err];
    },

    async archivesCount()
    {
        const [resp, err] = await helpers.try(api.get({
            url: route('api.lists.archives-count')
        }));
        
        if(err) return [resp, err];
        return [resp.data, err];
    },

    async late()
    {
        const { data } = await api.get({
            url: route('api.lists.late')
        });

        return data;
    },

    async show(uuid)
    {
        const [response, error] = await helpers.try(api.get({
            url: route('api.lists.show', { list: uuid }),
        }));

        return error ? [null, error] : [response.data, null];
    },

    async store(list, { user })
    {
        const { ordered_groupless_parent_tasks, current_user_filters, not_done_tasks_count, ...listData } = list;
        const { data } = await api.post({
            url: route('api.lists.store'),
            data: {
                list: listData,
                user
            }
        });

        return data;
    },

    async update(list, { field, user })
    {
        const { ordered_groupless_parent_tasks, ordered_groups, current_user_filters, not_done_tasks_count, ...listData } = list;
        const { data } = await api.put({
            url: route('api.lists.update', { list: list.uuid }),
            data: {
                list: listData,
                user
            },
            params: { field } 
        });

        return data;
    },

    async delete(list, { options = {}, user })
    {
        const { relations = false } = options;
        const { data } = await api.delete({
            url: route('api.lists.destroy', { list: list.uuid }),
            data: { user },
            params: { relations }
        });

        return data;
    },

    new({ user }, overload = {})
    {
        return {
            title: 'example list',
            user_ids: [],
            locked_at: null,
            created_by: user.id,
            ...overload
        };
    },

    // Sorting list alphabetically
    sortAlphabetical(a, b) {
        if (a.title > b.title) return 1;
        if (a.title < b.title) return -1;
        return 0;
    },

    private(list) {
        const { user_ids } = list;
        return user_ids.length === 1
    },

    shared(list) {
        const { user_ids } = list;
        return user_ids.length > 1
    },

    public(list) {
        const { user_ids } = list;
        return user_ids.length === 0;
    }
}

model.sortByOrder = (a, b) => {
    const a_order = a?.current_user_filters?.order;
    const b_order = b?.current_user_filters?.order;
    if (a_order > b_order) return 1
    if (a_order < b_order) return -1
    return model.sortAlphabetical(a, b);
}

model.sortByFavoriteOrder = (a, b) => {
    const a_order = a?.current_user_filters?.favorite_order;
    const b_order = b?.current_user_filters?.favorite_order;
    if (a_order > b_order) return 1
    if (a_order < b_order) return -1
    return model.sortAlphabetical(a, b);
}

export default model;
