<template>
    <li>
        <comment-form 
            v-if="display.form"
            :comment="comment"
            :callback="this.updateComment"
            :submitText="$t('modals.comments.form.submit.update')"
            @saved="hideForm"
            @cancelled="hideForm"
        />
        <div class="flex -mx-2 py-4" v-else>
            <div class="flex px-2">
                <div class="h-5 w-5">
                    <user-avatar :user="user" />
                </div>
            </div>
            <div class="flex flex-col flex-auto px-2">
                <div class="flex items-center">
                    <span class="text-[14px] font-medium text-black">{{ fullName }}</span>
                    <comment-menu
                        v-if="wasCreatedByActiveUser"
                        :comment="comment" 
                        :is-open="isOpen" 
                        @closeMenu="closeMenu" 
                        @openMenu="openMenu"
                        @updateComment="showForm"
                        @deleteComment="deleteComment"
                    />
                </div>
                <div class="text-[14px] text-gray-800 font-light comment-styling" v-html="commentText"></div>
                <div class="flex -mx-2 pb-1" v-if="comment.images.length">
                    <comment-image
                        v-for="image in comment.images"
                        :key="image.uuid"
                        :image="image"
                        :use-icon="false"
                        @clicked="openImage(image)"
                    />
                </div>
                <div class="flex flex-col" v-if="comment.files.length">
                    <comment-file
                        v-for="file in comment.files"
                        :file="file"
                        :key="file.uuid"
                        :use-icon="false"
                        @clicked="openFile(file)"
                    />
                </div>
                <span class="text-xs text-gray-500 font-light">{{ formatedCreatedAt }}</span>
            </div>
        </div>
    </li>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modals from "~base/components/Modals/Config";
import UserAvatar from "~base/components/_Partials/Avatar";
import CommentFile from "~base/components/Modals/Comments/File.vue";
import CommentImage from "~base/components/Modals/Comments/Image.vue";
import CommentMenu from "~base/components/Modals/Comments/Comment/Menu.vue";
import CommentForm from "~base/components/Modals/Comments/Form.vue";
import helpers from '~base/helpers';
import models from '~base/models';

export default {
    props: {
        comment: {
            type: Object,
            required: true
        },
        activeCommentMenu: {
            required: true
        },
        updateComment: {
            type: Function,
            required: true
        }
    },
    components: {
        UserAvatar,
        CommentFile,
        CommentImage,
        CommentMenu,
        CommentForm
    },
    data() {
        return {
            user: {},
            modals: modals.apply(this),
            display: {
                form: false
            }
        }
    },
    methods: {
        ...mapActions(['user_find']),
        linkify: helpers.string.linkify,
        async setUser()
        {
            this.user = await this.user_find({ id: this.comment.user_id });
        },
        openFile(element)
        {
            window.open(element.source, '_blank');
        },
        openImage(image)
        {
            this.modals.commentImage.show({ image })
        },
        closeMenu() {
            this.$emit("closeMenu");
        },
        openMenu() {
            this.$emit("openMenu");
        },
        deleteComment() {
            this.closeMenu();
            this.$emit("deleteComment");
        },
        showForm() {
            this.closeMenu();
            this.display.form = true;
        },
        hideForm() {
            this.display.form = false;
        }
    },
    computed: {
        ...mapGetters(['user_has_users']),
        fullName()
        {
            const { first_name = 'User', last_name = 'deleted' } = this.user;
            return `${first_name} ${last_name}`
        },
        isOpen() {
            return this.activeCommentMenu === this.comment.uuid;
        },
        wasCreatedByActiveUser() {
            return this.$store.state.user.id === this.comment.user_id
        },
        formatedCreatedAt() {
            return moment(this.comment.created_at).format('DD MMMM YYYY HH:MM');
        },
        commentText() {
            return helpers.string.linkify(
                this.comment.text_format, 
                { class: "text-primary hover:text-primary-dark transition" }
            );
        }
    },
    watch: {
        user_has_users()
        { 
            this.setUser();
        }
    },
    created()
    {
        this.setUser();
    }
}
</script>

<style lang="scss">
    .comment-styling {
        ul {
            @apply ml-4;
            li {
                p {
                    @apply flex items-center;
                    &::before {
                        content: "";
                        @apply inline-block rounded-full h-1 w-1 mr-1 bg-primary;
                    }
                }
            }
        }
    }
</style>