import { api, route } from "~base/helpers";

export default {
    async store(group, { user })
    {
        const { ordered_parent_tasks, ...groupData } = group;
        const { data } = await api.post({
            url: route('api.groups.store'),
            data: {
                group: groupData,
                user
            }
        });

        return data;
    },

    async update(group, { field, user })
    {
        const { ordered_parent_tasks, ...groupData } = group;
        const { data } = await api.put({
            url: route('api.groups.update', { group: group.uuid }),
            data: {
                group: groupData,
                user
            },
            params: { field }
        });

        return data;
    },

    async bulkUpdate(bulk)
    {
        const bulkData = bulk.map(({ ordered_parent_tasks, ...groupData }) => groupData)
        const { data } = await api.put({
            url: route('api.groups.update.bulk'),
            data: { bulk: bulkData }
        });

        return data;
    },

    async delete(group, { options, user })
    {
        const { tasks = false } = options;
        const { data } = await api.delete({
            url: route('api.groups.destroy', { group: group.uuid }),
            data: { user },
            params: { tasks }
        });

        return data;
    },

    new({ user }, overload = {})
    {
        return {
            title: 'example group',
            ordered_parent_tasks: [],
            show: true,
            created_by: user.id,
            ...overload
        };
    }
}