<template>
    <div
        class="fixed flex z-[90] bottom-4 right-4"
        v-if="$history.processing"
    >
        <div 
            class="flex flex-col">
            <div
                class="flex flex-auto rounded-lg items-center pl-5 pr-1 py-1.5 mt-2 bg-white shadow"
                v-for="todo in $history.todos"
                :key="todo.uuid"
            >
                <div class="flex">
                    <button class="text-gray-600 hover:text-gray-800 mr-2">{{ todo.data.message }}</button>
                    <button class="text-primary hover:text-primary-dark" @click="cancel(todo.uuid)">{{ $t("pages.list.toasts.buttons.cancel") }}</button>
                </div>
                <button class="p-4" @click.stop="close">
                    <img src="/spa/close.svg" class="h-3 w-3 opacity-25" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            display: true
        };
    },
    methods: {
        close() {
            this.display = false;
        },
        cancel(id) {
            this.$history.cancel(id)
        }
    }
}
</script>

<style>

</style>