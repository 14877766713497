<template>
    <label class="flex items-center justify-center h-8 w-8 group rounded-full bg-transparent hover:bg-gray-200 transition cursor-pointer">
        <i class="text-gray-400 group-hover:text-gray-800 transition" :class="[icon]"></i>
        <slot />
    </label>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        }
    }
}
</script>

<style>

</style>