const types = {
    'SET_TASK_EDITING': 'DOING_SET_TASK_EDITING',
    'SET_GROUP_EDITING': 'DOING_SET_GROUP_EDITING',
    'SET_LIST_EDITING': 'DOING_SET_LIST_EDITING',
}

const mutations = {
    [types.SET_TASK_EDITING]({ doing }, task)
    {
        doing.task = task;
    },
    [types.SET_GROUP_EDITING]({ doing }, group)
    {
        doing.group = group;
    },
    [types.SET_LIST_EDITING]({ doing }, list)
    {
        doing.list = list;
    },
}

export { types as doingMutationTypes };
export default mutations;

