<template>
    <v-spa-layout-page :loading="loading.lists" :container-class-name="hasLists ? 'max-w-[900px] mx-auto' : ''">
        <template slot="title">{{ $t("pages.today.title") }}</template>
        <template slot="content">
            <all-done-message v-if="! hasLists"></all-done-message>
            <div class="flex flex-col" v-else>
                <task-list
                    v-for="list in lists"
                    :key="list.uuid"
                    :list="list"
                    :use-groups="false"
                    :use-favorite="false"
                    :use-lock="false"
                    :use-archive="false"
                    :use-delete="false"
                    :use-add-task="false"
                    :use-share="false"
                    :disable-drag="true"
                    @updateTask="updateTaskInList({ ...$event, list })"
                    :task-key="tasks.key"
                    class="pb-6 last:pb-0"
                />
            </div>
        </template>
    </v-spa-layout-page>
</template>

<script>
import { mapActions } from "vuex";
import models from "~base/models";
import TaskList from "~base/components/TaskList/Show/Partials/List.vue";
import AllDoneMessage from "~base/pages/_includes/AllDoneMessage.vue";

export default {
  components: {
    TaskList,
    AllDoneMessage,
  },
  data() {
    return {
      loading: {
        lists: true,
      },
      tasks: { key: "today_tasks" },
      loadedLists: [],
    };
  },
  methods: {
    async setLists() {
      this.loading.lists = true;
      this.loadedLists = await models.taskList.today();
      // await Promise.all(this.loadedLists.map(l =>this.list_update(l)));
      this.loading.lists = false;
    },
    updateTaskInList({ list, task }) {
      console.log({ list, task });
      if (this.taskShouldStay(task)) return;
      const index = list[this.tasks.key].find((t) => t.uuid === task.uuid);
      if (index === -1) return;
      list[this.tasks.key].splice(index, 1);
    },
    taskShouldStay(task) {
      return models.task.isToday(task) && !task.is_done;
    },
  },
  computed: {
    lists() {
      return this.loadedLists.filter((l) => l[this.tasks.key].length);
    },
    hasLists() {
      return this.lists.length;
    },
  },
  created() {
    this.setLists();
  },
};
</script>