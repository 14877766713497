<template>
    <div class="flex items-center justify-between mt-6">
        <label v-if="title" class="text-gray-500 mr-4">{{ title }}</label>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: false,
            type: String
        }
    }
}
</script>
