import state from './state';
import getters, { getterTypes } from './getters';
import mutations, { mutationTypes } from './mutations';
import actions, { actionTypes } from './actions';

export const types = {
    actions: actionTypes,
    mutations: mutationTypes,
    getters: getterTypes
};

import StoreCreator from '@deegital/trustup-spa-js-assets/store';

const store = new StoreCreator({
    state,
    mutations,
    getters,
    actions
}).merge();

export default store;