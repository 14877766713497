import helpers from "~base/helpers"

const { screens } = helpers.tailwind.theme;

const state = {
    all: [
        { id: '', value: 0 },
        ...Object.keys(screens).map(
            s => ({ id: s, value: parseInt(screens[s].slice(0, -2))})
        )
    ],
    actual: ''
}

export default state;