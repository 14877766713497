import { mutationTypes } from "../mutations";

const types = {
    SET: "task_counters_set",
    TASK_UPDATED: "task_counters_update_task_updated",
    TASK_DELETED: "task_counters_update_task_deleted",
    TASK_STORED: "task_counters_update_task_stored",
    HANDLE_COUNTER_UPDATE: "task_counters_update",
    SET_ACTIVE_TASK: "TASKS/SET_ACTIVE"
};

export default {
    [types.SET]({ commit }, counters) 
    {
        commit(mutationTypes.tasks.SET_COUNTERS, counters);
    },

    [types.TASK_UPDATED]({ dispatch }, { model, persisted, wasDone, isDone }) 
    {
        dispatch(types.HANDLE_COUNTER_UPDATE, { oldValue: persisted, newValue: model, wasDone, isDone });
    },

    [types.TASK_DELETED]({ dispatch }, { model, wasDone }) 
    {
        dispatch(types.HANDLE_COUNTER_UPDATE, { oldValue: model, wasDone });
    },

    [types.TASK_STORED]({ dispatch }, { model, isDone }) 
    {
        dispatch(types.HANDLE_COUNTER_UPDATE, { newValue: model, isDone });
    },

    [types.HANDLE_COUNTER_UPDATE]({ commit, state }, { oldValue, newValue, wasDone, isDone })
    {
        const counters = {
            late: state.counters.late,
            today: state.counters.today
        };
        const now = moment();
        
        if (oldValue && !wasDone) {
            const wasMoment = moment(oldValue);
            const wasLate = wasMoment.isBefore(now, 'day');
            const wasToday = wasMoment.isSame(now, 'day');
            if (wasLate) counters.late --
            if (wasToday) counters.today --
        }

        if (newValue && !isDone) {
            const newMoment = moment(newValue);
            const newToday = newMoment.isSame(now, 'day');
            const newLate = newMoment.isBefore(now, 'day');
            if (newLate) counters.late ++
            if (newToday) counters.today ++
        }
        
        commit(mutationTypes.tasks.SET_COUNTERS, counters);
    },

    [types.SET_ACTIVE_TASK]({ commit }, { task })
    {
        commit(mutationTypes.tasks.SET_ACTIVE_TASK, task);
    },
}

export { types as TasksActionTypes };