export default {
    events: [],
    // categories: [],
    // collaborators: [],
    // filters: {
    //     categories: [],
    //     collaborators: [],
    // },
    view: null,
    isToday: false,
    title: null,
    // used to limit events request
    limits: {
        start: null,
        end: null,
    },
    // used to define calendar limit dates
    interval: {
        type: 'months',
        value: 3,
    },
}