import listMutations, { listsMutationTypes } from './lists';
import taskMutations, { tasksMutationTypes } from './tasks';
import userMutations, { usersMutationTypes } from './users';
import orderMutations, { ordersMutationTypes } from './orders';
import filterMutations, { filterMutationTypes } from './filters';
import calendar from './calendar';
import events from './events';
import modal from './modal';
import doing, { doingMutationTypes } from "./doing";
import screens, { screenMutationTypes } from "./screens";
import { api } from "~base/helpers"

export const mutationTypes = {
    doing: doingMutationTypes,
    screens: screenMutationTypes,
    lists: listsMutationTypes,
    orders: ordersMutationTypes,
    tasks: tasksMutationTypes,
    users: usersMutationTypes,
    filters: filterMutationTypes
}

export default {
    ...listMutations,
    ...taskMutations,
    ...userMutations,
    ...orderMutations,
    ...filterMutations,
    ...calendar,
    ...events,
    ...modal,
    ...doing,
    ...screens,
    /**
     * Set the current user.
     *
     * @param {Object} state Vuex's state
     * @param {Object} user
     */
    setUser(state, user) {
        state.user = user;
        
        if ( user && window.Intercom ) {
            window.intercomSettings.user_id = user.id;
            window.intercomSettings.user_hash = user.intercom_hash;
            window.intercomSettings.name = user.first_name + ' ' + user.last_name;
            window.intercomSettings.email = user.email;
            window.intercomSettings.created_at = user.created_at;
            window.Intercom('update', window.intercomSettings); 
        }
    },

    /**
     * Set the current token.
     *
     * @param {Object} state Vuex's state
     * @param {Object} token
     */
    setToken(state, token) {
        state.token = token;
        window.axios.defaults.headers.common['Authorization'] = state.token.token_type + ' ' + state.token.access_token;
    },

    'USER/SET'(state, user) {
        state.user = user;

        if ( state.user && state.user.jwt ) {
            window.axios.defaults.headers.common['X-TrustUp-JWT'] = state.user.jwt;
        }
    },

    'ACCOUNT/SET'(state, account) {
        state.account = account;

        api.setConfig({
            headers: {
                'X-Account-UUID': account.uuid,
                'X-TrustUp-Authorization': state.token.token_type + ' ' + state.token.access_token
            }
        });
    },
    
    'TOKEN/SET'(state, token) {
        state.token = token;

        if ( token ) {
            window.axios.defaults.headers.common['Authorization'] = state.token.token_type + ' ' + state.token.access_token;
            window.axios.defaults.headers.common['X-TrustUp-Authorization'] = state.token.token_type + ' ' + state.token.access_token;
        } else {
            window.axios.defaults.headers.common['Authorization'] = null;
            window.axios.defaults.headers.common['X-TrustUp-Authorization'] = null;
        }
    },

    /**
     * Remove the token from the localstorage.
     *
     * @param {Object} state Vuex's state
     */
    removeToken(state)
    {
        state.token = null;
        window.axios.defaults.headers.common['Authorization'] = null;
    },

    /**
     * Set the account.
     *
     * @param {Object} state Vuex's state
     * @param {Object} account
     */
     setAccount(state, account) {
        state.account = account;

        if ( account && window.Intercom ) {
            window.intercomSettings.tasks_account = account.name;
            window.intercomSettings.tasks_account_uuid = account.uuid;
            window.intercomSettings.tasks_account_created_at = account.created_at;
            window.Intercom('update', window.intercomSettings);
        }
    },

    /**
     * Set the sidebar section of the app.
     * 
     * @param {Object} state Vuex's state
     * @param {String} section
     */
    setSidebarSection(state, section) {
        state.sidebarSection = section;
    },

    /**
     * Set the status of the application loading.
     * 
     * @param {Object}  state Vuex's state
     * @param {Boolean} status
     */
    setApplicationLoadingStatus(state, status) {
        state.loadingApplication = status;
    }
};