<template>
    <li 
        class="py-2 px-4 text-gray-600 hover:text-primary hover:bg-gray-100 cursor-pointer flex items-center"
        @click="handleClick"
    >
        <div class="h-4 w-4 flex items-center justify-center">
            <i :class="action.icon" class="text-gray-600 text-sm"></i>
        </div>
        <span class="ml-2 text-gray-600 text-[14px]">{{ action.title }}</span>
    </li>
</template>

<script>
export default {
    props: { 
        action: {
            required: true,
            type: Object
        }
    },
    methods: {
        handleClick() {
            this.action.callback();
            this.$emit("closeMenu");
        }
    }
}
</script>