import axios from 'axios';

export default {

    getUser(context, headers = null)
    {
        return new Promise(function(resolve, reject) {
            axios.get(`${window.url.auth}/api/user?app=tasks`, {withCredentials: true})
                .then((response) => {
                    resolve({apps: response.data.apps, user: response.data.user, token: response.data.token});
                })
                .catch((error) => {
                    reject(error.response.data);
                });
        });
    },

    getApps(context)
    {
        return new Promise(function(resolve, reject) {
            axios.get(window.url.auth + "/api/apps", {withCredentials: true})
                .then((response) => {
                    // console.log(response);
                    // console.log(response.data.apps);
                    window.App.$spa.apps = response.data.apps;
                })
                .catch((error) => {
                    console.error(error);
                    reject(error.response.data);
                });
        });
    },

    logout(context)
    {
        return new Promise(function(resolve, reject) {
            window.location.href = window.url.auth+'/logout';
            resolve();
        });
    }
}