<template>
    <div v-click-outside="handleOutside" class="relative">
        <div class="pr-7 pl-2 py-1.5 text-gray-700 rounded border border-gray-200 relative" @click="toggle">
            <span>{{ value }}</span>
            <div 
                class="absolute top-0 bottom-0 right-2 w-5 flex items-center justify-center cursor-pointer"
                @click.stop="$emit('input', null)"
            >
                <i class="fa fa-times absolute right-0 text-sm text-gray-400"></i>
            </div>
        </div>
        <element-picker
            v-if="display.picker"
            v-model="pickerValue"
            track-by="value"
            :elements="options"
            :multiple="false"
            v-slot="{ 
                toggleElement,
                elements,
                selected,
                highlightPreviousElement,
                highlightNextElement,
                highlight,
                hasHighlight,
                isHighlighted
            }"
        >
            <time-picker-list
                :elements="elements.all"
                :select="toggleElement"
                :selected="selected"
                :highlightPrevious="highlightPreviousElement"
                :highlightNext="highlightNextElement"
                :highlighted="highlight"
                :hasHighlight="hasHighlight"
                :isHighlighted="isHighlighted"
            />
        </element-picker>
    </div>
</template>

<script>
import ElementPicker from "~base/components/_Partials/ElementPicker.vue"
import TimePickerList from "~base/components/_Partials/TimePickerList.vue"
export default {
    props: {
        value: {
            required: true,
            type: String
        },
        options: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            display: {
                picker: false
            }
        };
    },
    components: {
        ElementPicker,
        TimePickerList
    },
    methods: {
        show() {
            this.display.picker = true
        },
        hide() {
            this.display.picker = false
        },
        toggle() {
            this.display.picker ? this.hide() : this.show();
        },
        handleOutside() {
            if (this.display.picker) this.hide();
        }
    },
    computed: {
        pickerValue: {
            get() {
                return this.options.filter(o => o.value === this.value);
            },
            set(values) {
                this.$emit("input", values.length ? values[0].value : null);
                this.hide();
            }
        }
    }
}
</script>

<style>

</style>