var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('element-picker',{attrs:{"value":_vm.value,"track-by":"id","search-by":"full_name","elements":_vm.users,"multiple":true},on:{"input":function (users) { return _vm.$emit('input', users); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var deleteElement = ref.deleteElement;
var addElement = ref.addElement;
var toggleElement = ref.toggleElement;
var highlight = ref.highlight;
var hasHighlight = ref.hasHighlight;
var isHighlighted = ref.isHighlighted;
var highlightNextElement = ref.highlightNextElement;
var highlightPreviousElement = ref.highlightPreviousElement;
var input = ref.input;
var elements = ref.elements;
var selected = ref.selected;
return [_c('picker-user-list',{attrs:{"deleteElement":deleteElement,"addElement":addElement,"toggleElement":toggleElement,"isHighlighted":isHighlighted,"highlight":highlight,"hasHighlight":hasHighlight,"highlightNextElement":highlightNextElement,"highlightPreviousElement":highlightPreviousElement,"input":input,"elements":elements,"selected":selected}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }