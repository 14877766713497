import { api, route } from "~base/helpers";
import taskColor from "./taskColor";

const methods = {
    taskData(task) {
        // removing unused properties from task
        const { ordered_children, comments_count, prevent_filters, ...taskData } = task;
        return taskData;
    }
}

export default {
    ...methods,
    async update(task, { field, user })
    {
        const { data } = await api.put({
            url: route('api.tasks.update', { task: task.uuid }),
            data: {
                user,
                task: methods.taskData(task)
            },
            params: { field }
        });

        return data;
    },

    async bulkUpdate(bulk)
    {
        const bulkData = bulk.map(methods.taskData)
        const { data } = await api.put({
            url: route('api.tasks.update.bulk'),
            data: { bulk: bulkData }
        });

        return data;
    },

    async store(task, { user })
    {
        const { data } = await api.post({
            url: route('api.tasks.store'),
            data: {
                task: methods.taskData(task),
                user
            }
        });

        return data;
    },

    async delete(task, { user, options = {} })
    {
        const { nested_tasks = false } = options;
        const { data } = await api.delete({
            url: route('api.tasks.destroy', { task: task.uuid }),
            params: { nested_tasks },
            data: { user }
        });

        return data;
    },

    async counters()
    {
        const { today, late } = await api.get({
            url: route('api.tasks.counters'),
        });

        return { today, late };
    },

    new({ user }, overload)
    {
        return {
            title: 'example task',
            color_id: taskColor.default.id,
            is_done: false,
            due_date: null,
            due_date_has_time: false,
            task_group_id: null,
            user_ids: [],
            created_by: user.id,
            ...overload
        };
    },

    toCalendarTask(task)
    {
        const { id, uuid, title, is_done, due_date, comments_count, user_ids, task_list_id } = task;
        
        return {
            id,
            uuid,
            title,
            is_done,
            start: due_date,
            comments_count,
            user_ids: [...user_ids],
            task_list_id,
        };
    },

    isToday(task) {
        return moment().isSame(task.due_date, 'day');
    },

    isLate(task) {
        return moment().isAfter(task.due_date, 'day') && !task.is_done;
    },
}