<template>
    <ul>
        <span class="block mb-2 font-medium text-gray-800 md:hidden">Tri</span>
        <list-order v-for="order in orders" :key="order.id" :order="order" />
    </ul>
</template>

<script>
import ListOrder from "~base/components/TaskList/Show/Partials/List/Orders/Order.vue";
export default {
    components: {
        ListOrder
    },
    computed: {
        orders()
        {
            return this.$store.state.orders.all.filter(o => o?.group_default);
        }
    }
}
</script>

<style>

</style>