<template>
    <v-spa-modal
        :name="modals.delete.name"
        height="auto"
        width="100%"
        :scrollable="true"
        :shift-x="0.5"
        :shift-y="0.5"
        :show-close-icon="true"
        @before-open="setItem"
        classes="delete-modal"
    >
        <div class="flex flex-col items-center justify-center w-full h-full">
            <i class="mt-8 mb-4 text-6xl fad fa-trash text-primary"></i>
            <span class="my-4 text-2xl font-medium text-gray-800">{{ $t("modals.delete.title") }}</span>
            <span class="px-4 my-4 text-center text-gray-600" 
                v-html="$t('modals.delete.text', { 
                    text: `<span class='font-medium text-gray-800'>${item.title}</span>` 
                })"
            ></span>
            <div class="flex flex-col">
                <button class="relative px-4 py-3 my-8 -mx-4 text-sm text-base tracking-tight text-white uppercase transition-all duration-300 bg-red-500 rounded-lg outline-none hover:bg-red-600" @click="confirm">
                    {{ $t("modals.delete.buttons.confirm") }}
                </button>
                <button class="mb-4 transition duration-300 text-primary hover:text-primary-dark" @click="modals.delete.hide">{{ $t("modals.delete.buttons.cancel") }}</button>
            </div>
        </div>
    </v-spa-modal>
</template>

<script>
import modals from "~base/components/Modals/Config";

export default {
    data() {
        return {
            modals: modals.apply(this),
            item: {},
            callback: () => {}
        }
    },
    methods: {
        setItem({ params: { item, callback } }) {
            this.item = item;
            this.callback = callback;
        },
        confirm() {
            this.callback();
            this.modals.delete.hide();
        }
    }
}
</script>

<style> 
    @screen sm {
        .delete-modal {
            width: 500px !important;
            left: calc(50% - 250px) !important;
         }
    }
</style>