<template>
    <form class="flex flex-col p-2 border border-gray-300 rounded" @submit.prevent="handleSubmit">
        <div class="pb-2.5 border-b border-gray-300">
            <v-spa-wysiwyg
                ref="wysiwyg"
                :form="form"
                id="form-text"
                inputName="text"
                :displayLabel="false"
                containerClassName=""
                labelContainerClassName=""
                inputContainerClassName=""
                inputClassName="w-full min-h-32"
                :placeholder="$t('modals.comments.form.text.placeholder')"
            />
        </div>
        <div class="pt-2.5 flex -mx-2" v-if="form.images.length">
            <form-image
                :image="image"
                v-for="(image, i) in form.images"
                :use-icon="true" 
                :key="`form_image_${i}`"
                @clicked="deleteImage.bind(this, i)()"
            />
        </div>
        <div class="pt-2.5 flex flex-col" v-if="form.files.length">
            <form-file
                :file="file"
                v-for="(file, i) in form.files" 
                :key="`form_file_${i}`"
                :use-icon="true"
                @clicked="deleteFile.bind(this, i)()"
            />
        </div>
        <div class="w-full flex justify-between items-center pt-2.5">
            <div class="flex">
                <form-icon 
                    icon="fal fa-paperclip"
                    :data-tooltip="$t('modals.comments.form.file')"
                >
                    <input
                    id="form_comments_files"
                    multiple="multiple"
                    type="file"
                    @change="handleFiles.bind(this, $event, 'files')()"
                    class="hidden"
                />
                </form-icon>
                <form-icon
                    icon="fal fa-images" 
                    :data-tooltip="$t('modals.comments.form.image')"
                >
                    <input 
                        id="form_comments_images" 
                        multiple="multiple" 
                        type="file"
                        @change="handleFiles.bind(this, $event, 'images')()" 
                        class="hidden"
                    />
                </form-icon>
                <!-- <form-icon
                    icon="fal fa-smile-beam"
                    :data-tooltip="$t('modals.comments.form.emoji')"
                >
                </form-icon> -->
            </div>
            <div class="flex">
                <v-spa-loading-button 
                    v-if="comment"
                    font-size="text-sm"
                    color="bg-gray-200 text-gray-800 hover:text-black"
                    :disabled="loader.form.loading"
                    @click.native="cancel"
                    class="mr-2"
                >{{ $t('modals.comments.form.submit.cancel') }}</v-spa-loading-button>
                <v-spa-loading-button font-size="text-sm" :disabled="!valid" :loading="loader.form.loading">{{ submitText }}</v-spa-loading-button>
            </div>
        </div>
    </form>
</template>

<script>
import { mapActions } from "vuex";
import UploadedFile from "~spa/classes/UploadedFile";
import UploadedImage from "~spa/classes/UploadedImage";
import FormIcon from "~base/components/Modals/Comments/Icon.vue";
import FormFile from "~base/components/Modals/Comments/File.vue";
import FormImage from "~base/components/Modals/Comments/Image.vue";
import helpers from '~base/helpers';

export default {
    props: { 
        callback: {
            required: true,
            type: Function
        },
        comment: {
            required: false,
            type: Object
        },
        submitText: {
            required: true,
            type: String
        }
    },
    components: {
        FormIcon,
        FormFile,
        FormImage
    },
    data()
    {
        return {
            task: {},
            loader: {
                form: new helpers.loader(),
                files: new helpers.loader()
            },
            form: {
                text: '',
                images: [],
                files: []
            }
        }
    },
    methods: {
        ...mapActions(['updateEvent']),
        async handleSubmit() {
            const { form } = this.loader;
            if (form.loading) return;
            
            form.loadTill(async () => {
                try {
                    const { files, text, images } = this.form;
                    // transforming fomr to form data
                    const formData = helpers.formData.fromObject({
                        text: text, 
                        user_id: this.user.id,
                        files: this.newFiles.map(f => f.file),
                        kept_files: this.keptFiles.map(f => f.uuid),
                        images: this.newImages.map(i => i.source),
                        kept_images: this.keptImages.map(i => i.uuid),
                    });
                    await this.callback({ formData });
                    this.$emit("saved");
                    this.$refs.wysiwyg.update('');
                    this.form.text = '';
                    this.form.images = [];
                    this.form.files = [];
                } catch (error) {
                    throw error;
                }
            })
        },
        cancel() {
            this.$emit("cancelled");
        },
        async setFiles(files, prop)
        {
            const is_file = prop === 'files';
    
            try {
                for ( let i = 0; i < files.length; i++ ) {
                    const file = files[i];
                    const uploader = is_file ? new UploadedFile() : new UploadedImage();
                    const processed = await this.processFile(uploader, file);
                    this.form[prop].push(processed);
                } 
            } catch(err) {
                window.Toasteo.error('Could not upload');
            } finally {
                this.resetInput(`form_comments_${prop}`);
            }
    },
        async handleFiles({ target: { files } }, prop)
        {
            if (this.loader.files.loading) return
            this.loader.files.loadTill(this.setFiles.bind(this, files, prop));
        },
        async processFile(processor, file)
        {
            await processor.check(file);
            return processor.get(file);
        },
        resetInput(id)
        {
            document.getElementById(id).value = null;
        },
        deleteFile(index)
        {
            this.form.files.splice(index, 1);
        },
        deleteImage(index)
        {
            this.form.images.splice(index, 1);
        }
    },
    computed: {
        user()
        {
            return this.$store.state.user;
        },
        valid() {
            return Object.values(this.form).findIndex(e => e.length) !== -1;
        },
        keptFiles() {
            return this.form.files.filter(f => f.uuid);
        },
        newFiles() {
            return this.form.files.filter(f => !f.uuid);
        },
        keptImages() {
            return this.form.images.filter(i => i.uuid);
        },
        newImages() {
            return this.form.images.filter(i => !i.uuid);
        }
    },
    created() {
        if (this.comment) {
            const { text, files, images } = this.comment;
            this.form = { 
                text,
                files: files.map(f => f), 
                images: images.map(i => i)
            };
        }
    }
}
</script>