<template>
    <transition
        enter-active-class="transition duration-500 transform"
        leave-active-class="transition duration-500 transform"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <li class="flex flex-col md-select" @click="screenSmallerThanMd && modals.taskEdit.show({ task })" :class="{[draggable.handle.task]: screenSmallerThanMd}" v-show="display.task && opened">
            <div class="flex w-full group hover:bg-gray-100 relative">
                <div 
                    class="flex md:task-container w-full py-2.5 items-center border-b border-gray-200"
                >
                    <div class="flex" @click.stop="() => {}">
                        <div class="relative flex w-[60px]">
                            <div 
                                class="w-[20px] flex items-center justify-center" 
                                :class="{
                                    ['opacity-0']: !hasNestedTasks,
                                    ['cursor-pointer']: hasNestedTasks
                                }"
                            >
                                <list-chevron v-model="openNestedTasks" />
                            </div>
                            <label 
                                class="w-6 md:w-5 h-6 md:h-5 mr-2 flex items-center justify-center rounded-full border-2 cursor-pointer"
                                @mouseenter="hover.is_done = true"
                                @mouseleave="hover.is_done = false"
                                :style="isDoneStyle"
                            >

                                <i 
                                    class="fas fa-check text-white text-[11px]"
                                    :class="{
                                        'opacity-0': !updater.fields.is_done.model && !hover.is_done, 
                                        'opacity-100': updater.fields.is_done.model, 
                                    }"
                                ></i>
                                <input
                                    class="hidden" 
                                    type="checkbox" 
                                    v-model="updater.fields.is_done.model" 
                                    @input="() => updater.fields.is_done.start()" 
                                    @change="saveIsDone"
                                >
                            </label>
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row md:items-center" :style="{ width: 'calc(100% - 60px)' }">
                        <task-title
                            :fields="updater.fields"
                            :color="taskColor"
                            :stored="stored"
                            @update="updateTask"
                            @delete="showDeleteModal"
                        />
                        <div class="flex w-full md:w-auto">
                            <div class="hidden md:block px-2 md:w-auto">
                                <button @click="showComments">
                                    <i :class="{ 'text-primary fas fa-comments': task.comments_count, 'text-gray-400 far fa-comment': !task.comments_count }"></i>
                                </button>
                            </div>
                            <div class="hidden md:flex flex-auto md:flex-none md:justify-end md:w-[120px] relative">
                                <user-list-and-picker 
                                    :field="updater.fields.user_ids" 
                                    :tooltip="{ data: $t('pages.list.task.users.add'), location: 'top' }"
                                />
                            </div>
                            <div class="flex md:justify-end md:w-[160px] px-2 relative">
                                <due-date
                                    class="cursor-pointer"
                                    v-if="!updater.fields.due_date.editing"
                                    :field="updater.fields.due_date"
                                    :has-time="calendarHasTime"
                                    :is-done="!!updater.fields.is_done.model"
                                    :format="due_date.format.display"
                                    @start="openDatePicker"
                                />
                                <due-date-picker
                                    :formattedDate="formattedDueDate"
                                    :date="calendarDueDate"
                                    :time="calendarDueTime"
                                    :has-time="calendarHasTime"
                                    @time="calendarDueTime = $event"
                                    @date="calendarDueDate = $event"
                                    @save="saveDatePicker"
                                    @cancel="cancelDatePicker"
                                    v-else
                                    v-click-outside="saveDatePicker"
                                />
                            </div>
                        </div> 
                    </div>
                </div>
                <button class="hidden md:block px-2 opacity-0 group-hover:opacity-100 focus:outline-none" @click="showDeleteModal"><i class="far fa-trash-alt text-gray-600"></i></button>
                <div class="absolute right-full top-1/2 transform -translate-y-1/2 hidden md:block cursor-move p-2 opacity-0 group-hover:opacity-100 focus:outline-none" :class="draggable.handle.task" v-if="!disableDrag">
                    <i class="fas fa-arrows-alt text-gray-600"></i>
                </div>
            </div>
            <ul class="pl-9 flex flex-col" v-if="useNested">
                <draggable-list 
                    class="flex flex-col"
                    group="tasks"
                    v-model="draggableTasks"
                    ghost-class="draggable__moving_element"
                    :animation="300"
                    :handle="`.${draggable.handle.task}`"
                    :disabled="isEditingSomething || disableDrag"
                    :delay="300"
                    :delayOnTouchOnly="true"
                > 
                    <task-item
                        v-for="(task, i) in task.ordered_children"
                        :key="task.uuid"
                        :task="task"
                        :index="i"
                        :use-nested="false"
                        :opened="openNestedTasks"
                        :disable-drag="disableDrag"
                        @deleteTask="deleteChildrenTask"
                        @refreshTask="refreshTask"
                        @debounceFilters="debounceActiveFilters"
                    />
                </draggable-list>
            </ul>
        </li>
    </transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { types } from "~base/store";
import DraggableList from "vuedraggable";
import modals from "~base/components/Modals/Config"
import models from "~base/models";
import helpers from "~base/helpers";
import directives from "~base/directives";
import updater from "~base/classes/Models";
import DueDate from "~base/components/TaskList/Show/Task/DueDate";
import DueDatePicker from "~base/components/TaskList/Show/Task/DueDatePicker.vue";
import UserListAndPicker from "~base/components/TaskList/Show/Partials/UserListAndPicker.vue";
import TaskTitle from "~base/components/TaskList/Show/Task/Title.vue";
import listChevron from "~base/components/TaskList/Show/Partials/chevron.vue";
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';


export default {
    name: 'TaskItem',
    props: {
        task: {
            type: Object,
            required: true
        },
        useNested: {
            type: Boolean,
            required: false,
            default: true
        },
        // parent telling if task is opened
        opened: {
            type: Boolean,
            required: false,
            default: true
        },
        disableDrag: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: false
        },
    },
    components: {
        DueDate,
        DueDatePicker,
        DraggableList,
        UserListAndPicker,
        TaskTitle,
        listChevron
    },
    directives: {
        focus: directives.focus
    },
    data()
    {
        return {
            keypress: null,
            hover: {
                is_done: false
            },
            due_date: {
                format: {
                    display: "DD MMM YYYY",
                    store: "YYYY-MM-DD HH:mm:ss"
                },
            },
            updater: {
                handler: {},
                fields: {
                    title: {},
                    due_date: {},
                    is_done: {},
                    done_by: {},
                    user_ids: {},
                    color_id: {},
                    show: {},
                    due_date_has_time: {}
                }
            },
            modals: modals.bind(this)(),
            display: {
                task: true,
                users: {
                    selection: false
                }
            },
            debounce: {
                filters: null
            },
            // Opening all nested task by default
            // Remove it when storing to database and see openNestedTasks
            // this should be replaced by task property when storing to database
            openNested: true
        }
    },
    methods: {
        ...mapActions(['base_check_force_edit']),
        counterTaskStored(values) {
            this.$store.dispatch(actionTypes.tasks.TASK_STORED, values);
            const { isDone } = values;
            if (!isDone) return this.incrementTaskCounter()
        },
        counterTaskUpdated(values) {
            this.$store.dispatch(actionTypes.tasks.TASK_UPDATED, values);
            const { wasDone, isDone } = values;
            if (isDone && !wasDone) return this.decrementTaskCounter();
            if (!isDone && wasDone) return this.incrementTaskCounter();
        },
        counterTaskDeleted(values) {
            this.$store.dispatch(actionTypes.tasks.TASK_DELETED, values);
            const { wasDone } = values;
            if (!wasDone) this.decrementTaskCounter();
        },
        incrementTaskCounter() {
            this.addToTaskCounter(1);
        },
        decrementTaskCounter() {
            this.addToTaskCounter(-1);
        },
        addToTaskCounter(number) {
            this.updateListCounter(this.list.not_done_tasks_count + number);
        },
        updateListCounter(counter) {
            this.updateList({ 
                ...this.list, 
                not_done_tasks_count: counter
            });
        },
        updateList(list) {
            this.$store.dispatch(actionTypes.lists.update, list)
        },
        refreshTask({ task }) {
            const index = this.task.ordered_children.findIndex(({ uuid }) => uuid === task.uuid);
            // Making sure we're not trying to update a task without uuid yet
            if (index === -1) {
                const notStoredIndex = this.task.ordered_children.findIndex(({ uuid }) => !uuid);
                if (notStoredIndex === -1) return console.log({ uuid: task.uuid }, 'couldn"t find a task to update')
                return this.task.ordered_children.splice(notStoredIndex, 1, task);
            }
            this.task.ordered_children.splice(index, 1, task);
        },
        updateTask({ field, user = this.minimalUser })
        {
            return models.task.update(this.task, { field, user });
        },
        showDeleteModal() {
            this.modals.delete.show({ item: this.task, callback: this.handleDelete })
        },
        deleteChildrenTask(uuid)
        {
            const tasks =  this.task.ordered_children;
            const index = tasks.findIndex(task => task.uuid === uuid);
            if (index >= 0) tasks.splice(index, 1);
        },
        handleCancel()
        {
            if (!this.stored) this.handleDelete();
        },
        async handleUpdate({ field }, task, { enter_key = false } = {})
        {
            if (field === 'title' && this.updater.fields.title.model === '') return this.updater.fields.title.cancel();

            if (!this.stored) {
                // if user used enter to add this task, add automatically another task underneath
                if (enter_key) this.$emit('addTask');
                const { due_date, is_done } = this.task;
                try {
                    const stored = await models.task.store(this.task, { user: this.minimalUser });
                    this.counterTaskStored({ model: due_date, isDone: is_done });
                    this.setActiveTask(stored);
                    return this.$emit("refreshTask", { index: this.index, task: { ...stored, prevent_filters: true } });
                } catch (err) {
                    return console.error({ err });
                }
            }

            this.handleHistory({ field })
        },
        handleHistory({ field }) {
            const { fields } = this.updater
            const { due_date } = fields;
            const { model } = due_date;
            const { persisted = model } = due_date;
            const isDone = fields.is_done.model;
            const wasDone = fields.is_done.persisted ?? isDone;
            const old_value = fields[field].persisted;
            const { prevent_filters = false } = this.task;
            
            this.task.prevent_filters = true;
            this.updateTaskAndCounters({ 
                task: this.task,
                values: { model, persisted, isDone, wasDone }
            });

            this.$history.add({
                callback: () =>  this.updateTask({ field, user: this.minimalUser }),
                reverse: () => {
                    this.task.prevent_filters = prevent_filters;
                    this.task[field] = old_value;
                    this.updateTaskAndCounters({ 
                        task: this.task,
                        values: { model: persisted, persisted: model, isDone: wasDone, wasDone: isDone }
                    });
                },
                delay: 3000,
                data: { message: this.$t("pages.list.task.toasts.update") }
            });
        },
        updateTaskAndCounters({ task, values: { model, persisted, isDone, wasDone } }) {
            this.$emit("refreshTask", { task });
            this.counterTaskUpdated({ model, persisted, isDone, wasDone });
            this.emitUpdate();
        },
        // handlePostSave(field) {
        //     switch (field) {
        //         // case "is_done": this.debounce.filters(this.activeFiltersByGroup);
        //         default: return;
        //     }
        // },
        deleteTask({ user, options })
        {
            return models.task.delete(this.task, { user, options });
        },
        async handleDelete()
        {
            try {
                const { model } = this.updater.fields.due_date;
                this.emitDelete();

                if (this.stored) {
                    this.counterTaskDeleted({ model, wasDone: this.updater.fields.is_done.model });
                    await this.deleteTask({ user: this.minimalUser, options: { nested_tasks: true } });
                    console.log('successfully deleted')
                }
            } catch(err) {
                console.error(err);
                console.error("couldn't delete");
            }
        },
        setTaskEditing(value)
        {
            this.$store.dispatch(types.actions.doing.SET_TASK_EDITING, value);
        },
        showComments()
        {
            this.setActiveTask(this.task);
            this.modals.comments.show({ task: this.task })
            this.$emit('showTaskComments');
        },
        setUpdater() {
            this.updater.handler = new updater(this.task);
            const { handler, fields } = this.updater;
            Object.keys(fields).forEach(key => {
                const field = handler.field({
                    name: key,
                    callbacks: {
                        save: this.handleUpdate.bind(this, { field: key }),
                        start: this.setTaskEditing.bind(this, true),
                        stop: this.setTaskEditing.bind(this, false),
                        cancel: this.handleCancel
                    }
                });
                fields[key] = field; 
            });
        },
        setActiveTask(task) {
            this.$store.dispatch(actionTypes.tasks.SET_ACTIVE_TASK, { task })
        },
        recursiveDisplay(task, groups) {
            // for each group try to find a filter tletting task go through
            const taskFilterByGroup = groups.map(filters => filters.find(f => f.filter(task)) ? true : false);
            // Each group has to pass => making sure results do not include false;
            let display = !taskFilterByGroup.includes(false);
            // If task should not be displayed, do same process for every chidren task.
            if (!display && task.ordered_children && task.ordered_children.length) {
                // break if any child passes groups
                for (let i = 0; i < task.ordered_children.length; i++) {
                    const child = task.ordered_children[i];
                    display = this.recursiveDisplay(child, groups)
                    if (display) break;
                }
            }
            return display;
        },
        // saving due_date calendar if needed
        enterPressed()
        {
            const { due_date } = this.updater.fields;
            if (due_date.editing) due_date.save();
        },
        // closing due_date calendar if needed
        escapePressed()
        {
            const { due_date } = this.updater.fields;
            if (due_date.editing) due_date.cancel();
        },
        getNewCalendarDate(value) {
            const { due_date } = this.updater.fields;
            if (!value) return value;
            // Preventing save when setting initially
            if (moment(this.calendarDueDate).isSame(value)) return false;
            if (!due_date.model) {
                // setting to default time.
                return moment(value)
                    .hour(19)
                    .minute(0)
                    .format(this.due_date.format.store);
            }
            const momentDueDate = moment(due_date.model);
            const newMomentDate = moment(value);
            momentDueDate.year(newMomentDate.get('year'))
                .month(newMomentDate.get('month'))
                .date(newMomentDate.get('date'));
            // setting to default time if no time is given.
            if (!this.calendarHasTime) momentDueDate.hour(19).minute(0);

            return momentDueDate.format(this.due_date.format.store);
        },
        saveDatePicker() {
            const { due_date, due_date_has_time } = this.updater.fields;
            if (due_date_has_time.dirty && !due_date.dirty) due_date_has_time.save();
            else due_date_has_time.stop();
            due_date.save();
        },
        cancelDatePicker() {
            const { due_date, due_date_has_time } = this.updater.fields;
            due_date.cancel();
            due_date_has_time.cancel();
        },
        openDatePicker() {
            if (this.screenSmallerThanMd) return;
            const { due_date, due_date_has_time } = this.updater.fields;
            due_date.start();
            due_date_has_time.start();
        },
        emitDelete() {
            this.$emit('deleteTask', this.task.uuid);
        },
        emitUpdate() {
            this.$emit('updateTask');
        },
        setModalListeners() {
            this.$bus.$on('deleteTask', (uuid) => uuid === this.task.uuid && this.emitDelete())
            this.$bus.$on('updateTask', (uuid) => uuid === this.task.uuid && this.emitUpdate())
        },
        async saveIsDone() {
            const { done_by, is_done } = this.updater.fields;
            done_by.start().model = is_done.model ? this.minimalUser.id : null;
            const [, error] = await helpers.try(this.updater.fields.is_done.save());
            if(error) return done_by.cancel();
            done_by.stop();
        },
        debounceActiveFilters() {
            this.debounce.filters(this[getterTypes.filters.ACTIVE_BY_GROUP]);
        }
    },
    computed: {
        ...mapState(['users', 'user', 'draggable', 'screens']),
        ...mapGetters(['selectedOrder', [getterTypes.filters.ACTIVE_BY_GROUP], 'minimalUser']),
        list() {
            return this.$store.getters[getterTypes.lists.active];
        },
        screenSmallerThanMd() {
            return this.$store.getters[getterTypes.screens.IS_SMALLER_THAN_MD];
        },
        stored() {
            return !!this.task.uuid;
        },
        isEditingSomething()
        {
            return this.$store.getters[types.getters.doing.IS_EDITING];
        },
        hasNestedTasks() {
            const { ordered_children } = this.task;
            return ordered_children && ordered_children.length > 0; 
        },
        openNestedTasks: {
            get() {
                // when we store to database openNested should be model property
                return !!(this.task.show && this.hasNestedTasks);
            },
            set(value) {
                // when we store to database this should make a call too
                if (this.hasNestedTasks) {
                    const { show } = this.updater.fields;
                    show.start().model = value;
                    show.save();
                }
            }
        },
        draggableTasks: {
            get() {
                return this.task.ordered_children
            },
            set(children) {
                const bulk = [];
                // making sure children have correct attributes.
                children.forEach((child, childIndex) => {
                    const { parent_id, task_group_id, order } = child;
                    const updateNeeded = parent_id !== this.task.id || task_group_id !== this.task.task_group_id || order !== childIndex;
                    if (updateNeeded) {
                        child.parent_id = this.task.id;
                        child.task_group_id = this.task.task_group_id;
                        child.order = childIndex;
                        bulk.push({ uuid: child.uuid, parent_id: child.parent_id, task_group_id: child.task_group_id, order: child.order })
                    }
                    if (child.ordered_children && child.ordered_children.length) {
                        // grandChildren should be child since further nesting is forbidden
                        child.ordered_children
                            // .map(grandChild => ({ ...grandChild, parent_id: child.parent_id, task_group_id: child.task_group_id }) )
                            .forEach((grandChild, grandChildIndex) => {
                                const toChild = { 
                                    ...grandChild, 
                                    parent_id: child.parent_id, 
                                    task_group_id: child.task_group_id,
                                    order: child.order + grandChildIndex + 1
                                };
                                bulk.push({ uuid: toChild.uuid, parent_id: toChild.parent_id, task_group_id: toChild.task_group_id, order: toChild.order })
                                children.push(toChild);
                            });
                        // emptying grandChildren
                        child.ordered_children.splice(0, child.ordered_children.length);
                    } else {
                        child.ordered_children = [];
                    }
                })
                if (bulk.length) models.task.bulkUpdate(bulk);
                // setting value to formated one.
                this.$emit("refreshTask", { index: this.index, task: { ...this.task, ordered_children: children } });
            }
        },
        isDoneStyle()
        {
            return {
                borderColor: this.taskColor.value,
                backgroundColor: this.task.is_done || this.hover.is_done
                    ? this.taskColor.value
                    : this.taskColor.lighter
            };
        },
        taskColor() 
        {
            return models.taskColor.all.find(c => c.id === this.task.color_id) ?? models.taskColor.default;
        },
        calendarDueDate: {
            get() {
                const { due_date } = this.updater.fields;
                if (!due_date.model) return null;
                return moment(due_date.model)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .toDate();
            },
            set(value) {
                console.log({ value });
                const newValue = this.getNewCalendarDate(value);
                if (newValue === false) return;
                const { due_date } = this.updater.fields;
                if (!newValue) this.calendarHasTime = false;
                due_date.model = newValue;
                due_date.save();
            }
        },
        calendarDueTime: {
            get() {
                const { due_date } = this.updater.fields;
                if (!due_date.model || !this.task.due_date_has_time) return null;
                return moment(due_date.model).format('HH:mm');
            },
            set(value) {
                console.log({value}, 'timepicker');
                this.calendarHasTime = !!value;
                if(!value) return;
                const [ hour, minute ] = value.split(':');
                const { due_date } = this.updater.fields;
                if (!due_date.model) return;
                due_date.model = moment(due_date.model)
                    .hour(hour)
                    .minute(minute)
                    .format(this.due_date.format.store);
                // const { due_date } = this.updater.fields;
                // // Preventing save when setting initially
                // if (moment(due_date.model).isSame(value)) return;

                // console.log(due_date.model = value
                //     ? moment(value).format(this.due_date.format.store)
                //     : value)
                // due_date.save();
            }
        },
        calendarHasTime: {
            get() {
                return this.task.due_date_has_time || false;
            },
            set(value) {
                console.log({value}, 'hasTime');
                const { due_date_has_time, due_date } = this.updater.fields;
                // if (due_date.model && value && !this.calendarDueTime) this.calendarDueTime = '08:00';
                due_date_has_time.model = value;
            }
        },
        formattedDueDate()
        {
            const { model } = this.updater.fields.due_date;
            return model ? moment(model).format(this.due_date.format.display) : "Aucune date";
        },
        isListDetailsPage() {
            return this.$route?.name === 'account.lists.show';
        },
        isListPreventingFilters()
        {
            return this.$store.state.active_list_prevent_filters;
        },
        isListHavingDefaultFilters() {
            return this.$store.getters[getterTypes.lists.IS_ACTIVE_LIST_HAVING_DEFAULT_FILTERS];
        }
    },
    watch: {
        selectedOrder: {
            immediate: true,
            handler(order)
            {
                this.task.ordered_children?.sort(order.sort);
            },
        },
        [getterTypes.filters.ACTIVE_BY_GROUP]: {
            immediate: true,
            handler(groups) {
                if (!this.debounce.filters) {
                    // debouncing filter update
                    this.debounce.filters = helpers.debounce(groups => this.display.task = this.recursiveDisplay(this.task, groups), 250);
                }
                if (!this.stored || !this.isListDetailsPage) return;
                this.debounce.filters(groups);
            }
        },
        'task.prevent_filters'(prevent) {
            console.log({ prevent })
            if (prevent) {
                console.log(this.isListPreventingFilters || this.isListHavingDefaultFilters);
                if (this.isListPreventingFilters || this.isListHavingDefaultFilters) return;
                // if we want to dissociate filters and sorting
                    //if (this.recursiveDisplay(this.task, this[getterTypes.filters.ACTIVE_BY_GROUP])) return;
                return this.$store.dispatch(actionTypes.lists.SET_ACTIVE_PREVENT_FILTERS, prevent);
            }
            // user wants to apply filters => apply
            this.debounceActiveFilters();
            this.$emit("debounceFilters")
        },
        isListPreventingFilters(prevent) {
            if (prevent) return;
            // if we stop preventing => apply sorting
            this.task.prevent_filters = false;
            this.task.ordered_children?.sort(this.selectedOrder.sort);
        }
    },
    created()
    {
        if (! this.task.ordered_children) this.task.ordered_children = [];
        this.setUpdater();
        // listening enter and escape keys for due_date(since popping calendar and not input)
        this.keypress = new helpers.keypress(window);
        window.addEventListener(this.keypress.getEventName(['Enter']), this.enterPressed);
        window.addEventListener(this.keypress.getEventName(['Escape']), this.escapePressed);
        this.setModalListeners();

        if (!this.stored) this.updater.fields.title.start().model = ''
    }
}
</script>

<style lang="scss">
    @media (min-width: 768px) {
        .md\:w-task-text { width: calc(100% - 312px); }
        .md\:task-container { width: calc(100% - 30px); }
    }
</style>