import orders from "./orders";
import filters from "./filters";
import calendar from "./calendar";
import draggable from "./draggable";
import screens from "./screens";
import doing from "./doing";
import tasks from "./tasks";
export default {
    loadingApplication: true,
    user: null,
    users: [],
    account: null,
    token: null,
    sidebarSection: null,
    apps: null,
    layout: {
        header: true,
        sidebar: true,
        container: true,
        intercom: true
    },
    lists: [],
    active_list: null,
    active_list_prevent_filters: false,
    lists_archives_count: 0,
    counters: {
        late: null,
        today: null
    },
    orders,
    filters,
    calendar,
    draggable,    
    screens,    
    doing,
    tasks,
    modal: false,
    locale: 'fr'
};