<script>
export default {
    name: 'ElementPicker',
    props: {
        value: {
            type: Array,
            required: true
        },
        trackBy: {
            type: String,
            required: false,
            default: 'uuid'
        },
        searchBy: {
            required: false,
            type: String,
            default: 'title'
        },
        elements: {
            type: Array,
            required: true
        },
        multiple: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            input: {
                value: ''
            },
            highlight: {}
        }
    },
    methods: {
        addElement(el)
        {
            if (!this.value.find(e => e[this.trackBy] === el[this.trackBy])) {
                this.$emit('input', this.multiple ? [...this.value, el] : [el] );
            }

            this.resetInputValue();
        },
        deleteElement(el)
        {
            this.$emit('input', this.value.filter(e => e[this.trackBy] !== el[this.trackBy]));
            this.resetInputValue();
        },
        toggleElement(el) {
            this.selected(el)
                ? this.deleteElement(el)
                : this.addElement(el)
        },
        highlightPreviousElement(list) {
            // if not highlight select first
            if (!this.hasHighlight) {
                if (!this.value.length) return this.setHightlight(list[0])
                return this.setHightlight(this.value[0]);
            }
            const actualIndex = list.findIndex(el => el[this.trackBy] === this.highlight[this.trackBy]);
            // If first -> highlight last
            if (actualIndex === 0) return this.setHightlight(list[list.length - 1]);
            this.setHightlight(list[actualIndex - 1]);
        },
        highlightNextElement(list) {
            // if not highlight select first
            if (!this.hasHighlight) {
                if (!this.value.length) return this.setHightlight(list[0])
                return this.setHightlight(this.value[0]);
            }
            const actualIndex = list.findIndex(el => el[this.trackBy] === this.highlight[this.trackBy]);
            // If last -> highlight first
            if (actualIndex === list.length - 1) return this.setHightlight(list[0]);
            this.setHightlight(list[actualIndex + 1]);
        },
        setHightlight(el) {
            this.highlight = el;
        },
        isHighlighted(el) {
            return this.highlight[this.trackBy] === el[this.trackBy];
        },
        selected(el)
        {
            return this.value.find(e => e[this.trackBy] === el[this.trackBy]) ? true : false;
        },
        normalize(text)
        {
            return [...text.trim()].map(l => l.toLowerCase()).join('');
        },
        resetInputValue()
        {
            this.input.value = '';
        }
    },
    computed: {
        notSelected()
        {
            return this.elements.filter(e => this.value.find(el => e[this.trackBy] !== el[this.trackBy]));
        },
        notSelectedMatchingInput()
        {
            const value =  this.normalize(this.input.value);
            if (!value.length) return this.notSelected;

            return this.notSelected.filter(e => this.normalize(e[this.searchBy]).includes(value));
        },
        matchingInput()
        {
            const value =  this.normalize(this.input.value);
            if (!value.length) return this.elements;

            return this.elements.filter(e => this.normalize(e[this.searchBy]).includes(value));
        },
        hasHighlight()
        {
            return !!this.highlight[this.trackBy];
        }
    },
    render()
    {
        const { 
            addElement,
            deleteElement,
            toggleElement,
            selected,
            notSelected,
            notSelectedMatchingInput,
            matchingInput,
            elements,
            highlight,
            hasHighlight,
            highlightPreviousElement,
            highlightNextElement,
            isHighlighted,
        } = this
        
        return this.$scopedSlots.default({
            addElement,
            deleteElement,
            toggleElement,
            selected,
            highlightPreviousElement,
            highlightNextElement,
            isHighlighted,
            highlight,
            hasHighlight,
            elements: {
                notSelected,
                notSelectedMatchingInput,
                matchingInput,
                selected: this.value,
                all: elements
            },
            input: {
                attributes: {
                    value: this.input.value
                },
                events: {
                    input: ({ target: { value } }) => this.input.value = value.trim(),
                    keydown: ({ key }) => {
                        if (key === 'Escape') this.resetInputValue()
                    }
                },
                reset: this.resetInputValue
            }
        });
    }
}
</script>
