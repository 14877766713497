<template>
    <div class="flex items-center">
        <div class="" v-if="users.length === 0">
                <div class="h-6 w-6 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer">
                        <i class="fas fa-user-plus text-gray-600 text-[11px] transform -translate-y-0.5"></i>
                </div>
        </div>
        <div class="-ml-2 first:ml-0 h-6 w-6 border-2 border-white cursor-pointer bg-gray-200 rounded-full flex items-center justify-center" v-if="moreUserCount">
            <span class="text-[10px] text-gray-800">{{ `+${moreUserCount}` }}</span>
        </div>
        <div class="-ml-2 first:ml-0" v-for="user in visibleUsers" :key="user.id">
            <div class="h-6 w-6 rounded-full border-2 border-white cursor-pointer">
                <user-avatar :user="user" />
            </div>
        </div>
    </div>
</template>

<script>
import UserAvatar from "~base/components/_Partials/Avatar.vue";

export default {
    props: {
        limit: {
            required: true,
            type: Number
        },
        users: {
            required: true,
            type: Array
        }
    },
    components: {
        UserAvatar
    },
    computed: {
        visibleUsers()
        {
            return this.hasMoreUsers ? this.users.slice(0, this.limit) : this.users;
        },
        userCount()
        {
            return this.users.length;
        },
        hasMoreUsers()
        {
            return this.userCount > this.limit;
        },
        moreUserCount()
        {
            return this.hasMoreUsers ? this.userCount - this.limit : 0;
        }
    }
}
</script>

<style>

</style>