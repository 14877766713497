<template>
    <div class="relative flex flex-col h-full bg-white">
        <v-spa-loader v-if="loading"></v-spa-loader>
        <div class="flex p-2">
            <calendar-controls />
            <calendar-views />
        </div>
        <div class="flex-auto pt-1 overflow-x-hidden" ref="calendarContainer">
            <transition
                enter-active-class="transition-all duration-75 transform"
                leave-active-class="transition-all duration-75 transform"
                :enter-class="'opacity-0 ' + transitionEnterClasses"
                enter-to-class="translate-x-0 opacity-100"
                leave-class="translate-x-0 opacity-100"
                :leave-to-class="'opacity-0 ' + transitionLeaveToClasses"
                @after-leave="stopTransition"
                @enter="transitionCallCallback"
            >
                <div class="h-full" v-show="isCalendarReady">
                    <full-calendar
                        :options="fullCalendarOptions"
                        v-if="!loading"
                        ref="fullCalendar"
                    ></full-calendar>
                </div>
            </transition>
        </div>
    </div>
 
</template>

<script>

import Swipe from '~base/helpers/swipe';
import Zoom from '~base/helpers/zoom';
import FullCalendar from '@fullcalendar/vue';
import TimeGridView from '@fullcalendar/timegrid'
import DayGridView from '@fullcalendar/daygrid'
import Interaction from '@fullcalendar/interaction'
import FrLocale from '@fullcalendar/core/locales/fr';
import CalendarControls from "~base/components/Calendar/Controls.vue"
import CalendarViews from "~base/components/Calendar/Views.vue"
// import list from '@fullcalendar/list'

import { keypress } from '~base/helpers'
import { round } from '~base/helpers/numbers';
import { getNextMomentMatching, getShortMonth } from '~base/helpers/moment';
import models from "~base/models";
import modals from "~base/components/Modals/Config"

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    components: {
        FullCalendar,
        CalendarControls,
        CalendarViews
    },
    // props: {
    //     loading: {
    //         required: false,
    //         default: false,
    //         type: Boolean,
    //     },
    // },
    directives: {
        
    },
    data()
    {
        return {
            modals: modals.bind(this)(),
            loading: true,
            zoomMultiplicator: 1.2,
            allowSelection: true,
            selectionColors: {
                visible: 'rgba(43, 211, 245, 0.3)',
                hidden: 'rgba(43, 211, 245, 0)',
            },
            transitionStart: false,
            transitionCallback: null,
            transitionDirection: 'right',
            fullCalendarOptions: {
                plugins: [
                    TimeGridView,
                    DayGridView,
                    Interaction,
                ],
                headerToolbar: false
                // {
                    // left: '',
                    // center: 'title',
                    // right: ''
                    // left: 'prev,next today',
                    // center: 'title',
                    // right: 'dayGridMonth,timeGridWeek,timeGridDay'
                // }
                ,
                views: {
                    timeGridWeek: {
                        dayHeaderFormat: { weekday: 'short', day: 'numeric', omitCommas: true },
                        dayHeaderContent: this.renderTimeGridWeekHeader,
                    },
                    timeGridDay: {
                        dayHeaderContent: this.renderTimeGridDayHeader,
                    },
                    dayGridMonth: {
                        titleFormat: { month: 'short', year: 'numeric' },
                        dayCellContent: this.renderDayInMonthView,
                        eventContent: this.renderEventInMonthView,
                    }
                },
                slotDuration: '00:15:00',
                slotLabelInterval: '01:00',
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                },
                initialView: "timeGridDay",
                datesSet: this.datesSet,
                nowIndicator: true,
                locale: FrLocale,
                events: [],
                eventColor: null,
                eventTextColor: null,
                eventStartEditable: true,
                eventDurationEditable: false,
                // editable: true,

                // eventContent: this.renderEvent,
                defaultTimedEventDuration: '00:15',
                eventClick: this.editEvent,
                // eventResize: this.editMovedEvent,
                eventDrop: this.editMovedEvent,
                selectable: false,
                select: this.createEventBasedOnSelection, 
                allDaySlot: false,
                handleWindowResize: true,
                height: "100%",
                navLinks: true,
                longPressDelay: 400,
            }
        }
    },
    methods: {
        ...mapActions(['setEvents', 'updateEvent']),
        setDefaultEventColors()
        {
            this.fullCalendarOptions.eventColor = this.getTailwindValue('theme.colors.gray.200');
            this.fullCalendarOptions.eventTextColor = this.getTailwindValue('theme.colors.gray.600');

            return this;
        },

        getEventColor(event)
        {
            return event.textColor ? event.textColor : this.fullCalendarOptions.eventTextColor;
        },

        getEventBackgroundColor(event)
        {
            return event.backgroundColor ? event.backgroundColor : this.fullCalendarOptions.eventColor;
        },

        renderTimeGridWeekHeader({date, isToday})
        {
            let momentDate = window.moment(date);
            let circleClass = 'h-10 w-10 flex justify-center items-center rounded-full mb-2'
            circleClass += isToday ? ' bg-primary' : '';
            let dayClass = `text-2xl font-thin ${isToday ? "text-white" : "text-gray-600"}`

            return {
                html: `<div>
                            <span class="block text-gray-500 uppercase text-2xs">${momentDate.format('ddd')}</span>
                            <span class="${circleClass}">
                                <span class="${dayClass}">${momentDate.format('D')}</span>
                            </span>
                        </div>`
            }
        },

        renderDayInMonthView({date, isToday})
        {
            
            if (isToday) {
                let link = document.createElement('a');
                link.classList = 'h-10 w-10 flex justify-center items-center cursor-pointer rounded-full bg-primary text-white font-bold';
                link.innerText = new Date().getDate();
                link.addEventListener('mousedown', e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.calendarGoToDate(date)
                        .changeCalendarView('timeGridDay');
                });
                
                return { domNodes: [ link ] }
            }
        },

        renderEventInMonthView(renderInfo)
        {
            const {event, isToday, timeText} = renderInfo
            
            const setColorsAsHighlight = node => {
                node.style.backgroundColor = this.getTailwindValue('theme.colors.gray.300');                
                node.style.borderColor = this.getTailwindValue('theme.colors.gray.300');
                node.style.color = this.getTailwindValue('theme.colors.gray.800');
            };
            
            const setColorsAsDefault = node => {
                node.style.backgroundColor = this.getEventBackgroundColor(event);                
                node.style.borderColor = this.getEventBackgroundColor(event);
                node.style.color = this.getEventColor(event);
            };
           
            let title = document.createElement('div');
            title.classList = 'p-1 w-full truncate';
            let text = document.createElement('span');
            text.innerText = `${event.title}`;
            title.appendChild(text);
            setColorsAsDefault(title);
            // title.addEventListener('mouseenter', e => setColorsAsHighlight(e.target));
            // title.addEventListener('mouseleave', e => setColorsAsDefault(e.target));
            return { domNodes: [ title ] };
        },

        calendarGoToDate(date)
        {
            this.getCalendarApi().gotoDate(date);

            return this;
        },

        renderTimeGridDayHeader({date, isToday})
        {
            return this.renderTimeGridWeekHeader({date, isToday});
        },

        setCalendarDefaultView()
        {
            if (this.hasDefaultViewToken) {
                this.fullCalendarOptions.initialView = this.getDefaultViewToken;
            } else if (window.innerWidth >= this.getTailwindBreakPoint('sm')) {
                this.fullCalendarOptions.initialView = 'dayGridMonth';
            }

            return this;
        },

        changeCalendarView(view)
        {
            if (view == this.calendar.view) {
                return this;
            }

            return this.setTransitionCallback(() => this.changeCalendarViewFromApi(view))
                .setTransitionDirection( this.getChangeViewDirection(view) )
                .startTransition();
        },

        changeCalendarViewFromApi(view)
        {
            this.getCalendarApi().changeView(view);
        },

        getCalendarView()
        {
            return this.getCalendarApi().view;
        },

        getChangeViewDirection(viewName)
        {
            const views = ['dayGridMonth', 'timeGridWeek', 'timeGridDay']
            const currentView = this.getCalendarView().type;

            return views.findIndex(view => view == viewName) > views.findIndex(view => view == currentView) ? 'left' : 'right';
        },

        datesSet( info )
        {
            const { view, start, end } = info;

            return Promise.all([
                this.setCalendarView(view.type),
                this.setCalendarTitle(start, end, view.title),
                this.setCalendarIsToday(start, end),
                this.handleCalendarLimits(start, end, view.currentStart),
            ])
        },

        setCalendarView(type)
        {
            return this.$store.dispatch('setCalendarView', type);
        },

        setCalendarIsToday(start, end)
        {
            return this.$store.dispatch('setCalendarIsTodayFromDatesRange', { start, end });
        },

        setCalendarTitle(start, end, defaultTitle)
        {
            const title = this.isMonthView ? defaultTitle : ( this.isWeekView ? this.getCalendarWeekTitle(start, end) : this.getCalendarDayTitle(start) );
    
            return this.$store.dispatch('setCalendarTitle', title);
        },

        getCalendarWeekTitle(start, end)
        {
            const months = {
                start: start.getMonth(),
                end: end.getMonth(),
            };

            if (months.start == months.end) {
                return `${this.format(start, 'MMM YYYY')}`
            }

            return `${getShortMonth(start)} - ${this.format(end, 'MMM YYYY')}`
        },

        getCalendarDayTitle(start)
        {
            return this.format(start, 'MMM YYYY');
        },

        format(date, formatType)
        {
            return moment(date).format(formatType);
        },

        handleCalendarLimits(start, end, idealStart)
        {
            if (this.areDatesInLimits(start, end)) {
                console.log('in limits', start, end , this.$store.getters.calendarLimitsToString);
                return Promise.resolve();
            }

            console.log('not in limits', '/ actual:'+ start.toString() + end.toString(),'/ limits:' + this.$store.getters.calendarLimitsToString)

            return this.$store.dispatch('setCalendarLimitDates', this.getCalendarNewLimitsBasedOnDate(moment(idealStart)) )
                        .then(() => this.setCalendarEvents());
        },

        areDatesInLimits(start, end)
        {
            return this.isDateInCalendarLimits( start, true ) && this.isDateInCalendarLimits( end );
        },

        getTodayCalendarPage()
        {
            if (this.calendar.isToday) {
                return this;
            }
            
            const direction = this.isTodayForward() ? 'left' : 'right'
            
            return this.setTransitionCallback(this.getTodayPageFromApi)
                .setTransitionDirection(direction)
                .startTransition();
        },

        isTodayForward()
        {
            return new Date > this.getCalendarStartDate();
        },

        getCalendarStartDate()
        {
            return this.getCalendarApi().view.activeStart;
        },

        getTodayPageFromApi()
        {
            this.getCalendarApi().today();
        },

        // adaptCalendarView(e)
        // {
        //     let api = this.getCalendarApi();
        //     let width = e.target.innerWidth;

        //     if (this.shouldChangeToDayView(width, api)) {
        //         api.changeView('timeGridDay');
        //     }

        //     if (this.shouldChangeToWeekView(width, api)) {
        //         api.changeView('timeGridWeek');
        //     }

        //     return this;
        // },

        // shouldChangeToDayView(width, api)
        // {
        //     return width < this.getTailwindBreakPoint('sm') && api.view.type == 'timeGridWeek';
        // },

        // shouldChangeToWeekView(width, api)
        // {
        //     return width >= this.getTailwindBreakPoint('sm') && api.view.type == 'timeGridDay';
        // },

        createEventBasedOnSelection( selection )
        {
            if (! this.allowSelection) {
                return this.cancelSelection()
                    .showSelection();
            }

            const { startStr: start, endStr: end } =  selection;

            this.$bus.$emit('calendar:createEventBasedOnDates', { start, end });

            return this;
        },

        editEvent( eventInfo )
        {
            const task = models.calendarTask.toTask(eventInfo.event)
            this.modals.calendarTask.show({ task });

            // this.$bus.$emit('calendar:editEvent', { event })
            
            return this;
        },

        async editMovedEvent( eventInfo )
        {
            const { event, revert, oldEvent } = eventInfo;
            const task = models.calendarTask.toTask(event);
            const calendarTask = models.calendarTask.fromCalendar(event);
            try {
                this.updateEvent({ event: calendarTask })
                await models.task.update(task, { field: 'due_date', user: this.minimalUser });
                console.log('Updated task succesfully');
            } catch(err) {
                revert();
                this.updateEvent({ event: { ...calendarTask, start: oldEvent.start } })
                console.error('Cannot update task')
                throw err
            }

            // this.$bus.$emit('calendar:editEvent', { event, revert })
            
            return this;
        },

        revertCalendarAction(revert)
        {
            revert();

            return this;
        },

        getNextCalendarPage()
        {
            return this.setTransitionCallback(this.getNextCalendarPageFromApi)
                .setTransitionDirection('left')
                .startTransition();
        },

        getNextCalendarPageFromApi()
        {
            this.getCalendarApi().next();
        },

        transitionCallCallback()
        {
            if (this.transitionCallback) {
                this.transitionCallback();
                this.setTransitionCallback(null);
            }
        },

        setTransitionCallback(callback)
        {
            this.transitionCallback = callback;

            return this;
        },

        setTransitionDirection(direction)
        {
            this.transitionDirection = direction;

            return this;
        },

        startTransition() 
        {
            this.transitionStart = true;
            this.$bus.$emit('calendar:transitionTitle', this.transitionDirection);

            return this;
        },

        stopTransition()
        {
            setTimeout(() => this.transitionStart = false, 10);

            return this;
        },

        getPreviousCalendarPage()
        {
            return this.setTransitionCallback(this.getPreviousCalendarPageFromApi)
                .setTransitionDirection('right')
                .startTransition();
        },

        getPreviousCalendarPageFromApi()
        {
            this.getCalendarApi().prev();
        },

        hideSelection()
        {
            this.allowSelection = false;
            return this.setColorSelection(false);
        },

        cancelSelection()
        {
            this.getCalendarApi().unselect();

            return this;
        },

        hideEvents()
        {
            this.fullCalendarOptions.events = [];

            return this;
        },

        showEvents()
        {
            this.fullCalendarOptions.events = this.events;

            return this;
        },

        getCalendarApi()
        {
            return this.$refs.fullCalendar.getApi();
        },

        updateCalendarSize()
        {
            this.getCalendarApi().updateSize();

            return this;
        },

        updateCalendarSizeAfterSidebarToggle()
        {
            setTimeout(() => this.updateCalendarSize(), 250)

            return this;
        },

        forceCalendarRender()
        {
            return this.hideEvents()
                .showEvents();
        },

        startZooming()
        {
            return this
                .hideSelection();
        },

        zoomingInProgress(e)
        {
            const { zoom: { current: multiplicator } } = e.detail;

            return this.zoomInCalendar(multiplicator);
        },

        zoomInCalendar(multiplicator)
        {
            if (this.isMonthView) {
                return this;
            }

            const name = '--fc-timeline-hour-height';
            const value = round(this.getCssVariable(name).replace(/[^0-9\.]/gm, '') * multiplicator) + 'rem';
            
            return this.setCssVariable(name, value)
                .forceCalendarRender();
        },

        showSelection()
        {
            this.allowSelection = true;
            return this.setColorSelection();
        },

        setColorSelection(isVisible = true)
        {
            document.documentElement.style.setProperty('--fc-highlight-color', isVisible ? 'rgba(43, 211, 245, 0.3)' : 'rgba(43, 211, 245, 0)');

            return this;
        },

        setCssVariable(name, value)
        {
            document.documentElement.style.setProperty(name, value);
            
            return this;
        },

        getCssVariable(name, value)
        {
            return getComputedStyle(document.documentElement).getPropertyValue(name);
        },

        keyboardShortcutHandler(callback)
        {
            if (this.isModalOpen) {
                return this;
            }

            return callback();
        },

        zoomFromShortcut() {
            const callback = () => this.zoomInCalendar(this.zoomMultiplicator);

            return this.keyboardShortcutHandler(callback)
        },

        dezoomFromShortcut()
        {
            const callback = () => this.zoomInCalendar( (1 / this.zoomMultiplicator) );

            return this.keyboardShortcutHandler(callback)
        },

        getTodayCalendarPageFromShortcut()
        {
            return this.keyboardShortcutHandler(this.getTodayCalendarPage)
        },

        getNextCalendarPageFromShortcut()
        {
            return this.keyboardShortcutHandler(this.getNextCalendarPage)
        },

        getPreviousCalendarPageFromShortcut()
        {
            return this.keyboardShortcutHandler(this.getPreviousCalendarPage)
        },

        getMonthViewFromShortcut()
        {
            const callback = () => this.changeCalendarView('dayGridMonth');

            return this.keyboardShortcutHandler(callback)
        },

        getWeekViewFromShortcut()
        {
            const callback = () => this.changeCalendarView('timeGridWeek');

            return this.keyboardShortcutHandler(callback)
        },

        getDayViewFromShortcut()
        {
            const callback = () => this.changeCalendarView('timeGridDay');

            return this.keyboardShortcutHandler(callback)
        },

        // added
        setDefaultCalendarLimits()
        {
            return this.$store.dispatch('setCalendarLimitDates', this.getCalendarNewLimitsBasedOnDate(window.moment()) );
        },

        async setCalendarEvents()
        {
            return this.events = await models.calendarTask.index(this.calendarLimitsToString)
        }

    },
    computed: {
        ...mapState(['calendar']),
        ...mapGetters(['getTailwindValue', 'getTailwindBreakPoint', 'hasDefaultViewToken', 'getDefaultViewToken', 'isWeekView', 'isMonthView', 'isModalOpen', 'isDateInCalendarLimits', 'getCalendarNewLimitsBasedOnDate', 'calendarLimitsToString', 'minimalUser']),

        events:
        {
            get() {
                return this.calendar.events;
            }, 
            set(events) {
                this.setEvents(events)
            }
        },

        isCalendarReady()
        {
            if (this.loading) {
                return false;
            }

            return this.transitionStart ? false : true;
        },

        transitionLeaveToClasses()
        {
            return this.transitionIsRight ? 'translate-x-12' : '-translate-x-12';
        },

        transitionEnterClasses()
        {
            return this.transitionIsRight ? '-translate-x-12' : 'translate-x-12';
        },

        transitionIsRight()
        {
            return this.transitionDirection == 'right';
        },
        
    },
    watch: {
        events(events) {
            this.fullCalendarOptions.events = events;
        },

        loading(isLoading)
        {
            if ( isLoading || this.keyPress ) {
                return;
            }

            this.keyPress = new keypress(window);
            window.addEventListener(this.keyPress.getEventName(['ArrowLeft']), this.getPreviousCalendarPageFromShortcut);
            window.addEventListener(this.keyPress.getEventName(['ArrowRight']), this.getNextCalendarPageFromShortcut);
            // window.addEventListener(this.keyPress.getEventName(['Control', 'z']), e => console.log(e));
            window.addEventListener(this.keyPress.getEventName(['Control', 'ArrowUp']), this.zoomFromShortcut);
            window.addEventListener(this.keyPress.getEventName(['Control', 'ArrowDown']), this.dezoomFromShortcut);
            window.addEventListener(this.keyPress.getEventName(['a']), this.getTodayCalendarPageFromShortcut);
            window.addEventListener(this.keyPress.getEventName(['j']), this.getDayViewFromShortcut);
            window.addEventListener(this.keyPress.getEventName(['s']), this.getWeekViewFromShortcut);
            window.addEventListener(this.keyPress.getEventName(['m']), this.getMonthViewFromShortcut);
        },
    },
    created()
    {
        this
            .setDefaultCalendarLimits()
                .then(() => this.setCalendarEvents())
                .then(() => {
                    this.loading = false;
                    this.$bus.$emit('calendar:loaded');
                })
        this
            .setCalendarDefaultView()
            .showSelection()
            .setDefaultEventColors()
            .$bus.$on('fullCalendar:changeView', this.changeCalendarView)
            .$bus.$on('fullCalendar:nextPage', this.getNextCalendarPage) 
            .$bus.$on('fullCalendar:previousPage', this.getPreviousCalendarPage) 
            .$bus.$on('fullCalendar:today', this.getTodayCalendarPage)
            .$bus.$on('fullCalendar:cancelSelection', this.cancelSelection)
            .$bus.$on('fullCalendar:revert', this.revertCalendarAction)
            .$bus.$on('sidebar:toggled', this.updateCalendarSizeAfterSidebarToggle)
            // .$modalRenderer.add('v-calendar-modal', 'Calendar/FormHandler'); 
    },
    mounted()
    {
        const container = this.$refs.calendarContainer;
        
        this.swipeApi = new Swipe(container, 20, 500);
        container.addEventListener('swipeRight', this.getPreviousCalendarPage);
        container.addEventListener('swipeLeft', this.getNextCalendarPage);
        
        this.zoomApi = new Zoom(container, 333);
        container.addEventListener('zoomStart', this.startZooming);
        container.addEventListener('zoomInProgress', this.zoomingInProgress);
        container.addEventListener('zoomStop', this.stopZooming);
    },
    beforeDestroy() {
        const container = this.$refs.calendarContainer;
        
        this.swipeApi.removeListeners();
        container.removeEventListener('swipeRight', this.getPreviousCalendarPage);
        container.removeEventListener('swipeLeft', this.getNextCalendarPage);
        
        this.zoomApi.removeListeners();
        container.removeEventListener('zoomStart', this.startZooming);
        container.removeEventListener('zoomInProgress', this.zoomingInProgress);
        container.removeEventListener('zoomStop', this.stopZooming);

        window.removeEventListener(this.keyPress.getEventName(['ArrowLeft']), this.getPreviousCalendarPageFromShortcut);
        window.removeEventListener(this.keyPress.getEventName(['ArrowRight']), this.getNextCalendarPageFromShortcut);
        // window.removeEventListener(this.keyPress.getEventName(['Control', 'z']), e => console.log(e));
        window.removeEventListener(this.keyPress.getEventName(['Control', 'ArrowUp']), this.zoomFromShortcut);
        window.removeEventListener(this.keyPress.getEventName(['Control', 'ArrowDown']), this.dezoomFromShortcut);
        window.removeEventListener(this.keyPress.getEventName(['a']), this.getTodayCalendarPageFromShortcut);
        window.removeEventListener(this.keyPress.getEventName(['j']), this.getDayViewFromShortcut);
        window.removeEventListener(this.keyPress.getEventName(['s']), this.getWeekViewFromShortcut);
        window.removeEventListener(this.keyPress.getEventName(['m']), this.getMonthViewFromShortcut);

        this
            .$bus.$off('fullCalendar:changeView', this.changeCalendarView)
            .$bus.$off('fullCalendar:nextPage', this.getNextCalendarPage) 
            .$bus.$off('fullCalendar:previousPage', this.getPreviousCalendarPage) 
            .$bus.$off('fullCalendar:today', this.getTodayCalendarPage)
            .$bus.$off('fullCalendar:cancelSelection', this.cancelSelection)
            .$bus.$off('fullCalendar:revert', this.revertCalendarAction)
            .$bus.$off('sidebar:toggled', this.updateCalendarSizeAfterSidebarToggle)
            // .$modalRenderer.remove('v-calendar-modal');
    },
}
</script>

<style lang="scss">
    :root {
        --fc-button-text-color: theme('colors.gray.600');
        --fc-button-bg-color: white;
        --fc-button-border-color: theme('colors.gray.200');
        --fc-button-hover-bg-color: theme('colors.gray.100');
        --fc-button-hover-border-color: theme('colors.gray.200');
        --fc-button-active-bg-color: theme('colors.gray.200');
        --fc-button-active-border-color: theme('colors.gray.300');

        // --fc-event-bg-color: theme('colors.gray.300');
        // --fc-event-border-color: theme('colors.gray.300');
        // --fc-event-text-color: theme('colors.gray.600');

        --fc-now-indicator-color: theme('colors.red.600');
        --fc-today-bg-color: rgba(255, 255, 255, 0);

        --fc-timeline-hour-height: .9rem;
    }

    .fc {
        .fc-toolbar {
            &.fc-header-toolbar {
                @apply my-6 pr-4;
            }
        }
        .fc-button {
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &:hover, &.fc-button-active {
                &:not(:disabled) {
                    color: theme('colors.gray.800');
                }
            }
            &:disabled {
                cursor: default;
            }
        }
    }

    .fc .fc-timegrid-slot-minor
    {
        border: none;
    }

    .fc .fc-timegrid-slot {
        height: var(--fc-timeline-hour-height, .8rem);
    }

    .fc .fc-timegrid-slot-label-cushion
    {
        font-size: 11px;
        color: theme('colors.gray.500');
        position: relative;
        top: -19px;
    }

    .fc .fc-timegrid-slot-label-frame.fc-scrollgrid-shrink-frame 
    {
        height: calc(var(--fc-timeline-hour-height, .8rem) * .25);
    }

    .fc-theme-standard .fc-scrollgrid 
    {
        border: none;
    }

    .fc .fc-timegrid-slot-label 
    {
        border: none;
    }

    .fc thead .fc-scroller 
    {
        overflow-y: hidden !important;
    }

    .fc .fc-toolbar-title 
    {
        @apply capitalize text-sm text-gray-600;
        @screen md {
            @apply text-lg;
        }
    }

    .fc-today-button {
        display: none !important;
        @screen sm {
            display: inline-block !important;
        }
    }

    .fc .fc-timegrid-now-indicator-line {
        border-width: 3px 0 0;
    }
    
    .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
        border-width: 8px 0 8px 9px;
    }

    .fc-daygrid-dot-event {
        padding: 0;
    }
    
</style>