<template>
    <sidebar-list-group 
        :lists="privateLists"
        :draggable="{ group: 'private' }"
        :title="$t('sidebar.lists.private')"
        :isFavorite="false"
    />
</template>

<script>

import SidebarListGroup from "~base/components/_Layout/Sidebar/Lists/ListGroup.vue";
import { getterTypes } from '~base/store/getters';

export default {
    components: {
        SidebarListGroup
    },
    computed: {
        privateLists() {
            return this.$store.getters[getterTypes.lists.sorted].private;
        }
    }
}
</script>

<style>

</style>