<template>
    <figure class="mx-2 cursor-pointer relative group" @click="$emit('clicked')">
        <img class="h-12 w-12 object-cover rounded" :src="image.source" :alt="image.name">
        <div v-if="useIcon" class="w-5 h-5 absolute bottom-full left-full opacity-0 group-hover:opacity-100 transition transform -translate-x-1/2 translate-y-1/2 rounded-full flex items-center justify-center bg-gray-200">
            <i class="text-sm text-gray-600 fas fa-times"></i>
        </div>
    </figure>
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            required: true
        },
        useIcon: {
            required: true,
            type: Boolean
        }
    }
}
</script>

<style>

</style>