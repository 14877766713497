<template>
    <button class="flex items-center py-4 pl-6 md-select group focus:outline-none" @click="$emit('clicked')">
        <div class="h-5 w-5 mr-2.5 rounded-full text-blue-600 group-hover:text-white bg-transparent group-hover:bg-blue-600 flex items-center justify-center transition">
            <i class="fal fa-plus text-[14px]"></i>
        </div>
        <span class="ml-px font-light text-blue-600">{{ $t('pages.list.task.add') }}</span>
    </button>
</template>

<script>
export default {

}
</script>