<template>
  <div>
    <v-spa-loader v-if="loader.activities.loading" />
    <div v-else class="flex flex-col">
      <span class="mb-10 text-4xl">Activities</span>
      <ul class="mb-4 border-l border-gray-200">
            <single-activity
                v-for="activity in activities"
                :key="activity.uuid"
                :activity="activity"
            />
      </ul>
    </div>
  </div>
</template>

<script>
import helpers from '~base/helpers';
import models from "~base/models";
import SingleActivity from "~base/components/ActivityFeed/Activity.vue";

export default {
    components: {
        SingleActivity
    },
    data() 
    {
        return {
            loader: {
                activities: new helpers.loader()
            },
            activities: []
        }
    },
    methods: {
        async setActivities()
        {
            try {
                this.activities = await models.activityFeed.index();
            } catch (error) {
                console.error('error while getting feed');
            }
        },
        getActivities()
        {
            this.loader.activities.loadTill(this.setActivities);
        }
    },
    async created()
    {
        this.getActivities();
    }
}
</script>