<template>
    <element-picker
        @input="colors => $emit('input', colors)"
        :value="value"
        track-by="id"
        :elements="colors"
        :multiple="false"
        v-slot="{ deleteElement, addElement, elements, selected }"
    >
        <div class="flex flex-col">
            <ul class="flex py-2">
                <single-picker-element
                    :element="color"
                    :delete="deleteElement" 
                    :add="addElement" 
                    :selected="selected" 
                    :key="color.id" 
                    v-for="color in elements.all"
                >
                    <div class="h-full w-full rounded-full" :style="{ backgroundColor: color.value }"></div>
                </single-picker-element>
            </ul>
        </div>
    </element-picker>
</template>

<script>
import ElementPicker from "~base/components/_Partials/ElementPicker.vue";
import SinglePickerElement from "~base/components/_Partials/PickerElement.vue";

export default {
    props: {
        value: {
            required: true
        },
        colors: {
            type: Array,
            required: true
        }
    },
    components: {
        ElementPicker,
        SinglePickerElement
    }
}
</script>

<style>

</style>