<template>
    <div class="flex px-1 relative" v-click-outside="stopUserSelection">
        <slot 
            name="selectedUsers" 
            :toggle="toggleUserSelection" 
            :selected="selectedUsers"
            :tooltip="tooltip"
            :limit="limit.users"
        >
            <div :data-tooltip="tooltip.data" :data-tooltip-location="tooltip.location" class="flex md:justify-end md:w-full">
                <user-list :users="selectedUsers" :limit="limit.users" @click.native.stop="toggleUserSelection" />
            </div>
        </slot>
        <div 
            class="fixed inset-0 md:absolute z-[60] md:right-0 md:top-full md:left-auto md:bottom-auto md:w-[260px] md:mt-2 bg-white md:shadow md:rounded p-8 md:p-4" 
            v-if="field.editing"
        >
            <modal-close-icon @close="stopUserSelection" />
            <div class="flex h-full md:h-auto flex-col justify-center md:justify-start">
                <user-picker :users="users" v-model="selectedUsers" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserPicker from "~base/components/_Partials/UserPicker.vue";
import UserList from "~base/components/_Partials/UserList.vue";
import ModalCloseIcon from "~base/components/_Partials/Modals/CloseIcon.vue";

export default {
    props: {
        field: {
            required: true,
            type: Object
        },
        tooltip: {
            required: true,
            type: Object
        },
        ifEmpty: { required: false, type: Array, default: () => [] } 
    },
    data() {
        return {
            limit: {
                users: 3
            }
        }
    },
    components: {
        UserPicker,
        UserList,
        ModalCloseIcon
    },
    methods: {
        stopUserSelection()
        {
            if(!this.field.editing) return;

            this.field.save();
            this.$emit('stopUserSelection');
        },
        toggleUserSelection()
        {
            this.field.editing
                ? this.field.save()
                : this.field.start();
        },
        isUserSelected(user) {
            return this.field.model.includes(user.id);
        },
        isConnectedUser(user) {
            return this.user.id === user.id
        },
        sortingUsers(userA, userB)
        {
            const userASelected = this.isUserSelected(userA);
            const userBSelected = this.isUserSelected(userB);
            
            return userASelected && userBSelected
                ? this.isConnectedUser(userA)
                    ? -1
                    : this.isConnectedUser(userB)
                        ? 1
                        : userA.first_name < userB.first_name
                            ? -1
                            : 1
                        : userASelected
                            ? -1
                            : userBSelected
                                ? 1
                                : this.isConnectedUser(userA)
                                    ? -1
                                    : this.isConnectedUser(userB)
                                        ? 1
                                        : userA.first_name < userB.first_name
                                            ? -1
                                            : 1;
        }
    },
    computed: {
        ...mapState({
            users(state) { return [...state.users].sort(this.sortingUsers) },
            user: state => state.user
        }),
        selectedUsers: 
        {
            get()
            {
                return this.users.filter(u => this.field.model.includes(u.id));
            },
            set(new_users)
            {
                let users = new_users;
                if (!users.length) users = this.ifEmpty;
                this.field.model = users.map(u => u.id);
                this.users.sort(this.sortingUsers);
                this.stopUserSelection();
            }
        }
    }
}
</script>

<style>

</style>