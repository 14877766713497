<template>
  <div>
    <list-header
      :shareable="shareable"
      @addGroup="addGroup"
      @toggleLocked="toggleLocked"
      @toggleSorting="$refs.sortingsSubmenu.show()"
      @toggleFilters="$refs.filtersSubmenu.show()"
      @toggleFavorite="toggleFavorite"
      @toggleArchive="toggleArchived"
      @toggleShare="toggleDisplayShareButton"
    /> 
    <div class="flex items-center overflow-visible group" :class="{'border-b border-gray-200 px-6 lg:px-12 py-4 md:py-0': asPage}">
        <slot name="title"
          :setModel="({ target: { value } }) => updater.fields.title.model = value"
          :model="updater.fields.title.model" 
          :editing="updater.fields.title.editing" 
          :start="() => updater.fields.title.start()" 
          :save="() => updater.fields.title.save()" 
          :cancel="() => updater.fields.title.cancel()"
        >
          <span class="text-xl" v-if="!updater.fields.title.editing">{{ updater.fields.title.model }}</span>
          <input
            type="text"
            class="py-0.5 px-[3px] !text-xl leading-none border bg-white border-dashed border-gray-600"
            v-else
            v-focus
            v-model="updater.fields.title.model"
            @keydown.enter.prevent="() => updater.fields.title.save()"
            @keydown.escape.prevent="() => updater.fields.title.cancel()"
            @blur="() => updater.fields.title.save()"
          />
        </slot>
        <slot name="title-icons">
          <div class="flex items-center justify-end flex-auto md:justify-between" v-if="hasUuid">
            <i class="block text-lg text-gray-400 cursor-pointer md:hidden far fa-ellipsis-h" @click="modals.listHeader.show({ shareable })"></i>
            <div class="items-center hidden md:flex">
              <button 
                data-tooltip-location="bottom" :data-tooltip="lockedTooltip"
                class="focus:outline-none" @click.stop="toggleLocked" v-if="useLock">
                <i class="pl-4 pr-2 text-gray-600" :class="lockedIconClasses"></i>
              </button>
              <button 
                data-tooltip-location="bottom" :data-tooltip="favoriteTooltip"
                class="focus:outline-none" @click="toggleFavorite" v-if="useFavorite">
                <i class="fas fa-star text-[18px] px-2" :class="favoriteClasses"></i>
              </button>
              <button 
                data-tooltip-location="bottom" :data-tooltip="archiveTooltip"
                class="focus:outline-none" @click.stop="toggleArchived" v-if="useArchive">
                <i class="px-2 text-gray-600" :class="archiveIconClasses"></i>
              </button>
              <button 
                data-tooltip-location="bottom" :data-tooltip="$t('pages.list.header.delete')"
                class="focus:outline-none" @click="modals.delete.show({ item: list, callback: deleteList })" v-if="useDelete">
                <i class="px-2 text-gray-600 opacity-0 far fa-trash-alt group-hover:opacity-100"></i>
              </button>
            </div>
            <div class="">
              <share-list-button
                v-if="shareable && displayShareButton"
                :field="updater.fields.user_ids"
                @stopUserSelection="toggleDisplayShareButton"
              />
            </div>
          </div>
        </slot>
        <div class="flex flex-col md:mb-5 md:mt-7" v-if="useGroups && hasUuid">
        <div class="flex -mr-2.5">
            <button 
                class="hidden md:block px-2.5 md:px-4 py-1.5 md:py-2.5 bg-primary hover:bg-primary-dark text-white text-sm md:text-base rounded transition focus:outline-none flex items-center" 
                @click="addGroup"
            >
                <i class="mr-1 text-sm far fa-stream"></i>
                <span class="hidden md:inline">
                    {{ $t("pages.list.header.add_group") }}
                </span>
            </button>
            <div class="flex flex-col px-2.5">
                <div class="flex h-full">
                    <v-spa-submenu-container right-panel-portal="sortings-submenu" modal-name="sortings-submenu-modal" :use-right-panel-on-small-screens="true" position="right-0" ref="sortingsSubmenu">
                        <template v-slot:default="slotProps">
                            <div class="flex items-center h-full">
                                <button class="hidden md:block px-2.5 py-1 flex items-center text-gray-600 hover:text-gray-800 transition focus:outline-none" @click="slotProps.show()">
                                    <i class="fas fa-sort-amount-down"></i>
                                    <span class="hidden pl-1 md:inline">{{ $t("pages.list.header.sort") }}</span>
                                </button>
                            </div>
                        </template>
                        <div class="w-[200px]" slot="submenu">
                            <list-orders></list-orders>
                        </div>
                    </v-spa-submenu-container>
                    <v-spa-submenu-container right-panel-portal="filters-submenu" modal-name="filters-submenu-modal" :use-right-panel-on-small-screens="true" position="right-0" ref="filtersSubmenu">
                        <template v-slot:default="slotProps">
                            <div class="flex items-center h-full">
                                <button class="hidden md:block px-2.5 py-1 flex items-center text-gray-600 hover:text-gray-800 transition focus:outline-none" @click="slotProps.show()">
                                    <i class="fas fa-filter"></i>
                                    <span class="hidden pl-1 md:inline">{{ $t("pages.list.header.filter") }}</span>
                                </button>
                            </div>
                        </template>
                        <div class="w-[550px] p-2" slot="submenu">
                            <list-filters></list-filters>
                        </div>
                    </v-spa-submenu-container>
                </div>
            </div>
        </div>
        </div> 
    </div>
    <div class="px-6 pt-6 lg:pt-12 lg:px-12" v-if="(activeNonDefaultFilters.length || !this.isDefaultOrder)">
        <div class="flex flex-wrap -mx-2">
        <list-active-order :small-version="true" />
        <list-filter
            v-for="filter in activeNonDefaultFilters"
            :small-version="true"
            :key="filter.id"
            :filter="filter"
        />
        </div>
    </div>
    <div class="pb-6 md:pb-7" :class="{ 'px-6 pt-6 lg:pt-12 lg:px-12': asPage, 'pt-6 md:pt-7' : !useGroups }" v-if="havingGrouplessAddTaskButton || havingGrouplessTasks">
      <ul class="flex flex-col" v-if="havingGrouplessTasks">
        <draggable-list 
            class="flex flex-col"
            group="tasks"
            v-model="draggableTasks"
            ghost-class="draggable__moving_element"
            :animation="300"
            :handle="`.${draggable.handle.task}`"
            :disabled="disableDrag || isEditingSomething || havingNotStoredTask"
            :delay="300"
            :delayOnTouchOnly="true"
        >
          <task-item
            v-for="(task, i) in tasks"
            :key="task.uuid"
            :task="task"
            :index="i"
            :disable-drag="disableDrag || isEditingSomething || havingNotStoredTask"
            @deleteTask="deleteGrouplessTask"
            @updateTask="$emit('updateTask', { task })"
            @addTask="addGrouplessTask"
            @refreshTask="refreshTask"
          />
        </draggable-list>
      </ul>
      <add-task-button @clicked="addGrouplessTask" v-if="havingGrouplessAddTaskButton && hasUuid" />
    </div>
    <div :class="{ 'px-6 pt-6 lg:pt-12 lg:px-12': asPage}">
      <ul class="flex flex-col">
        <draggable-list 
          class="flex flex-col"
          group="groups"
          v-if="useGroups"
          v-model="draggableGroups"
          ghost-class="draggable__moving_element"
          :animation="300"
          :handle="`.${draggable.handle.group}`"
          :disabled="isEditingSomething || disableDrag"
          :delay="300"
          :delayOnTouchOnly="true"
        >
          <task-group
            v-for="(group, i) in list.ordered_groups"
            :key="group.uuid"
            :group="group"
            :index="i"
            :disable-drag="disableDrag"
            @delete="deleteGroup"
            @refreshGroup="refreshGroup"
          />
        </draggable-list>
      </ul>
    </div>
    <prevent-filters />
    <undo-action />
  </div>
</template>

<script>
import moment from "moment";
import DraggableList from "vuedraggable";
import ClickOutside from 'v-click-outside';
import { mapActions, mapGetters, mapState } from "vuex";
import models from "~base/models";
import Updater from "~base/classes/Models";
import TaskItem from "~base/components/TaskList/Show/Task.vue";
import TaskGroup from "~base/components/TaskList/Show/Group.vue";
import PreventFilters from "~base/components/TaskList/Show/Partials/List/PreventFilters.vue";
import UndoAction from "~base/components/TaskList/Show/Partials/List/Undo.vue";
import AddTaskButton from "~base/components/TaskList/Show/Partials/AddTask.vue";
import ListOrders from "~base/components/TaskList/Show/Partials/List/Orders.vue";
import ListActiveOrder from "~base/components/TaskList/Show/Partials/List/Orders/ActiveOrder.vue";
import ListFilters from "~base/components/TaskList/Show/Partials/List/Filters.vue";
import ListFilter from "~base/components/TaskList/Show/Partials/List/Filters/Filter.vue";
import ShareListButton from "~base/components/TaskList/Show/Partials/List/ShareButton.vue";
import ListHeader from "~base/components/Modals/ListHeader.vue";
import directives from "~base/directives";
import { types } from '~base/store';
import modals from "~base/components/Modals/Config"
import { getterTypes } from '~base/store/getters';
import helpers from '~base/helpers';
import { actionTypes } from '~base/store/actions';

export default {
  props: {
    asPage: {
        type: Boolean,
        default: false,
    },
    useGroups: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    useAddTask: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    useFavorite: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    useLock: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    useArchive: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    useDelete: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    useShare: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    disableDrag: {
      type: Boolean,
      required: false,
      default: false
    },
    list: {
        type: Object,
        required: true
    },
    taskKey: {
      type: String,
      required: true
    }
  },
  components: {
    TaskItem,
    ShareListButton,
    TaskGroup,
    AddTaskButton,
    DraggableList,
    ListOrders,
    ListActiveOrder,
    ListFilters,
    ListFilter,
    ListHeader,
    PreventFilters,
    UndoAction
  },
  directives: {
    focus: directives.focus,
    clickOutside: ClickOutside.directive
  },
  data() {
    return {
      updater: {
        handler: {},
        fields: {
          title: {},
          locked_at: {},
          archived_at: {},
          user_ids: {}
        },
      },
      debounce: {
        filters: null
      },
      modals: modals.apply(this),
      display: {
        share_button: false
      }
    };
  },
  methods: {
    ...mapActions([
      "list_update", 
      'task_counters_update_task_stored',
      'orders_hide', 'orders_toggle',
      'filters_hide', 'filters_toggle'
    ]),
    stored()
    {
      return this.list.uuid ? true : false;
    },
    handleCancel()
    {
      if (!this.stored()) this.deleteList() 
    },
    async updateList({ field }) {
      try {
        const { title } = this.updater.fields;
        // if trying to save empty  title
        if (field === 'title' && title.model === '') return title.cancel();
        // if list not persisted yet, persist it
        if (!this.stored()) return this.storeList();
        const list = await models.taskList.update(this.list, { field, user: this.minimalUser });
        console.log("updated");
      } catch (error) {
        console.error("cannot update");
        throw error;
      }
    },
    async storeList()
    {
      const list = await models.taskList.store(this.list, { user: this.minimalUser });
      this.list.uuid = list.uuid;
      this.list_update(list);
      // Replace url (sending preventLoading avoid database hit since line just above set database values to list)
      this.$router.replace({ name: 'account.lists.show', params: { list: list.uuid, preventLoading: true } })
    },
    async deleteList() {
      try {
        this.deleteListFromStore(this.list);
        this.$router.replace({
          name: "account.homepage",
        });

        if (this.stored()) {
          const list = await models.taskList.delete(this.list, { 
            options: { relations: true },
            user: this.minimalUser
           });
          console.log("deleted");
        }
      } catch (error) {
        console.error("cannot delete");
        throw error;
      }
    },
    setUpdater() {
      this.updater.handler = new Updater(this.list);
      const { handler, fields } = this.updater;
      Object.keys(fields).forEach(key => {
        const field = handler.field({
          name: key,
          callbacks: {
            save: this.updateList.bind(this, { field: key }),
            start: this.setListEditing.bind(this, true),
            stop: this.setListEditing.bind(this, false),
            cancel: this.handleCancel
          }
        });
        fields[key] = field; 
      });
    },
    setListEditing(value)
    {
      this.$store.dispatch(types.actions.doing.SET_LIST_EDITING, value);
    },
    async addGrouplessTask() {
      const task = models.task.new(
        { user: this.minimalUser },
        { 
          task_list_id: this.list.id, 
          order: this.tasks.length 
            ? this.tasks.reduce((max,t) => Math.max(max, t.order), 0) + 1
            : 0
        }
      );
      this.task_counters_update_task_stored({ model: task.due_date, isDone: task.is_done });
      this.tasks.push(task);
    },
    deleteGrouplessTask(uuid) {
      const index = this.tasks.findIndex((t) => t.uuid === uuid);
      if (index !== -1) {
        this.tasks.splice(index, 1);
      }
    },
    // forcing update for tasks to avoid references errors
    refreshTask({ task }) {
      const index = this.tasks.findIndex(({ uuid }) => uuid === task.uuid);
      // Making sure we're not trying to update a task without uuid yet
      if (index === -1) {
          const notStoredIndex = this.tasks.findIndex(({ uuid }) => !uuid);
          if (notStoredIndex === -1) return console.log({ uuid: task.uuid }, 'couldn"t find a task to update')
          return this.tasks.splice(notStoredIndex, 1, task);
      }
      this.tasks.splice(index, 1, task);
    },
    // forcing update for freshly created task
    refreshGroup({ group, index }) {
      const actualGroup = this.list.ordered_groups[index] ?? null;
      if (actualGroup) this.list.ordered_groups.splice(index, 1, group);
    },
    async addGroup() {
      const group = models.taskGroup.new(
        { user: this.minimalUser },
        { 
          task_list_id: this.list.id, 
          order: this.list.ordered_groups
            ? this.list.ordered_groups.reduce((max, g) => Math.max(max, g.order), 0) + 1
            : 0
        }
      );
      this.list.ordered_groups.push(group);
    },
    async deleteGroup(uuid) {
      const index = this.list.ordered_groups.findIndex((g) => g.uuid === uuid);
      if (index !== -1) {
        this.list.ordered_groups.splice(index, 1);
      }

      if (uuid) {
        const group = await models.taskGroup.delete({ uuid }, {
          options: { tasks: true },
          user: this.minimalUser
        });
      }
    },
    onClickOutsideSortings()
    {
      if (this.displaySortings && !this.screenSmallerThanMd) this.orders_hide();
    },
    onClickOutsideFilters()
    {
      if (this.displayFilters && !this.screenSmallerThanMd) this.filters_hide();
    },
    async toggleFavorite()
    {
        const favorite_at = this.isFavorite ? null : moment()
        const updater = new Updater(this.list);
        const field = updater.field({
          name: 'current_user_filters',
          callbacks: { save: this.saveFavorite }
        });
        const is_update = this.isListHavingFilters;
        field.start().model = is_update
          ? { ...this.list.current_user_filters, favorite_at }
          : models.taskListUserFilter.new({ user: this.minimalUser, list: this.list }, { favorite_at });
        field.save({ favorite_at, is_update });
    },
    async saveFavorite(list, { favorite_at, is_update }) {
      const filter = await (
            is_update
                ? models.taskListUserFilter.update({ 
                    filter: {
                        favorite_at,
                        uuid: list.current_user_filters.uuid
                    },
                    field: "favorite_at",
                    user: this.minimalUser 
                })
                : models.taskListUserFilter.store({
                  filter: { 
                    favorite_at,
                    task_list_id: list.id,
                    user_id: this.minimalUser.id
                  },
                  user: this.minimalUser 
                })
        );
        this.list_update({ ...list, current_user_filters: filter });
    },
    async toggleLocked()
    {
      const { user_ids, locked_at } = this.updater.fields;
        const locked_at_new = this.list.locked_at ? null : moment()
        const user_ids_new = locked_at_new ? [this.minimalUser.id] : [];
        const user_ids_old = user_ids.model;
        locked_at.start().model = locked_at_new;
        user_ids.model = user_ids_new;
        try {
          await locked_at.save();
        } catch (err) {
          console.error(err);
          user_ids.model = user_ids_old;
        }
    },
    async toggleArchived() {
      const { archived_at } = this.updater.fields;
      const was_archived = archived_at.model;
      const is_archived = was_archived ? null : moment();
      archived_at.start().model = is_archived;
      const [, err] = await helpers.try(archived_at.save());
      if (err) return console.error("could not update archived_at");
      this.updateArchivesCount({ is_archived, was_archived })
    },
    toggleDisplayShareButton() {
      this.display.share_button = !this.display.share_button
    },
    addListToStore(list) {
      this.$store.dispatch(actionTypes.lists.store, list);
    },
    deleteListFromStore(list) {
      this.$store.dispatch(actionTypes.lists.delete, list);
    },
    updateArchivesCount({ is_archived, was_archived }) {
      this.$store.dispatch(actionTypes.lists.UPDATE_ARCHIVES_COUNT, { is_archived, was_archived });
    },
  },
  computed: {
    ...mapGetters(['minimalUser', 'activeNonDefaultFilters']),
    ...mapState(['user', 'draggable']),
    isEditingSomething()
    {
        return this.$store.getters[ types.getters.doing.IS_EDITING];
    },
    screenSmallerThanMd() {
        return this.$store.getters[getterTypes.screens.IS_SMALLER_THAN_MD];
    },
    selectedOrder() {
      return this.$store.getters[getterTypes.orders.SELECTED];
    },
    isDefaultOrder() {
      return this.$store.getters[getterTypes.orders.IS_DEFAULT_ORDER];
    },
    tasks: {
      get()
      {
        return this.list[this.taskKey];
      },
      set(value)
      {
        this.list[this.taskKey] = value;
      }
    },
    displaySortings()
    {
      return this.$store.state.orders.display;
    },
    displayFilters()
    {
      return this.$store.state.filters.display;
    },
    draggableTasks: {
        get() 
        {
            return this.tasks;
        },
        set(tasks) 
        {
            const bulk = [];
            // Making sure tasks reflect parent groupless task model
            tasks.forEach((parent, index) => {
              const { parent_id, task_group_id, order } = parent;
              const updateNeeded = parent_id !== null || task_group_id !== null || order !== index;
              if (updateNeeded) {
                parent.task_group_id = null;
                parent.parent_id = null;
                parent.order = index;
                bulk.push({ uuid: parent.uuid, parent_id: parent.parent_id, task_group_id: parent.task_group_id, order: parent.order })
              }
            });
            if (bulk.length) models.task.bulkUpdate(bulk);
            // setting value to formated one
            this.tasks = tasks;
        }
    },
    draggableGroups: {
        get() 
        {
            return this.list.ordered_groups;
        },
        set(groups) 
        {
            const bulk = [];
            // Making sure groups reflect parent groupless task model
            groups.forEach((group, index) => {
              const { order, uuid } = group;
              const updateNeeded = order !== index;
              if (updateNeeded) {
                group.order = index;
                bulk.push({ uuid, order: group.order })
              }
            });
            if (bulk.length) models.taskGroup.bulkUpdate(bulk);
            // setting value to formated one
            this.list.ordered_groups = groups;
        }
    },
    // used to disable drag and drop when editing
    havingNotStoredTask()
    {
      return this.tasks.find(t => !t.uuid) ? true : false;
    },
    havingGrouplessTasks()
    {
      return this.tasks.length > 0;
    },
    // used to hide add task button when having groups
    havingGroups()
    {
      const { ordered_groups } = this.list
      return ordered_groups && ordered_groups.length > 0;
    },
    havingGrouplessAddTaskButton()
    {
      return this.useAddTask && !this.havingGroups;
    },
    shareable() {
      if (!this.useShare) return false;
      if (this.updater.fields.user_ids.editing) return true;
      if(models.taskList.public(this.list)) return false;

      return true;
    },
    hasUuid() {
      return this.list.uuid;
    },
    displayShareButton() {
      if (!this.screenSmallerThanMd) return true;
      return this.display.share_button;
    },
    isFavorite() {
      return !!this.list?.current_user_filters?.favorite_at;
    },
    isLocked() {
      return !!this.list.locked_at; 
    },
    isArchived() {
      return !!this.list.archived_at; 
    },
    lockedIconClasses()
    {
      return { 
        'far fa-lock-alt': this.isLocked,
        'far fa-lock-open-alt': !this.isLocked
      }
    },
    lockedTooltip()
    {
      return !!this.isLocked
        ? this.$t('pages.list.header.lock.active')
        : this.$t('pages.list.header.lock.disabled')
    },
    favoriteClasses()
    {
      return {
        'text-gray-600': !this.isFavorite,
        'text-[#ffc107]': this.isFavorite
      }
    },
    favoriteTooltip()
    {
      return this.isFavorite
        ? this.$t('pages.list.header.favorite.active')
        : this.$t('pages.list.header.favorite.disabled')
    },
    archiveIconClasses() {
      return {
        "far fa-box-alt text-[18px]": this.isArchived,
        "far fa-box-open text-[18px]": !this.isArchived,
      }
    },
    archiveTooltip()
    {
      return this.isArchived
        ? this.$t('pages.list.header.archive.active')
        : this.$t('pages.list.header.archive.disabled')
    },
    isListHavingFilters() {
      return !!this.list?.current_user_filters
    },
    isListPreventingFilters()
    {
      return this.$store.state.active_list_prevent_filters;
    },
  },
  watch: {
    selectedOrder: {
      immediate: true,
      handler(order) {
        this.tasks.sort(order.sort);
      }
    },
    isListPreventingFilters(prevent) {
      if (prevent) return;
      // stop preventing => sorting
      this.tasks.sort(this.selectedOrder.sort);
    },
    list() {
      this.setUpdater();
    }
  },
  created() {
    this.setUpdater()
    // Forcing task focus when adding task.
    if (!this.stored()) this.updater.fields.title.start().model = ''
  },
};
</script>

<style>
</style>