import users, { usersGetterTypes } from "./users";
import user, { userGetterTypes } from "./user";
import orders, { orderGetterTypes } from "./orders";
import filters, { filtersGetterTypes } from "./filters";
import lists, { ListGetterTypes } from "./lists";
import calendar from "./calendar";
import events from "./events";
import modal from "./modal";
import tailwind from "./tailwind";
import doing, { doingGetterTypes } from "./doing";
import screens, { screenGetterTypes } from "./screens";

export const getterTypes = {
    doing: doingGetterTypes,
    lists: ListGetterTypes,
    orders: orderGetterTypes,
    user: userGetterTypes,
    users: usersGetterTypes,
    screens: screenGetterTypes,
    filters: filtersGetterTypes
};

export default {
    ...users,
    ...user,
    ...orders,
    ...filters,
    ...calendar,
    ...lists,
    ...events,
    ...modal,
    ...tailwind,
    ...doing,
    ...screens,
    apiHeaders: state => {
        if ( ! state.token ) {
            return {};
        }

        return {
            'Authorization': state.token.token_type + ' ' + state.token.access_token
        };
    },
    appApiHeaders:(state) => {
        let headers = {
            'Content-Language': state.locale
        };
        if ( state.token ) {
            headers['X-TrustUp-Authorization'] = state.token.token_type + ' ' + state.token.access_token
        }
        return headers;
    },
    hasAccount: state => state.account ? true : false,
}