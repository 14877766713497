<template>
    <sidebar-element :to="{ name: 'account.today' }">
        <template v-slot:icon="icon">
            <i class="fal fa-calendar-day text-today-task" :class="icon._class"></i>
        </template>

        <template v-slot:text="text">
            <span :class="text._class">{{ $t("sidebar.menu.today") }}</span>
        </template>
        
        <template v-slot:numbers="numbers">
            <span class="text-gray-600" :class="numbers._class">{{ todayCounter }}</span>
        </template>
    </sidebar-element>
</template>

<script>
    import { mapState } from "vuex";
    import SidebarElement from "~base/components/_Layout/Sidebar/Section/Element.vue";

    export default {
        components: {
            SidebarElement
        },
        computed: {
            ...mapState(['counters']),
            todayCounter()
            {
                const { today } = this.counters;
                return today > 0 
                    ? today 
                    : null;
            }
        }
    }
</script>