import { api, route } from "~base/helpers";

export default {
    async index()
    {
        const { data } = await api.get({
            url: route('api.activity.index')
        });

        return data;
    }
}