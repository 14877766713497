var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleOutside),expression:"handleOutside"}],staticClass:"relative"},[_c('div',{staticClass:"pr-7 pl-2 py-1.5 text-gray-700 rounded border border-gray-200 relative",on:{"click":_vm.toggle}},[_c('span',[_vm._v(_vm._s(_vm.value))]),_vm._v(" "),_c('div',{staticClass:"absolute top-0 bottom-0 right-2 w-5 flex items-center justify-center cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('input', null)}}},[_c('i',{staticClass:"fa fa-times absolute right-0 text-sm text-gray-400"})])]),_vm._v(" "),(_vm.display.picker)?_c('element-picker',{attrs:{"track-by":"value","elements":_vm.options,"multiple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var toggleElement = ref.toggleElement;
        var elements = ref.elements;
        var selected = ref.selected;
        var highlightPreviousElement = ref.highlightPreviousElement;
        var highlightNextElement = ref.highlightNextElement;
        var highlight = ref.highlight;
        var hasHighlight = ref.hasHighlight;
        var isHighlighted = ref.isHighlighted;
return [_c('time-picker-list',{attrs:{"elements":elements.all,"select":toggleElement,"selected":selected,"highlightPrevious":highlightPreviousElement,"highlightNext":highlightNextElement,"highlighted":highlight,"hasHighlight":hasHighlight,"isHighlighted":isHighlighted}})]}}],null,false,2180330497),model:{value:(_vm.pickerValue),callback:function ($$v) {_vm.pickerValue=$$v},expression:"pickerValue"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }