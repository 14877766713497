const types = {
    SELECTED: "selectedOrder",
    IS_DEFAULT_ORDER: "ORDERS_IS_DEFAULT_ORDER",
    SELECTED_SAME_GROUP_ORDERS: "ORDERS_SELECTED_SAME_GROUP_ORDERS"
}

export default {
    [types.SELECTED]: ({ orders: { selected, all } }) => all.find(order => order.id === selected),
    [types.IS_DEFAULT_ORDER]: ({ orders }) => orders.selected === orders.default,
    [types.SELECTED_SAME_GROUP_ORDERS]: ({ orders }, getters) => orders.all.filter(
        o => o.group === getters[types.SELECTED]?.group && o.id !== getters[types.SELECTED]?.id
    ),
}

export { types as orderGetterTypes }