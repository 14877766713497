<template>
    <sidebar-element :to="{ name: 'account.archives' }">
        <template v-slot:icon="icon">
            <i class="fal fa-box-open text-gray-800" :class="icon._class"></i>
        </template>
        
        <template v-slot:text="text">
            <span :class="text._class">{{ $t("sidebar.menu.archives") }}</span>
        </template>
        
        <template v-slot:numbers="numbers">
            <span class="text-gray-600" :class="numbers._class">{{ archivesCount }}</span>
        </template>
    </sidebar-element>
</template>

<script>
import SidebarElement from "~base/components/_Layout/Sidebar/Section/Element.vue";
import models from '~base/models';
import { actionTypes } from '~base/store/actions';

    export default {
        components: {
            SidebarElement
        },
        methods: {
            async setArchivesCount() {
                const [count, err] = await models.taskList.archivesCount();
                if (err) return;
                this.$store.dispatch(actionTypes.lists.SET_ARCHIVES_COUNT, { count })
            },
        },
        computed: {
            archivesCount()
            {
                return this.$store.state.lists_archives_count || null;
            }
        },
        created() {
            this.setArchivesCount();
        }
    }
</script>