const types = {
    ACTIVE: "activeFilters",
    ARE_DEFAULT_FILTERS: "FILTERS_ARE_DEFAULT_FILTERS",
    ACTIVE_WITHOUT_DEFAULT: "activeNonDefaultFilters",
    ACTIVE_BY_GROUP: "activeFiltersByGroup",
    ALL_BY_GROUPS: "groupFilters",
    DEFAULTS: "defaultFilters"
};

export default {
    [types.ACTIVE]: ({ filters }) => {
        return filters.active.map(id => {
            const filter = filters.all.find(f => f.id === id);
            const group = filters.groups.find(g => g.id === filter.group_id);
            return {
                ...filter,
                group
            };
        });
    },
    [types.ARE_DEFAULT_FILTERS]({ filters }) {
        if (filters.active.length !== filters.default.length) return false;
        return filters.active.findIndex(f => !filters.default.includes(f)) === -1;
    },
    [types.ACTIVE_WITHOUT_DEFAULT]({ filters }, { activeFilters })
    {   
        return activeFilters.filter( ({ id }) => !filters.default.includes(id));
    },
    [types.ACTIVE_BY_GROUP](state, { activeFilters })
    {
        return Object.values(activeFilters.reduce((groups, f) => {
            if (groups[f.group_id]) groups[f.group_id].push(f);
            else groups[f.group_id] = [f];
            return groups
        }, {}));
    },
    [types.ALL_BY_GROUPS]: ({ filters }) => filters.groups.map(group => ({
        group,
        filters: filters.all.filter(f => f.group_id === group.id)
    })),
    [types.DEFAULTS]: ({ filters }) => filters.default.map(id => filters.all.find(f => f.id === id)),      
}

export { types as filtersGetterTypes };