const fromObject = ( element, namespace = '', data = new FormData() ) => {
    for ( const key in element ) {
        const value = element[key];
        const isObjectOrArray = typeof value === 'object' && value !== null;
        const isFile = value instanceof File;
        const currentNamespace = namespace ? `${namespace}[${key}]` : key;
        if ( isObjectOrArray && ! isFile ) {
            fromObject(value, currentNamespace, data)
        } else {
            data.append(currentNamespace, value);
        };
    }

    return data;
};

const formData = { fromObject };
export { fromObject };

export default formData;