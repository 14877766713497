<template>
    <sidebar-element :to="{ name: 'account.late' }">
        <template v-slot:icon="icon">
            <i class="fal fa-alarm-exclamation text-late-task" :class="icon._class"></i>
        </template>
        
        <template v-slot:text="text">
            <span :class="text._class">{{ $t("sidebar.menu.late") }}</span>
        </template>
        
        <template v-slot:numbers="numbers">
            <span class="text-late-task" :class="numbers._class">{{ lateCounter }}</span>
        </template>
    </sidebar-element>
</template>

<script>
    import { mapState } from "vuex";
    import SidebarElement from "~base/components/_Layout/Sidebar/Section/Element.vue";

    export default {
        components: {
            SidebarElement
        },
        computed: {
            ...mapState(['counters']),
            lateCounter()
            {
                const { late } = this.counters;
                return late > 0 
                    ? late 
                    : null;
            }
        }
    }
</script>