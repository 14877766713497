import axios from 'axios';

export class Api {

    constructor(config = {})
    {
        this.setConfig(config);
    }

    setConfig({ params = {}, url = '', headers = {} })
    {
        this.config = {
            params,
            url,
            headers: { 
                "X-Requested-With": "XMLHttpRequest",
                ...headers
            }
        }
        
        return this;
    }

    makeRequest({ method, url, params = {}, data = {}, headers = {} })
    {
        return axios({
            method,
            url: this.getUrl(url),
            params: { ...this.config.params, ...params },
            data,
            headers: { ...this.config.headers, ...headers },
        })
        .then(resp => resp.data);
    }

    getUrl(url)
    {
        if (url.search('http') === 0) return url;
        if (url[0] === '/') {
            const slashIndex = this.config.url.indexOf('/');
            if (slashIndex > -1) return this.config.url.slice(0, slashIndex) + url
        }

        return `${this.config.url}/${url}`;
    }

    get( config )
    {
        return this.makeRequest( { ...config, method: 'get' } );
    }

    post( config )
    {
        return this.makeRequest( { ...config, method: 'post' } );
    }

    put( config )
    {
        return this.makeRequest( { ...config, method: 'put' } );
    }

    delete( config )
    {
        return this.makeRequest( { ...config, method: 'delete' } );
    }

}

export default new Api();