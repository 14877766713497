const debounce = (callback, delay) => {
    let timer;
    let context;
    let args;
    const cancel = () => clearTimeout(timer);
    const launchCallback = () => callback.apply(context, args);
    const setTimer = () => timer = setTimeout(() => launchCallback(), delay);
    const force = () => {
        cancel();
        launchCallback();
    }
    
    let debounced = function() {
        context = this;
        args = arguments;
        cancel();
        setTimer();
    }

    debounced.cancel = cancel;
    debounced.force = force;

    return debounced;
}

export default debounce;