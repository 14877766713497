<template>
    <li class="py-2 first:pt-4 last:pb-4 md:px-6 flex w-full items-center justify-between group cursor-pointer" :class="containerClasses" @click="selectOrder">
        <div class="flex items-center">
            <!-- <div class="flex items-center justify-center h-5 w-5 mr-2">
                <i class="text-sm group-hover:text-primary transition" :class="order.icon"></i>
            </div> -->
            <span class=" group-hover:text-primary whitespace-nowrap transition">{{ order.group_display }}</span>
        </div>
        <i class="pl-4 text-primary far fa-check" :class="{ 'opacity-0' : !isActive, 'opacity-100': isActive }"></i>
    </li>
</template>

<script>
import { mapGetters } from 'vuex';
import models from '~base/models';
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';
export default {
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    methods: {
        setSelectedOrder(selected) {
            this.$store.dispatch(actionTypes.orders.SELECT, selected ); 
        },
        hideMenu() {
            this.$store.dispatch(actionTypes.orders.HIDE);
        },
        async selectOrder()
        {
            const order = this.isActive ? { id: "custom" } : { id: this.order.id };
            this.setSelectedOrder(order);
            this.hideMenu();
            const payload = {
                user_id: this.minimalUser.id,
                sorting: order.id,
                task_list_id: this.list.id
            };
            const filter = await (
                this.isListHavingFilters
                    ? models.taskListUserFilter.update({ 
                        filter: {
                            ...payload,
                            uuid: this.list.current_user_filters.uuid
                        },
                        field: "order",
                        user: this.minimalUser 
                    })
                    : models.taskListUserFilter.store({ filter: payload, user: this.minimalUser })
            );
            this.updateList({ ...this.list, current_user_filters: filter });
        },
        updateList(list) {
            this.$store.dispatch(actionTypes.lists.update, list)
        }
    },
    computed: {
        ...mapGetters(['minimalUser']),
        isActive()
        {
            return this.order.id === this.selectedId;
        },
        containerClasses()
        {
            return {
                'text-gray-600': !this.isActive,
                'text-primary': this.isActive,
            }
        },
        selectedId()
        {
            return this.$store.state.orders.selected;
        },
        isListHavingFilters() {
            return !!this.list?.current_user_filters
        },
        list() {
            return this.$store.getters[getterTypes.lists.active];
        },
    }
}   
</script>

<style>

</style>