<template>
    <v-spa-layout-page :loading="loaders.lists.loading">
        <template slot="title">{{ $t("pages.archives.title") }}</template>
        <template slot="content">
            <empty-archives v-if="! hasLists"></empty-archives>
            <div v-else class="flex flex-col">
                <div class="flex flex-col" v-if="hasLists">
                    <div
                        v-for="list in lists"
                        :key="list.uuid"
                        class="flex items-center mb-2"
                    >
                        <span class="text-xl">{{ list.title }}</span>
                        <i
                            class="p-2 ml-2 cursor-pointer fas fa-external-link-alt text-primary hover:text-primary-dark"
                            @click="$router.push({ name: 'account.lists.show', params: { list: list.uuid } })"
                        ></i>
                    </div>
                </div>
            </div>
        </template>
    </v-spa-layout-page>
</template>

<script>

import models from "~base/models";
import TaskList from "~base/components/TaskList/Show/Partials/List.vue";
import EmptyArchives from "~base/pages/_includes/EmptyArchives.vue";
import helpers from '~base/helpers';

export default {
  components: {
    TaskList,
    EmptyArchives
  },
  data() {
    return {
      loaders: { lists: new helpers.loader(true) },
      lists: []
    };
  },
  methods: {
    async setLists() {
      const [lists, err] = await models.taskList.archives();
      if (err) return;
      this.lists = lists;
    },
    initLists() {
      this.loaders.lists.loadTill(this.setLists);
    }
  },
  computed: {
    hasLists() {
      return this.lists.length;
    }
  },
  created() 
  {
    this.initLists();
  },
};
</script>