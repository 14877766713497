const types = {
    MINIMAL_USER: "minimalUser"
}

export default {
    [types.MINIMAL_USER]({ user })
    {
        const { avatar, first_name, id } = user

        return { avatar, first_name, id };
    }
}

export { types as userGetterTypes }