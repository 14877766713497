import TaskIndex from "~base/components/Tasks/Index"
import TaskListShow from "~base/pages/TaskList/Show";
import Archives from "~base/pages/Archives.vue";
import Today from "~base/pages/Today";
import Late from "~base/pages/Late";
import Calendar from "~base/pages/Calendar";
import AccountsIndex from "./components/Accounts/Index";
import AccountsBase from "./components/Accounts/Base";
import ActivityFeed from "~base/pages/ActivityFeed";
import Settings from "~base/pages/Settings";

import RouteGenerator from "@deegital/trustup-spa-js-assets/routing/routes"
const generator = new RouteGenerator();

const routes = [

    generator.create({
        path: '/',
        name: 'homepage',
        redirect: {
            name: 'accounts.index'
        }
    }).title('Tasks - TrustUp Pro').public().get(),
    
    generator.create({
        component: AccountsIndex,
    }).accounts().title('Tasks - TrustUp Pro').get(),

    generator.create({
        component: AccountsBase,
        redirect: {
            name: 'account.homepage'
        },
        children: [
            generator.create({
                path: 'home',
                name: 'account.homepage',
                redirect: {
                    name: 'account.today'
                }
            }).protected().get(),
    
            generator.create({
                path: 'lists/:list',
                name: 'account.lists.show',
                component: TaskListShow,
            }).protected().get(),

            generator.create({
                path: 'today',
                name: 'account.today',
                component: Today,
            }).protected().get(),

            generator.create({
                path: 'late',
                name: 'account.late',
                component: Late,
            }).protected().get(),

            generator.create({
                path: 'archives',
                name: 'account.archives',
                component: Archives,
            }).protected().get(),

            generator.create({
                path: 'calendar',
                name: 'account.calendar',
                component: Calendar,
            }).protected().get(),
            generator.create({
                path: 'activity',
                name: 'account.activity',
                component: ActivityFeed,
            }).protected().get(),
            generator.create({
                path: 'settings',
                name: 'account.settings',
                component: Settings,
            }).protected().get()
        ]
    }).protected().account().title('Tasks - TrustUp Pro').get()

];

export default routes;