class UpdateHandlerField
{
    constructor({ name, handler, callbacks = {} })
    {
        this.name = name;
        this.handler = handler;
        this.model = this.handler.model[this.name];
        this.setCallbacks(callbacks);
            // .setModel()
    }

    get dirty()
    {
        return this.persisted && this.persisted !== this.model ? true : false;
    }

    get persisted()
    {
        return this.handler.persisted[this.name];
    }

    get saving()
    {
        return this.handler.saving[this.name];
    }

    set persisted(value)
    {
        this.handler.persisted[this.name] = value;
    }

    get editing()
    {
        return this.handler.editing[this.name];
    }

    get model()
    {
        return this.handler.model[this.name];
    }

    set model(value)
    {
        this.handler.model[this.name] = value
    }

    setCallbacks(callbacks = {})
    {
        this.handler.setCallbacks(this.name, callbacks);

        return this;
    }

    // setModel()
    // {
    //     this.model = this.handler.model[this.name];

    //     return this;
    // }

    // setEditing()
    // {
    //     this.editing = this.handler.editing[this.name];

    //     return this;
    // }

    async save(...args)
    {
        return await this.handler.save(this.name, ...args);
    }

    start()
    {
        this.handler.start(this.name);
        
        return this;
    }

    stop()
    {
        this.handler.stop(this.name);
        
        return this;
    }

    cancel()
    {
        this.handler.cancel(this.name);

        return this;
    }
}

export default UpdateHandlerField;