import { route, api } from "~base/helpers";
export default {
    async store({ user, filter })
    {
        const { data } = await api.post({
            url: route('api.taskListUserFilters.store'),
            data: {
                filter,
                user
            }
        });

        return data;
    },

    async update({ user, filter, field })
    {
        const { data } = await api.put({
            url: route('api.taskListUserFilters.update', { taskListUserFilter: filter.uuid }),
            params: { field },
            data: {
                filter,
                user
            }
        });

        return data;
    },

    async bulkUpdate({ bulk })
    {
        const { data } = await api.put({
            url: route('api.taskListUserFilters.update.bulk'),
            data: { bulk }
        });

        return data;
    },

    new({ user, list }, overload) {
        return {
            sorting: 'custom',
            favorite_at: null,
            filters: [],
            order: null,
            favorite_order: null,
            user_id: user.id,
            task_list_id: list.id,
            ...overload
        }
    }
}