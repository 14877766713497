import axios from 'axios';
import qs from 'qs';

export default {

    getData(context, {url, urlParams = null, key = null, data = {}, method = 'GET', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                if ( ! key ) {
                    return resolve(response.data);
                }
                resolve(response.data[key]);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    postData(context, {url, urlParams = null, key, data = {}, method = 'POST', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    updateData(context, {url, urlParams = null, key, data = {}, method = 'PUT', extras = {}})
    {
        if ( urlParams ) {
            url = url + '?' + Object.keys(urlParams).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`).join('&');
        }

        return new Promise(function(resolve, reject) {
            axios({
                method: method,
                url: url,
                data: data
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while making a request.', {error: error, url: url, method: method, data: data});
                reject(error);
            });
        });
    },

    ping(context, {url, method = 'GET'})
    {
        return new Promise(function (resolve, reject) {
            axios({
                method: method,
                url: url
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while trying to ping the url.', {error: error, url: url, method: method});
                reject(error);
            });
        });
    },

    deleteData(context, {url, itemToDelete = null, array = null, key = 'id'})
    {
        return new Promise(function (resolve, reject) {
            axios({
                method: 'DELETE',
                url: url
            })
            .then((response) => {
                if ( array && key && itemToDelete ) {
                    context.dispatch('deleteItemInArray', {array: array, key: key, itemToDelete: itemToDelete});
                }
                resolve(response);
            })
            .catch((error) => {
                console.error('An error occured while trying to delete data.', {error: error, url: url, array: array, key: key});
                reject(error);
            });
        });
    },

    deleteItemInArray(context, {array, key, itemToDelete})
    {
        for ( let i = 0; i < array.length; i++ ) {
            if ( array[i][key] == itemToDelete ) {
                array.splice(i, 1);
            }
        }
    },

    setVariableInTitle(context, {variable, value})
    {
        if ( document.title.includes('{'+variable+'}') ) {
            document.title = document.title.replace('{'+variable+'}', value);
        };
    },

    makeAccountRequest(context, {url, urlParams = {}, method = 'GET', data = {}}) {
        return axios({
                method: method,
                params: urlParams,
                paramsSerializer: function(params) {
                    return qs.stringify(params, { strictNullHandling: true });
                },
                url: url,
                data: data,
                headers: context.getters.appApiHeaders
            })
            .then(response => response.data)
            .catch(error => { throw error.response.data; });
    },

    getAccountData(context, {url, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, method: 'GET', urlParams});
    },

    postAccountData(context, {url, data, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, data, method: 'POST', urlParams});
    },

    updateAccountData(context, {url, data, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, data, method: 'PUT', urlParams});
    },

    deleteAccountData(context, {url, urlParams = {}}) {
        return context.dispatch('makeAccountRequest', {url, method: 'DELETE', urlParams});
    }
}