import Base from './base';
import Auth from './auth';
import Accounts from './accounts';
import Professionals from './professionals';
import Tasks, { TasksActionTypes } from './tasks';
import Lists, { listsActionTypes } from './lists';
import Users, { usersActionTypes } from './users';
import Orders, { ordersActionTypes } from './orders';
import Filters, { filtersActionTypes } from './filters';
import Calendar from './calendar';
import Events from './events';
import Modal from './modal';
import Chargebee from "./chargebee";
import Doing, { doingActionTypes } from './doing';
import Screens, { screenActionTypes } from './screens';

export const actionTypes = {
    doing: doingActionTypes,
    screens: screenActionTypes,
    lists: listsActionTypes,
    filters: filtersActionTypes,
    orders: ordersActionTypes,
    tasks: TasksActionTypes,
    users: usersActionTypes
}

const actions = Object.assign(
    Base,
    Auth,
    Accounts,
    Professionals,
    Tasks,
    Lists,
    Users,
    Orders,
    Filters,
    Calendar,
    Events,
    Modal,
    Doing,
    Chargebee,
    Screens
);

/**
 * These are methods can be be used globally.
 * They can contain asynchronous operations.
 * They will commit a mutation instead of mutating the state directly.
 */
export default actions;