import { refreshSortings } from "~base/store/state/orders";

const types = {
    SELECT: "orders_select",
    SET_DISPLAY: "orders_display",
    GET_TRANSLATED: "ORDERS_GET_TRANSLATED"
}

export default {
    [types.SELECT]({ orders }, id = 'custom')
    {
        orders.selected = id;
    },

    [types.SET_DISPLAY]({ orders }, value)
    {
        orders.display = value;
    },

    [types.GET_TRANSLATED](state)
    {
        console.log(refreshSortings(), state.orders, 'sdfsfs');
        state.orders = refreshSortings();
    },
}

export { types as ordersMutationTypes };