<script>
export default {
    props: {
        minuteInterval: {
            required: false,
            type: Number,
            default() {
                return 15
            }
        },
        startTime: {
            required: false,
            type: String,
            default() {
                return '00:00';
            },
        },
        endTime: {
            required: false,
            type: String,
            default() {
                return '00:00';
            }
        },
        valueFormat: {
            required: false,
            type: String,
            default() {
                return 'HH:mm';
            }
        },
        textFormat: {
            required: false,
            type: String,
            default() {
                return 'HH:mm';
            }
        },
        preselected: {
            required: false,
            default() {
                return null;
            }
        },
        selectClassName: {
            required: false,
            type: String,
            default() {
                return this.$spa.css.form.select;
            },
        }
    },
    methods: {
        getFormattedOption(momentHour)
        {
            return {
                value: momentHour.clone().format(this.valueFormat),
                text: momentHour.clone().format(this.textFormat),
            }
        },
        shouldTimeBePushedToOptions(momentDateTime)
        {
            if ( this.isTimeMaximumAndMidnight (momentDateTime)) {
                return false;
            }

            return (momentDateTime >= this.minimumMoment) && (momentDateTime <= this.maximumMoment);
        },
        
        isTimeMaximum(momentDateTime)
        {
            return momentDateTime.clone().format('YYYY-MM-DD HH:mm') == this.maximumMoment.clone().format('YYYY-MM-DD HH:mm');
        },

        isTimeMaximumAndMidnight(momentDateTime)
        {
            return this.isMaximumMidnight && this.isTimeMaximum(momentDateTime);
        },
    },
    computed: {
        options()
        {
            let options = [];
            this.hourRange.forEach(hour => {
                this.minuteRange.forEach(minute => {
                    let currentMoment = window.moment(`${hour}:${minute}`, 'H:m');
                    if( this.shouldTimeBePushedToOptions(currentMoment) ) {
                        options.push(this.getFormattedOption(currentMoment));
                    }
                });
            });

            return options;
        },
        minuteRange()
        {
            let values = [];

            if (this.minuteInterval == 60 || this.minuteInterval == 0) {
                return [ 0 ];
            }

            let count = 60 / this.minuteInterval;
            for (let interval = 0; interval < count; interval++) {
                values.push(interval * this.minuteInterval);
            }

            return values;
        },
        hourRange()
        {
            let values = [];
            for (let hour = this.startHour; hour <= this.endHour; hour++) {
                values.push(hour);
            }

            return values;
        },
        startHour()
        {
            return window.moment(this.startTime,'HH:mm').format('H') * 1;
        },
        endHour()
        {
            let endHour = window.moment(this.endTime,'HH:mm').format('H') * 1;

            return endHour == 0 ? 24 : endHour;
        },
        maximumMoment()
        {
            let endTimeMoment = window.moment(this.endTime, 'HH:mm');
            
            if (this.endTime == '00:00') {
                endTimeMoment.add(1, 'days');
            }

            return endTimeMoment;
        },
        isMaximumMidnight()
        {
            return this.endTime == '00:00';
        },
        minimumMoment()
        {
            return window.moment(this.startTime, 'HH:mm');
        }
    },
    render() {
        return this.$scopedSlots.default({
            elements: this.options
        })
    }
}
</script>