<template>
    <sidebar-list-group 
        :lists="sharedAndPublicLists"
        :draggable="{ group: 'public' }"
        :title="$t('sidebar.lists.shared')"
        :isFavorite="false"
    >
        <template v-slot:action>
            <div :data-tooltip="$t('sidebar.lists.buttons.add_list')" data-tooltip-location="left">
                <i
                    class=" block fas fa-plus-circle cursor-pointer"
                    @click="addList"
                ></i>
            </div>
        </template>
    </sidebar-list-group>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SidebarListGroup from "~base/components/_Layout/Sidebar/Lists/ListGroup.vue";
import models from '~base/models';
import { getterTypes } from '~base/store/getters';

export default {
    components: {
        SidebarListGroup,
    },
    methods: {
        ...mapActions(['list_store']),
        async addList()
        {
            const list = models.taskList.new({ user: this.minimalUser });
            this.list_store(list);
            this.$router.push({
                name: 'account.lists.show', 
                params: { list: "new" }
            });
        },
    },
    computed: {
        ...mapGetters(['minimalUser']),
        sharedAndPublicLists() {
           return this.$store.getters[getterTypes.lists.sorted].sharedAndPublic;
        }
    }
}
</script>

<style>

</style>