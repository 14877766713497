<template>
    <div class="relative" v-click-outside="handleOutside">
        <i class="mx-2 fas fa-ellipsis-h text-gray-600 hover:text-primary text-sm cursor-pointer" @click="toggleMenu"></i>
        <ul class="absolute top-full left-0 flex rounded-md flex-col bg-white shadow z-10" v-if="isOpen">
            <menu-item @closeMenu="closeMenu" v-for="action in actions" :key="action.title" :action="action" />
        </ul>
    </div>
</template>

<script>
import MenuItem from "~base/components/Modals/Comments/Comment/Menu/Item.vue";

export default {
    props: {
        isOpen: { 
            required: true,
            type: Boolean
        }
    },
    data() {
        return {
            actions: [
                {
                    title: this.$t("modals.comments.list.menu.update"),
                    icon: "far fa-pencil-alt",
                    callback: this.updateComment
                },
                {
                    title: this.$t("modals.comments.list.menu.delete"),
                    icon: "far fa-trash-alt",
                    callback: this.deleteComment
                }
            ]
        }
    },
    methods: {
        closeMenu() {
            this.$emit('closeMenu');
        },
        openMenu() {
            this.$emit('openMenu');
        },
        handleOutside() {
            if (this.isOpen) this.closeMenu();
        },
        toggleMenu() {
            this.isOpen ? this.closeMenu() : this.openMenu();
        },
        deleteComment() {
            this.$emit("deleteComment");
        },
        updateComment() {
            this.$emit("updateComment");
        }
    },
    components: { MenuItem }
}
</script>

<style>

</style>}]