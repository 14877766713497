<template>
    <sidebar-element :to="{ name: 'account.activity' }">
        <template v-slot:icon="icon">
            <i class="fal fa-inbox text-gray-800" :class="icon._class"></i>
        </template>

        <template v-slot:text="text">
            <span :class="text._class">{{ $t("sidebar.menu.activities") }}</span>
        </template>
    </sidebar-element>
</template>

<script>
    import SidebarElement from "~base/components/_Layout/Sidebar/Section/Element.vue";

    export default {
        components: {
            SidebarElement
        }
    }
</script>