import helpers, { route } from "~base/helpers";
export default {
    async reactiveAccountSubscription()
    {
        const { account } = await helpers.api.put({
            url: route('api.chargebee.subscription.reactivate')
        });

        return account;
    },

    async reactiveAccountSubscriptionWithCard(context, token)
    {
        const { account } = await helpers.api.put({
            url: route('api.chargebee.subscription.reactivate'),
            data: { token }
        });

        return account;
    },

    async getAccountChargebeeInformations(context, uuid)
    {
        return await helpers.api.get({
            url: route('api.chargebee.informations')
        });
    },

    async getAccountChargebeeHostedPage(context, plan)
    {
        const { hosted_page } = await helpers.api.post({
            url: route('api.chargebee.subscription.hostedPages'),
            data: { plan }
        })

        return hosted_page;
    },
}