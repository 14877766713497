<template>
    <v-spa-layout-page :loading="loaders.settings.loading">
        <template slot="title">{{ $t("pages.settings.title") }}</template>
        
        <v-spa-loading-button @clicked="openLink" slot="title-button">
            <i class="text-white fas fa-users-cog md:mr-1"></i>
            <span class="hidden md:inline">{{ $t("pages.settings.collaborators.manage") }}</span>
        </v-spa-loading-button>

        <user-list slot="content"></user-list>     
    </v-spa-layout-page>
</template>

<script>
import UserList from "~base/components/Settings/Users.vue";
import helpers from '~base/helpers';
import models from '~base/models';
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';

export default {
    components: {
        UserList
    },
    data() {
        return {
            loaders: {
                settings: new helpers.loader(true)
            }
        };
    },
    methods: {
        async setSettings() {
            this.loaders.settings.loadTill(async () => {
                const settings = await models.userSetting.index({ users: this.users })
                const users = this.users.map(u => ({ ...u, setting: settings.find(s => s.user_id === u.id) || null }));
                this.setUsers(users);
            })
        },
        openLink() {
            window.open(this.$store.state.user.links.manage_users, '_blank')
        },
        setUsers(users) {
            this.$store.dispatch(actionTypes.users.SET_USERS, users)
        }
    },
    computed: {
        users() {
            return this.$store.state.users;
        },
        hasUsers() {
            return this.$store.getters[getterTypes.users.HAS_USERS];
        }
    },
    created() {
        if (this.hasUsers) {
            this.setSettings();
        } else {
            this.stopWatching = this.$watch('hasUsers', val => {
                if (val) {
                    this.setSettings();
                    this.stopWatching();
                }
            })
        }
    }
}
</script>

<style>

</style>