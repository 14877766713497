<template>
    <div class="flex flex-col">
        <v-spa-loader className="absolute inset-0 z-10 text-center" v-if="!account" />
        <template v-else>
            <sidebar-menu />
            <div class="hidden lg:block">
                <sidebar-lists />
            </div>
        </template>
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapState } from 'vuex';
    import models from "~base/models";
    import SidebarMenu from "~base/components/_Layout/Sidebar/Menu.vue";
    import SidebarLists from "~base/components/_Layout/Sidebar/Lists.vue";

    export default {
        components: {
            SidebarMenu,
            SidebarLists
        },
        data() {
            return {}
        },
        methods: {
            ...mapActions(['task_counters_set', 'list_index']),
            async setCounters()
            {
                const counters = await models.task.counters();
                await this.task_counters_set(counters);
            },
            async setLists()
            {
                const lists = await models.taskList.index();
                this.list_index(lists);
            },
            init()
            {
                return Promise.all([this.setCounters(), this.setLists()]);
            }
        },
        computed: {
            ...mapState(['account', 'counters', 'lists'])
        },
        created()
        {
            if (this.account) {
                this.init();
            } else {
                this.stopWatching = this.$watch('account', val => {
                    if (val) {
                        this.init();
                        this.stopWatching();
                    }
                })
            }
        }
    }
</script>
