export default {
    setEvents({ commit }, events) {
        commit('setEvents', events);
    },
    
    updateEvent({ commit, state }, { event }) {
        const index = state.calendar.events.findIndex(e => e.uuid === event.uuid);
        if (index === -1) return console.log('not found');
        commit('updateEvent', { event, index });
    },

    storeEvent({ commit }, { event }) {
        commit('storeEvent', { event });
    },

    destroyEvent({ commit, state }, { event }) {
        const index = state.calendar.events.findIndex(e => e.uuid === event.uuid);
        if (index === -1) return console.log('not found');
        commit('destroyEvent', { index });
    },
}