<template>
    <div class="account-base">
        <layout>
            <template slot="content">
                <div class="relative py-24" v-if="loading">
                    <v-spa-loader></v-spa-loader>
                </div>
                <div class="relative h-full" v-if="! loading && $store.state.account && $store.state.account.uuid">
                    <router-view :key="'account-' + $store.state.account.uuid + '-' + $store.state.account.updated_at + '-' + $route.fullPath"></router-view>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import calendarControls from "~base/components/Calendar/Controls.vue"
    import calendarViews from "~base/components/Calendar/Views.vue"
    import Layout from '~base/components/Layout.vue';
    import models from "~base/models";
import { actionTypes } from '~base/store/actions';
import helpers from '~base/helpers';
    export default {
        components: {
            'layout': Layout,
            calendarControls,
            calendarViews
        },
        data() {
            return {
                loading: false,
                debounce: { resize: null }
            }
        },
        methods: {
            ...mapActions(['user_index']),
            getAccount(uuid)
            {
                return new Promise((resolve, reject) => {
                    this.loading = true;
                    this.$store.dispatch('getAccount', uuid)
                        .then((account) => {
                            this.$store.dispatch('setAccount', account);
                            resolve();
                        })
                        .catch(error => {
                            console.log(error);
                            window.Toasteo.error('Account #' + uuid + ' not found.');
                            this.$router.push({name: 'homepage'});
                        });
                });
            },
            setScreen() {
                const screens = this.$store.state.screens.all;
                const screen = [...screens].reverse().find(s =>  window.innerWidth >=  s.value);
                this.$store.dispatch(actionTypes.screens.SET_SCREEN, { screen });
                
                return this;
            },
            watchResize() {
                this.debounce.resize = helpers.debounce(this.setScreen, 250);
                window.addEventListener("resize", this.debounce.resize);
            }
        },
        computed: {
            ...mapState(['account']),
            isCalendarPage()
            {
                return this.$route.name === 'account.calendar'
            }
        },
        
        async created() {
            this.setScreen()
                .watchResize();
            
            if (! this.account) {
                await this.getAccount(this.$route.params.account);
            }
            this.loading = false;

            this.user_index(await models.user.index())
        },

        beforeDestroy() {
            window.removeEventListener("resize", this.debounce.resize);
        }
    }
</script>
