<template>
    <li class="py-2.5 flex flex-col">
        <div class="pl-14 relative">
            <div class="shadow p-4 flex flex-col w-full">
                <span class="">
                    {{ activity.data.user.first_name }}
                    <span class="text-gray-600">
                        has done
                    </span>
                    {{ activity.full_type }}
                    <span class="text-gray-600">
                        concerning
                    </span>
                    {{ activity.data.title ? activity.data.title : activity.data.new }}
                </span>
            </div>
            <div class="absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-8 w-8 flex items-center justify-center border-4 border-white bg-white">
                <i class="fas fa-user text-sm text-gray-400"></i>
            </div>
        </div>
        <div class="pl-14 mt-2">
            <span class="text-sm text-gray-600 font-light">{{ createdAt.date }}</span>
            <span class="text-sm text-gray-600 font-light">{{ createdAt.time }}</span>
        </div>
    </li>
</template>

<script>
import moment from "moment";
export default {
    props: {
        activity: {
            required: true,
            type: Object
        }
    },
    computed: {
        createdAt()
        {
            const { created_at } = this.activity
            return {
                date: moment(created_at).format('DD MMMM YYYY'),
                time: moment(created_at).format('HH:mm')
            }
        }
    }
}
</script>

<style>

</style>