<template>
    <div class="flex items-center">
        <transition
            enter-active-class="transition duration-200 transform"
            leave-active-class="transition duration-200 transform"
            enter-class="opacity-0 -translate-x-2"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0 -translate-x-2"
        >
            <div class="hidden sm:block mr-2 transition-all duration-200 transform overflow-hidden" v-if="! loading">
                <button 
                    class="px-3 py-2 text-sm font-semibold border rounded outline-none transition duration-200" 
                    :class="todayButtonClasses"
                    @click="getTodayCalendarPage"
                >{{ $t("pages.calendar.controls.today") }}</button>
            </div>
        </transition>
        
        <transition
            enter-active-class="transition duration-200 transform"
            leave-active-class="transition duration-200 transform"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="flex flex-none items-center" v-if="! loading">
                <div @click="getPreviousCalendarPage" class="flex flex-none group h-8 w-8 items-center justify-center cursor-pointer rounded-full bg-white hover:bg-gray-200 transition duration-200">
                    <i class="fas fa-chevron-left text-base text-gray-600 group-hover:text-gray-800 transition duration-200"></i>
                </div>
                <div @click="getNextCalendarPage" class="flex flex-none group h-8 w-8 items-center justify-center cursor-pointer rounded-full bg-white hover:bg-gray-200 transition duration-200">
                    <i class="fas fa-chevron-right text-base text-gray-600 group-hover:text-gray-800 transition duration-200"></i>
                </div>
            </div>
        </transition>
        <div class="overflow-hidden sm:ml-2">
            <vue-sliding
                :trigger="transitions.title.sliding"
                leaving="duration-75"
                leaving-from="transform translate-x-0 opacity-100"
                :leaving-to="`transform opacity-0 ${isDirectionRight ? 'translate-x-12' : '-translate-x-12'}`"
                coming="duration-75"
                :coming-from="`transform opacity-0 ${isDirectionRight ? '-translate-x-12' : 'translate-x-12'}`"
                coming-to="transform translate-x-0 opacity-100"
            >
                <div 
                    v-if="calendar.title"
                    class="text-gray-600 text-sm sm:text-lg leading-none"
                > 
                    {{ calendar.title }} 
                </div>
            </vue-sliding>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import VueSliding from "~base/transitions/Sliding.vue";

export default {
    components: {
        VueSliding
    },
    data() {
        return {
            loading: true,
            transitions: {
                title: {
                    direction: null,
                    sliding: false
                }
            }
        }
    },
    methods: {
        stop()
        {
            this.show = true;
        },
        getPreviousCalendarPage()
        {
            this.$bus.$emit('fullCalendar:previousPage');

            return this;
        },
        getNextCalendarPage()
        {
            this.$bus.$emit('fullCalendar:nextPage');

            return this;
        },
        transitionTitle(direction) {
            const { title } = this.transitions;
            title.direction = direction;
            title.sliding = ! title.sliding

            return this;
        },
        getTodayCalendarPage()
        {
            if (! this.calendar.isToday) {
                this.$bus.$emit('fullCalendar:today');
            }

            return this;
        },
    },
    computed: {
        ...mapState(['calendar']),
        isTodayButtonActive()
        {
            return (! this.loading) && (! this.calendar.isToday);
        },
        todayButtonClasses()
        {
            return this.isTodayButtonActive ? 'text-gray-600 border-gray-300 hover:bg-gray-200' : 'text-gray-400 border-gray-100 cursor-default';
        },
        isDirectionRight()
        {
            return this.transitions.title.direction === 'right';
        },
    },
    created() 
    {
        this.$bus.$on('calendar:loaded', () => this.loading = false);
        this.$bus.$on('calendar:transitionTitle', this.transitionTitle);
    }
}
</script>