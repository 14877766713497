<template>
    <user-list-and-picker
        ref="picker"
        :field="field"
        :tooltip="{ data: $t('pages.list.share.users.button.tooltip'), location: 'left' }"
        :ifEmpty="[user]"
        @stopUserSelection="$emit('stopUserSelection')"
        class=""
    >
        <template
            v-slot:selectedUsers="{ toggle, selected, limit, tooltip }"
        >
            <div
                :data-tooltip="tooltip.data" 
                :data-tooltip-location="tooltip.location" 
                class="hidden md:flex items-center py-2 px-4 relative shadow border border-gray-200 hover:border-gray-400 transition cursor-pointer group rounded-md"
                @click="toggle"
            >
                <user-list :users="selected" :limit="limit" />
                <span class="pl-1 text-gray-800 transition">{{ $t("pages.list.share.users.button.text") }}</span>
            </div>
        </template>
    </user-list-and-picker>
</template>

<script>
import { mapState } from "vuex"
import UserListAndPicker from "~base/components/TaskList/Show/Partials/UserListAndPicker.vue";
import UserList from "~base/components/_Partials/UserList.vue";
import { getterTypes } from '~base/store/getters';

export default {
    props: {
        field: {
            required: true,
            type: Object
        }
    },
    components: {
        UserListAndPicker,
        UserList
    },
    methods: {},
    computed: {
        ...mapState(['user']),
        isScreenSmallerThanMd() {
            return this.$store.getters[getterTypes.screens.IS_SMALLER_THAN_MD]
        }
    },
    watch: {},
    mounted() {
        // forcing user selection if screen is smaller than md 
        // since opening is handled from a modal that triggered this component render
        if (!this.isScreenSmallerThanMd) return;
        this.$refs.picker.toggleUserSelection();
    }
}
</script>

<style>

</style>