import { refreshFilters } from "~base/store/state/filters";

const types = {
    GET_TRANSLATED: "FILTERS_GET_TRANSLATED",
};

export default {
    // filters_select({ filters }, id)
    // {
    //     filters.active.push(id);
    // },

    // filters_unselect({ filters }, id)
    // {
    //     const index = filters.active.findIndex(filterId => filterId === id);
    //     if (index > -1) filters.active.splice(index, 1);
    // },
    [types.GET_TRANSLATED](state)
    {
        state.filters = refreshFilters();
    },

    filters_set({ filters }, ids)
    {
        filters.active = ids;
    },

    filters_display({ filters }, value)
    {
        filters.display = value;
    }
}

export { types as filterMutationTypes };