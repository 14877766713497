<template>
    <v-spa-right-panel-modal
        :name="modal.name"
        @before-open="init"
        @before-close="destroy"
    >
        <div class="flex flex-col items-center justify-center w-full h-full">
            <img :src="image.source" class="object-contain" alt="" v-if="image.source">
        </div>
    </v-spa-right-panel-modal>
</template>

<script>
import modals from "~base/components/Modals/Config";

export default {
    data() {
        return {
            modals: modals.apply(this),
            modal: modals.apply(this).commentImage,
            image: { source: null },
        }
    },
    methods: {
        init({ params: { image } }) {
            this.image = image;
        },
        destroy() {
            this.image = { source: null };
        }
    }
}
</script>