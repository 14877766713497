const types = {
    SET_COUNTERS: "task_counters_set",
    SET_ACTIVE_TASK: "TASKS/SET_ACTIVE_TASK",
};

export default {
    [types.SET_COUNTERS](state, counters) 
    {
        state.counters = counters;
    },
    [types.SET_ACTIVE_TASK]({ tasks }, task) 
    {
        tasks.active = task;
    }
}

export { types as tasksMutationTypes };