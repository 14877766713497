<template>
    <div class="flex flex-col h-full -mt-2.5">
        <div class="relative flex-auto">
            <v-spa-loader v-if="!account" />
            <template v-else>
                <sidebar-menu />
                <sidebar-lists />
            </template>
        </div>
    </div>
</template>



<script>

    import { mapState } from "vuex";
    import SidebarMenu from "~base/components/_Layout/Sidebar/Menu.vue";
    import SidebarLists from "~base/components/_Layout/Sidebar/Lists.vue";

    export default {
        components: {
            SidebarMenu,
            SidebarLists
        },
        computed: {
            ...mapState(['account'])
        }
    }
</script>
