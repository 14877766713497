<template>
  <i class="text-gray-400 text-sm" :class="chevron" @click="$emit('input', !value)"></i>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
            type: Boolean
        }
    },
    computed: {
        chevron() {
            return this.value ? "fas fa-chevron-down" : "fas fa-chevron-right";
        }
    }
}
</script>

<style>

</style>