const types = {
    index: "list_index",
    update: "list_update",
    UPDATE_LISTS: "LISTS_UPDATE_LISTS",
    store: "list_store",
    delete: "list_delete",
    set_active: "LISTS_SET_ACTIVE",
    SET_ACTIVE_PREVENT_FILTERS: "FILTERS_SET_ACTIVE_PREVENT_FILTERS",
    SET_ARCHIVES_COUNT: "LISTS/SET_ARCHIVES_COUNT"
}

export default {

    [types.index](state, lists)
    {
        if (state.lists.length === 0) state.lists = lists;
        else {
            const finalLists = [];
            lists.forEach(list => {
                const existing = state.lists.find(ls => ls.uuid === list.uuid);
                finalLists.push(existing ? existing : list)
            });
            state.lists = finalLists;
        }
    },

    [types.update](state, list)
    {
        const index = state.lists.findIndex(l => l.uuid === list.uuid);
        if (index === -1) {
            return state.lists.push(list);
        };
        state.lists.splice(index, 1, list);
    },

    [types.UPDATE_LISTS](state, lists) {
        state.lists = state.lists.map(state_list => lists.find(list => state_list.uuid === list.uuid) || state_list)
    },

    [types.store](state, list)
    {
        state.lists.push(list);
    },

    [types.delete](state, list)
    {
        const index = state.lists.findIndex(l => l.uuid === list.uuid);
        if (index === -1) return;
        state.lists.splice(index, 1);
    },

    [types.set_active] (state, list)
    {
        state.active_list = list.uuid;
    },

    [types.SET_ACTIVE_PREVENT_FILTERS] (state, prevent)
    {
        state.active_list_prevent_filters = prevent;
    },

    [types.SET_ARCHIVES_COUNT] (state, count)
    {
        state.lists_archives_count = count;
    },

}

export { types as listsMutationTypes }