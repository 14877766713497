import { mutationTypes } from "../mutations";

const types = {
    GET_TRANSLATED: "FILTERS_GET_TRANSLATED",
    DESELECT: "filters_unselect",
    SELECT: "filters_select",
    SET: "filters_set",
    SHOW: "filters_show",
    HIDE: "filters_hide",
    TOGGLE_DISPLAY: "filters_toggle"
};

export default {
    [types.DESELECT]({ dispatch, getters: { activeFilters, defaultFilters }, state }, { id })
    {
        const filter = activeFilters.find(f => f.id === id);
        // if (!filter) return;

        const sameGroupFilter = activeFilters.find(f => f.group_id === filter.group_id && f.id !== filter.id);
        const activeIds = state.filters.active.filter(fId => fId !== id);
        
        // Reactivate default group filter if needed
        if (!sameGroupFilter) {
            const defaultFilter = defaultFilters.find(f => f.group_id === filter.group_id);
            if (defaultFilter) activeIds.push(defaultFilter.id);
        }

        return dispatch('filters_set', activeIds);
    },

    [types.SELECT]({ dispatch, getters: { activeFilters }, state: { filters } }, { id })
    {
        const filter = filters.all.find(f => f.id === id);
        // if (!filter) return;
        // removing same group filters that cancel others
        const newActiveFilters = activeFilters.filter(f => f.group_id !== filter.group_id || (!f.cancel_others && !filter.cancel_others));
        // adding new filter
        newActiveFilters.push(filter);

        return dispatch('filters_set', newActiveFilters.map(f => f.id));
    },

    [types.SET]({ commit }, ids)
    {
        commit('filters_set', ids);
        return ids;
    },

    [types.GET_TRANSLATED]({ commit })
    {
        commit(mutationTypes.filters.GET_TRANSLATED);
    },

    [types.SHOW]({ commit })
    {
        commit('filters_display', true);
    },

    [types.HIDE]({ commit })
    {
        commit('filters_display', false);
    },

    [types.TOGGLE_DISPLAY]({ commit, state })
    {
        commit('filters_display', !state.filters.display);
    }
};

export { types as filtersActionTypes };