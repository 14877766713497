
const config = function () {
    // define modal names here
    // You get hide and show fucntion aswell when importing 
    const config = {
        comments: 'comments',
        commentImage: 'commentImage',
        calendarTask: 'calendarTask',
        delete: 'delete',
        taskEdit: "taskEdit",
        listHeader: "listHeader"
    }
    
    Object.keys(config).forEach(name => {
        config[name] = {
            name,
            show: params => this.$modal.show(name, params),
            hide: () => this.$modal.hide(name)
        };
    })
    
    return config;
}

export default config;