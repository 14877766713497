<template>
    <ul>
        <li v-for="groupFilter in groupFilters" :key="groupFilter.group.id" class="pb-4 last:pb-0">
            <span class="block mb-2 font-medium text-gray-800">{{ groupFilter.group.display }}</span>
            <ul class="flex flex-wrap items-center py-2 -mx-2">
                <list-filter v-for="filter in groupFilter.filters" :key="filter.id" :filter="filter" />
            </ul>
        </li>
    </ul>
</template>

<script>
import ListFilter from "~base/components/TaskList/Show/Partials/List/Filters/Filter.vue";
export default {
    components: {
        ListFilter
    },
    computed: {
        groupFilters()
        {
            return this.$store.getters.groupFilters;
        }
    }
}
</script>

<style>

</style>