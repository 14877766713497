import { taskColor } from "~base/models";
import i18n from "~base/i18n";

const filters = () => ({
    display: false,
    active: ['status-all', 'due-date-all', 'task-color-all'],
    default: ['status-all', 'due-date-all', 'task-color-all'],
    all: [
        {
            id: 'status-done',
            group_id: 'status',
            display: i18n.t("pages.list.filters.status.done"),
            cancel_others: true,
            filter: task => task.is_done
        },
        {
            id: 'status-not-done',
            group_id: 'status',
            display: i18n.t("pages.list.filters.status.not_done"),
            cancel_others: true,
            filter: task => !task.is_done
        },
        {
            id: 'status-all',
            group_id: 'status',
            display: i18n.t("pages.list.filters.all"),
            cancel_others: true,
            filter: task => true
        },
        {
            id: 'due-date-late',
            group_id: 'due-date',
            display: i18n.t("pages.list.filters.due_date.late"),
            cancel_others: false,
            filter: task => task.is_done ? false : moment(task.due_date).isBefore(moment(), 'day')
        },
        {
            id: 'due-date-today',
            group_id: 'due-date',
            display: i18n.t("pages.list.filters.due_date.today"),
            cancel_others: false,
            filter: task => moment(task.due_date).isSame(moment(), 'day')
        },
        {
            id: 'due-date-all',
            group_id: 'due-date',
            display: i18n.t("pages.list.filters.due_date.all"),
            cancel_others: true,
            filter: task => true
        },
        ...taskColor.all.map(c => {
            const { id, title } = c;
            return {
                id: `task-color-${id}`,
                group_id: 'task-color',
                display: title,
                cancel_others: false,
                filter: task => task.color_id === id
            };
        }),
        {
            id: `task-color-all`,
            group_id: 'task-color',
            display: i18n.t("pages.list.filters.color.all"),
            cancel_others: true,
            filter: () => true
        }
    ],
    groups: [
        { id: 'status', display: i18n.t("pages.list.filters.status.group") },
        { id: 'due-date', display: i18n.t("pages.list.filters.due_date.group") },
        { id: 'task-color', display: i18n.t("pages.list.filters.color.group") }
    ]
});

export default filters();
export { filters as refreshFilters };