<template>
    <div class="flex group items-center -mx-2" @click="$emit('clicked')">
        <span class="text-sm text-primary px-2 cursor-pointer">{{ file.name }}</span>
        <div v-if="useIcon" class="w-5 h-5 rounded-full px-2 bg-gray-200 flex items-center justify-center cursor-pointer opacity-0 group-hover:opacity-100 transition">
            <i class="far fa-trash-alt text-gray-600 text-xs transition"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        file: {
            type: Object,
            required: true
        },
        useIcon: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style>

</style>