<template>
        <ul class="absolute z-10 left-0 right-0 top-full mt-2 px-4 shadow bg-white max-h-36 overflow-y-auto">
        <time-picker-list-item
            :element="element"
            :highlighted="isHighlighted(element)"
            :selected="selected(element)"
            :select="select"
            v-for="element in elements"
            :key="element.value"
        />
        </ul>
</template>

<script>
import helpers from '~base/helpers'
import TimePickerListItem from '~base/components/_Partials/TimePickerListItem.vue';
export default {
    props: {
        elements: {
            required: true,
            type: Array
        },
        selected: {
            required: true,
            type: Function
        },
        select: {
            required: true,
            type: Function
        },
        highlightPrevious: {
            type: Function,
            required: true
        },
        highlightNext: {
            type: Function,
            required: true
        },
        highlighted: {
            required: true
        },
        hasHighlight: {
            type: Boolean,
            required: true
        },
        isHighlighted: {
            type: Function,
            required: true
        },
    },
    components: { TimePickerListItem },
    data() {
        return {
            keypress: {}
        }
    },
    computed: {},
    created() {
        this.keypress = new helpers.keypress(document)
            .on(['ArrowUp'], () => this.highlightPrevious(this.elements))
            .on(['ArrowDown'], () => this.highlightNext(this.elements))
    },
    beforeDestroy() {
        this.keypress.removeListeners();
    }
}
</script>

<style>

</style>