<template>
    <li 
        class="flex items-center py-1 cursor-pointer border border-transparent rounded hover:bg-gray-100 transition focus:outline-none"
        :class="{['bg-gray-100']: isHighlighted}"
        tabindex="0"
        @click="handleClick"
        @keydown.enter.prevent.stop="selectIfHighlighted"
    >
        <picker-element 
            v-bind="pickerProps"
        >
            <user-avatar :user="element" />
        </picker-element>
        <span :class="nameClasses">{{ element.full_name }}</span>
    </li>
</template>

<script>
import PickerElement from "~base/components/_Partials/PickerElement.vue";
import UserAvatar from "~base/components/_Partials/Avatar.vue";

export default {
    props: {
        element: {
            required: true,
            type: Object
        },
        delete: {
            required: true,
            type: Function
        },
        add: {
            required: true,
            type: Function
        },
        selected: {
            required: true,
            type: Function
        },
        highlighted: {
            required: true,
            type: Function
        }
    },
    components: {
        PickerElement,
        UserAvatar
    },
    methods: {
        handleClick()
        {
            this.isSelected
                ? this.delete(this.element)
                : this.add(this.element)
        },
        selectIfHighlighted() {
            return this.isHighlighted && this.handleClick();
        },
        focusIfActive(is_active) {
            return is_active && this.$el.focus();
        } 
    },
    computed: {
        nameClasses()
        {
            return `text-[15px] leading-none pl-2 pr-1 ${ this.isSelected ? "text-primary" : "text-gray-600"}`;
        },
        // Click should be handled by parent here so callbacks are defined as empty function
        pickerProps()
        {
            return {
                ...this.$props,
                delete: () => {},
                add: () => {}
            }
        },
        isHighlighted() {
            return this.highlighted(this.element)
        },
        isSelected() {
            return this.selected(this.element)
        },
        isActive() {
            return this.isHighlighted || this.isSelected;
        }
    },
    watch: {
        isHighlighted(is_highlighted) {
            this.focusIfActive(is_highlighted)
        } 
    },
    mounted() {
        this.focusIfActive(this.isHighlighted);
    }
}
</script>

<style>

</style>