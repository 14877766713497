<template>
    <div
        class="fixed flex z-20 justify-between rounded-lg bottom-4 right-4 pl-5 pr-1 py-1.5 bg-white shadow"
        v-if="isListPreventingFilters && this.display"
        @click="stopPrevent"
    >
        <div class="flex flex-auto items-center">
            <div class="flex flex-col">
                <button class="text-primary hover text-primary-dark cursor-pointer">{{ $t("pages.list.toasts.apply_filters") }}</button>
            </div>
            <button class="p-4" @click.stop="close">
                <img src="/spa/close.svg" class="h-3 w-3 opacity-25" />
            </button>
        </div>
    </div>
</template>

<script>
import { actionTypes } from '~base/store/actions';
export default {
    data() {
        return {
            display: true
        };
    },
    methods: {
        close() {
            this.display = false;
        },
        stopPrevent() {
            this.$store.dispatch(actionTypes.lists.SET_ACTIVE_PREVENT_FILTERS, false);
        }
    },
    computed: {
        isListPreventingFilters() {
            return this.$store.state.active_list_prevent_filters;
        }
    }
}
</script>

<style>

</style>