<template>
    <element-picker
        @input="users => $emit('input', users)"
        :value="value"
        track-by="id"
        search-by="full_name"
        :elements="users"
        :multiple="true"
        v-slot="{ deleteElement, addElement, toggleElement, highlight, hasHighlight, isHighlighted, highlightNextElement, highlightPreviousElement, input, elements, selected }"
    >
        <picker-user-list
            :deleteElement="deleteElement"
            :addElement="addElement"
            :toggleElement="toggleElement"
            :isHighlighted="isHighlighted"
            :highlight="highlight"
            :hasHighlight="hasHighlight"
            :highlightNextElement="highlightNextElement"
            :highlightPreviousElement="highlightPreviousElement"
            :input="input"
            :elements="elements"
            :selected="selected"
        />
    </element-picker>
</template>

<script>
import ElementPicker from "~base/components/_Partials/ElementPicker.vue";
import PickerUserList from "~base/components/_Partials/UserPicker/List.vue";

export default {
    props: {
        value: {
            required: true
        },
        users: {
            type: Array,
            required: true
        }
    },
    components: {
        ElementPicker,
        PickerUserList
    }
}
</script>

<style>

</style>