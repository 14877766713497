<template>
    <sidebar-section>
        <template v-slot:title_text="title_text">
            <span :class="title_text._class">{{ title }}</span>
        </template>
        <template v-slot:title_icon>
            <slot name="action" />
        </template>

        <template v-slot:list>
            <div class="flex flex-col" v-if="lists.length">
                <draggable-list
                    v-model="draggableLists"
                    :group="draggable.group"
                    :delay="300"
                    ghost-class="draggable__moving_element"
                    :animation="300"
                    :delayOnTouchOnly="true"
                >
                    <single-list
                        :list="list"
                        v-for="list in lists"
                        :key="list.uuid"
                    />
                </draggable-list>
            </div>
            <span class="py-3 px-4 text-gray-600" v-else>{{ $t("sidebar.lists.empty") }}</span>
        </template>
    </sidebar-section>
</template>

<script>
import SingleList from "~base/components/_Layout/Sidebar/Lists/List.vue";
import SidebarSection from "~base/components/_Layout/Sidebar/Section.vue";
import DraggableList from "vuedraggable";
import models from '~base/models';
import { actionTypes } from '~base/store/actions';
import { getterTypes } from '~base/store/getters';

export default {
    props: {
        lists: {
            required: true,
            type: Array
        },
        title: {
            required: true,
            type: String
        },
        draggable: {
            required: true,
            type: Object
        }
    },
    components: {
        SingleList,
        SidebarSection,
        DraggableList
    },
    methods: {
        updateLists(lists) {
            this.$store.dispatch(actionTypes.lists.UPDATE_LISTS, lists)
        },
        handleDraggableListUpdate(draggableLists) {
            const column = this.isFavorite ? "favorite_order" : "order";
            const { lists, bulk } = draggableLists.reduce((data, list, i) => {
                if (!list.current_user_filters || list.current_user_filters[column] !== i) {
                    const current_user_filters = list?.current_user_filters
                        ? { ...list.current_user_filters, [column]: i }
                        : models.taskListUserFilter.new({ user: this.user, list: list }, { [column]: i, task_list_id: list.id })
                    // Creating bulk for filters
                    data.bulk.push(current_user_filters);
                    // Updating lists values
                    data.lists.push({ ...list, current_user_filters })
                }
                data.lists.push(list);
                return data;
            }, { bulk: [], lists: [] });
            this.updateLists(lists);
            models.taskListUserFilter.bulkUpdate({ bulk });
        },
        
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        draggableLists: {
            get() {
                return this.lists;
            },
            set(lists) {
                this.handleDraggableListUpdate(lists);
            }
        },
        isFavorite() {
            return this.draggable.group === "favorite";
        }
    },
    watch: {
        lists: {
            immediate: true,
            handler(value) {
                value.sort(models.taskList[this.isFavorite ? "sortByFavoriteOrder" : "sortByOrder"])
            }
        }
    }
}
</script>
