import tailwindConfig from '../../../tailwind.config';

const colors = [
    {
        id: 1,
        value: tailwindConfig.theme.extend.colors.gray[400],
        lighter: "transparent",
        default: true,
        title: 'Aucun'
    },
    {
        id: 2,
        value: "#ffcf00",
        lighter: "#fffcee",
        title: 'Important'
    },
    {
        id: 3,
        value: "#f13535",
        lighter: "#fff2f2",
        title: 'Urgent'
    }
];

export default {
    all: colors,
    default: colors.find(c => c.default),
    exceptDefault: colors.filter(c => !c.default)
}