import { api, route } from "~base/helpers";

export default {
    async index()
    {
        const { data } = await api.get({
            url: route('api.users.index')
        });

        return data.map(user => ({...user, full_name: `${user.first_name} ${user.last_name}`.trim()}));
    },
    async connected()
    {
        try {
            const { data: { user, token } } = await axios.get(window.url.auth + "/api/user", { withCredentials: true })
            return { user, token };
        } catch ({ response: { data } }) {
            throw data;
        }
    }
}
