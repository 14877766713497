<template>
    <v-spa-right-panel-modal
        :name="name"
        height="100%"
        :scrollable="false"
        width="100%"
        :shift-x="1"
        :shift-y="0"
        :show-close-icon="true"
        @before-open="initModal"
    >
        <div>
            <div class="relative h-screen" v-if="loader.comments.loading">
                <v-spa-loader />
            </div>
            <div class="relative flex flex-col max-h-screen px-7">
                <span class="pr-8 text-3xl text-gray-800 py-7">{{ task.title }}</span>
                <ul class="flex flex-col flex-auto overflow-x-hidden overflow-y-auto" v-if="comments.length">
                    <comment-item
                        v-for="comment in comments"
                        :comment="comment"
                        :active-comment-menu="activeCommentMenu"
                        :task="task"
                        :key="comment.uuid"
                        :update-comment="updateComment.bind(this, comment.uuid)"
                        @openMenu="activeCommentMenu = comment.uuid"
                        @closeMenu="activeCommentMenu = null"
                        @deleteComment="openDeleteModal(comment)"
                    />
                </ul>
                <div class="py-4">
                    <comment-form
                        :callback="addComment"
                        :submitText="$t('modals.comments.form.submit.store')"
                    />
                </div>
            </div>
        </div>
    </v-spa-right-panel-modal>
</template>

<script>
import { mapActions } from "vuex";
import modals from "~base/components/Modals/Config";
import CommentItem from "~base/components/Modals/Comments/Comment.vue";
import commentForm from "~base/components/Modals/Comments/Form.vue";
import models from "~base/models";
import helpers from '~base/helpers';

export default {
    components: {
        CommentItem,
        commentForm,
    },
    data()
    {
        return {
            name: modals().comments.name,
            modals: modals.apply(this),
            comments: [],
            activeCommentMenu: null,
            loader: {
                comments: new helpers.loader(true),
            },
            unwatch: { taskHasUuid: null }
        }
    },
    methods: {
        ...mapActions(['updateEvent']),
        initModal()
        {
            const { comments } = this.loader;
            //  if task is not stored yet
            // Wait for it then load comments
            if (!this.task.uuid) {
                this.unwatch.taskHasUuid = this.$watch('taskHasUuid', (has_uuid) => {
                    if (!has_uuid) return;
                    // no need to set comments => task freshly created
                    comments.stop()
                    this.unwatch.taskHasUuid();
                })
                return;
            };
            
            return comments.loadTill(async () => {
                this.comments = await models.comment.index({ uuid: this.task.uuid });
            })
        },
        async addComment({ formData })
        {
            const comment = await models.comment.store({ 
                task: this.task, 
                comment: formData
            });
            this.task.comments_count ++;
            // on calendar page update event comments_count;
            if (this.$route.name === 'account.calendar') {
                const event = models.task.toCalendarTask(this.task);
                this.updateEvent({ event });
            }
            this.comments.push(comment);
        },
        async updateComment(uuid, { formData })
        {
            const comment = await models.comment.update({ 
                task: this.task, 
                comment: { formData, uuid }
            });
            const index = this.comments.findIndex(c => c.uuid === uuid);
            if (index !== -1) this.comments.splice(index, 1, comment);
        },
        async deleteComment(comment) {
            models.comment.delete({
                task: this.task,
                comment
            });
            this.task.comments_count --;
            // on calendar page update event comments_count;
            if (this.$route.name === 'account.calendar') {
                const event = models.task.toCalendarTask(this.task);
                this.updateEvent({ event });
            }

            const index = this.comments.findIndex(c => c.uuid === comment.uuid);
            if (index !== -1) this.comments.splice(index, 1);
        },
        openDeleteModal(comment) {
            this.modals.delete.show({
                item: { title: comment.text },
                callback: this.deleteComment.bind(this, comment)
            });
        }
    },
    computed: {
        task() {
            return this.$store.state.tasks.active;
        },
        taskHasUuid() {
            return !!this.task.uuid;
        }
    }
}
</script>