export default class Loader
{
    constructor(status = false)
    {
        this.loading = status
    }

    load()
    {
        this.loading = true;
        return this;
    }

    stop()
    {
        this.loading = false
        return this;
    }

    async loadTill(callback)
    {
        try {
            this.load();
            await callback();
        } catch(err) {
            throw err;
        } finally {
            this.stop();
        }
    }
}