import { v4 as uuid_v4 } from "uuid"
import helpers from "~base/helpers"
import Vue from "vue";

class History {
    constructor({ max }) {
        this.max = max;
        this.todos = [];
        this.setProcessingAlert();
    }
    /**
     * Adding entry
     * @param {Object} entry entry to add
     * @returns entry uuid
     */
    add({ callback, reverse, delay, data }) {
        const uuid = uuid_v4();
        const timeout = setTimeout(() => {
            this.call(uuid);
        }, delay);
        if (this.todos.length >= this.max) this.call(this.todos[0].uuid);
        this.todos.push({ uuid, callback, reverse, timeout, data })
        return uuid;
    }

    /**
     * Calling instantky entry matching uuid
     * @param {String} uuid entry uuid
     * @returns void
     */
    call(uuid) {
        console.log("calling", uuid)
        const todo = this.todos.find(t => t.uuid === uuid);
        if (!todo) return console.error("couldnt find todo");
        this.callTodo(todo);
    }

    /**
     * Cancelling entry matching uuid
     * @param {String} uuid entry uuid
     * @returns void
     */
    cancel(uuid) {
        console.log("calling", uuid)
        const index = this.todos.findIndex(t => t.uuid === uuid);
        if (index === -1) return console.error("couldnt find todo");
        const { reverse, timeout } = this.todos[index];
        clearTimeout(timeout);
        reverse();
        this.todos.splice(index, 1);
    }

    async callTodo({ uuid, callback, reverse, timeout }) {
        clearTimeout(timeout);
        const index = this.todos.findIndex(t => t.uuid === uuid);
        if (index === -1) return;
        this.todos.splice(index, 1);
        const [, error] = await helpers.try(callback());
        if (error) {
            console.error(error);
            reverse();
        };
    }

    setProcessingAlert() {
        window.onbeforeunload = this.confirmLeaving.bind(this);
    }

    confirmLeaving(e) {
        if (!this.processing) return;
        e.preventDefault();
        const message = "Un enregistrement est en cours, vous risquez de perdre des données";
        if (!e) return message;
        e.returnValue = message;
    }

    /**
     * Telling if plugin is processing items.
     * @returns {Boolean}
     */
    get processing() {
        return this.todos.length > 0;
    }
}

const plugin = {
    install(Vue, options) {
        Vue.prototype.$history = Vue.observable(new History(options));
    }
}

Vue.use(plugin, { max: 1 });

export default plugin;

