import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { defaultLocale } from "./locales";

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: defaultLocale,
	fallbackLocale: defaultLocale,
	messages: window.translations
});

export default i18n;

window.exportTranslations = function () {
    let exported = [];

    let translations = {
        fr: flattenObj(window.App.$i18n.messages.fr),
        nl: flattenObj(window.App.$i18n.messages.nl),
        en: flattenObj(window.App.$i18n.messages.en),
        de: flattenObj(window.App.$i18n.messages.de)
    };

    for ( let key in translations.fr ) {
        exported.push({
            key: key,
            text: {
                fr: translations.fr[key],
                nl: translations.nl[key] || null,
                en: translations.en[key] || null,
                de: translations.de[key] || null
            }
        });
    }

    return exported;
}

const flattenObj = (ob) => {
  
    // The object which contains the
    // final result
    let result = {};
  
    // loop through the object "ob"
    for (const i in ob) {
  
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if ((typeof ob[i]) === 'object') {
            const temp = flattenObj(ob[i]);
            for (const j in temp) {
  
                // Store temp in result
                result[i + '.' + j] = temp[j];
            }
        }
  
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};