const tailwind = require('@deegital/trustup-spa-js-assets/tailwind.js');

tailwind.theme.extend.colors = {
    ...tailwind.theme.extend.colors,
    ...{
        body: '#FFF',
        'late-task': '#f44336',
        'today-task': '#4caf50'
    }
}

module.exports = tailwind;
