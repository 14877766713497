import { listsMutationTypes } from "../mutations/lists";

const types = {
    index: "list_index",
    update: "list_update",
    UPDATE_LISTS: "LISTS_UPDATE_LISTS",
    store: "list_store",
    delete: "list_delete",
    set_active: "LISTS_SET_ACTIVE",
    SET_ACTIVE_PREVENT_FILTERS: "FILTERS_SET_ACTIVE_PREVENT_FILTERS",
    SET_ARCHIVES_COUNT: "LISTS/SET_ARCHIVES_COUNT",
    UPDATE_ARCHIVES_COUNT: "LISTS/UPDATE_ARCHIVES_COUNT",
};

export default {
    
    async [types.index]({ commit }, lists)
    {
        commit(listsMutationTypes.index, lists);
    },
    
    [types.update]({ commit }, list)
    {
        commit(listsMutationTypes.update, list);
    },

    [types.UPDATE_LISTS]({ commit }, lists)
    {
        commit(listsMutationTypes.UPDATE_LISTS, lists);
    },
    
    [types.store]({ commit }, list)
    {
        commit(listsMutationTypes.store, list);
    },

    [types.delete]({ commit }, list)
    {
        commit(listsMutationTypes.delete, list);
    },

    [types.set_active]({ commit }, { list }) {
        commit(listsMutationTypes.set_active, list);
    },
    [types.SET_ACTIVE_PREVENT_FILTERS]({ commit }, prevent) {
        commit(listsMutationTypes.SET_ACTIVE_PREVENT_FILTERS, prevent);
    },
    [types.SET_ARCHIVES_COUNT]: ({ commit }, { count }) => commit(listsMutationTypes.SET_ARCHIVES_COUNT, count),
    
    [types.UPDATE_ARCHIVES_COUNT]: ({ commit, state: { lists_archives_count } }, { was_archived, is_archived }) => {
        if (was_archived) lists_archives_count --; 
        if (is_archived) lists_archives_count ++;
        commit(listsMutationTypes.SET_ARCHIVES_COUNT, lists_archives_count);
    },

}

export { types as listsActionTypes }