const types = {
    "SET_USERS": "user_index",
    "UPDATE_USER": "USERS_USER_UPDATE"
}

export default {
    
    [types.SET_USERS](state, users)
    {
        state.users = users;
    },
    [types.UPDATE_USER](state, user)
    {
        const index = state.users.findIndex(u => u.id === user.id);
        if (index === -1) return;
        state.users.splice(index, 1, user);
    },

}

export { types as usersMutationTypes };