<template>
    <div class="h-full">
        <div class="relative py-24" v-if="! list || loaders.list.loading"><v-spa-loader></v-spa-loader></div>
        <task-list 
            :list="list"
            :disable-drag="!isDefaultOrder" 
            :use-groups="true" 
            :as-page="true"
            task-key="ordered_groupless_parent_tasks"
            v-else
        >
            <template v-slot:title="{ model, start, save, cancel, editing, setModel }">
            <input 
                class="py-0.5 px-[3px] !text-xl !md:text-3xl leading-none focus:outline-none bg-white border border-dashed border-gray-600"
                v-if="editing"
                v-focus
                :value="model"
                @input="setModel"
                @blur="save"
                @keydown.enter.prevent="save"
                @keydown.esc.prevent="cancel"
            >
            <span 
                class="py-0.5 px-[3px] text-xl md:text-3xl hover:bg-white border border-dashed border-transparent hover:border-gray-600"
                v-else
                @click="start"
            >{{ model }}</span>
            </template>
        </task-list>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import models from "~base/models";
import TaskList from "~base/components/TaskList/Show/Partials/List.vue";
import directives from "~base/directives";
import helpers from '~base/helpers';
import { getterTypes } from '~base/store/getters';
import { actionTypes } from '~base/store/actions';

export default {
  components: {
    TaskList
  },
  directives: {
    focus: directives.focus,
  },
  data() {
    return {
      loaders: {
        list: new helpers.loader(false)
      }
    };
  },
  methods: {
    ...mapActions(['list_update']),
    async setList() {
      this.setActiveList();
      // happens when list was just persisted and url changes (store already has up-to-date data)
      if (this.$route.params.preventLoading) return;

      this.loaders.list.loadTill(async () => {
        const [list, error] = await this.getList();
        if(error) return this.$router.replace({ name: "account.homepage" });
        await this.updateList(list);
        this.setActivefilters();
        this.setActiveSorting();
      });

    },
    async getList() {
      return this.stored
        ? models.taskList.show(this.uuid)
        : [{ ...this.list, ordered_groupless_parent_tasks: [] }]
    },
    updateList(list) {
      this.$store.dispatch(actionTypes.lists.update, list);
    },
    setActiveList() {
      this.$store.dispatch(actionTypes.lists.set_active, { list: { uuid: this.uuid } })
    },
    setActivefilters() {
      this.$store.dispatch(actionTypes.filters.SET, this.listFilters);
    },
    setActiveSorting() {
      this.$store.dispatch(actionTypes.orders.SELECT, { id: this.listSorting });
    },
  },
  computed: {
    isDefaultOrder() {
      return this.$store.getters[getterTypes.orders.IS_DEFAULT_ORDER];
    },
    list() {
      return this.$store.getters[getterTypes.lists.active];
    },
    listFilters() {
      return this.list?.current_user_filters?.filters || this.$store.state.filters.default;
    },
    listSorting() {
      return this.list?.current_user_filters?.sorting || this.$store.state.orders.default;
    },
    stored()
    {
      return this.uuid ? true : false;
    },
    uuid()
    {
      const { list } = this.$route.params;
      return list === "new" ? undefined : list;
    }
  },
  created() 
  {
    this.setList();
  },
};
</script>