<template>
    <sidebar-section>
        <template v-slot:title>{{ null }}</template>
        <template v-slot:list>
            <!-- <menu-activity-stream /> -->
            <menu-today />
            <menu-late />
            <menu-calendar />
            <menu-settings />
            <menu-archives />
        </template>
    </sidebar-section>
</template>

<script>
import SidebarSection from "~base/components/_Layout/Sidebar/Section.vue";
import MenuActivityStream from "~base/components/_Layout/Sidebar/Menu/ActivityStream.vue";
import MenuToday from "~base/components/_Layout/Sidebar/Menu/Today.vue";
import MenuLate from "~base/components/_Layout/Sidebar/Menu/Late.vue";
import MenuCalendar from "~base/components/_Layout/Sidebar/Menu/Calendar.vue";
import MenuSettings from "~base/components/_Layout/Sidebar/Menu/Settings.vue";
import MenuArchives from "~base/components/_Layout/Sidebar/Menu/Archives.vue";
export default {
    components: {
        SidebarSection,
        MenuActivityStream,
        MenuToday,
        MenuLate,
        MenuCalendar,
        MenuSettings,
        MenuArchives
    }
}
</script>

<style>

</style>